/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import {
  Select as MUISelect,
  MenuItem as MUIMenuItem,
  selectClasses,
} from "@mui/material";
import styled from "@emotion/styled";

export const Select = styled((props) => (
  <MUISelect
    {...props}
    MenuProps={{
      PaperProps: {
        sx: {
          borderRadius: 0,
          border: "1px solid #dddddd",
          margin: 0,
          boxShadow: "none",
          transform: "translate(-0.5px, -2px)  !important",
          ul: {
            padding: 0,
          },
          li: {
            paddingInline: "15.5px",
          },
        },
      },
    }}
  />
))`
  .${selectClasses.select} {
    padding: 3.5px 31px 3.5px 8px;
    border: 1px solid #dddd;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  ${({ entriesSelect }) =>
    entriesSelect &&
    `
    .${selectClasses.select} {
      height:22px;
      padding:0 31px 0 8px;
    }`}

  .${selectClasses.icon} {
    transform: translateX(-3px);
  }

  &:before {
    border-bottom: none !important;
  }

  &:after {
    border-bottom: none !important;
  }
`;

export const WhiteSelect = styled(
  forwardRef(({ selectProps, ...props }, ref) => {
    const width = props.sx?.width;
    const { searchable } = props;
    return (
      <MUISelect
        ref={ref}
        {...props}
        SelectDisplayProps={selectProps}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: 0,
              border: "1px solid #dddddd",
              margin: 0,
              minWidth: { width },
              boxShadow: "none",
              transform: "translate(0px, -1px)  !important",
              ul: {
                padding: searchable ? "88px 0 0 0" : "0",
                maxHeight: searchable ? "238px" : "140px",
                overflow: "auto",
              },
              li: {
                paddingInline: "15.5px",
                fontSize: "1.3rem",
                lineHeight: "1.6rem",
              },
            },
          },
        }}
      />
    );
  })
)`
  .${selectClasses.select} {
    padding: 6px 8px;
    border: 1px solid #dddd;
    font-size: 1.3rem;
    line-height: 2rem;
    background-color: #fff;
    border-radius: 0 !important;
  }

  ${({ smaller }) =>
    smaller &&
    `
    .${selectClasses.select} {
      padding: 4.5px 8px;

    }`}

  ${({ medium }) =>
    medium &&
    `
    .${selectClasses.select} {
      padding: 5px 8px;

    }`}

  ${({ medium2 }) =>
    medium2 &&
    `
    .${selectClasses.select} {
      padding: 7px 8px;
    }
  `}

  ${({ bigger }) =>
    bigger &&
    `
    .${selectClasses.select} {
      padding: 7px 8px;
    }
  `}

  .${selectClasses.icon} {
    transform: translateX(-3px);
  }

  fieldset {
    display: none;
  }

  &:before {
    border-bottom: none !important;
  }

  &:after {
    border-bottom: none !important;
  }

  .MuiInputBase-readOnly {
    cursor: default;
    color: #0000007f;
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  &.selected {
    background-color: transparent !important;
  }
  &.hidden {
    display: none;
  }
`;

export const SelectOptionText = styled("p")`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-left: 1rem;
`;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputLabel, TextInput, FormError } from "components/global/forms";
import { fetchChangePassword } from "data/features/auth/authApi";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Styles from "../styles";

function ChangePasswordForm({ changePasswordKey }) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsDisabled(true);
    fetchChangePassword({ newPassword: data.password, key: changePasswordKey })
      .then((response) => {
        toast.success(t("changePassword.form.toast.success"));
        navigate("/login", { replace: true });
        return response;
      })
      .catch((error) => {
        if (error.response.typeOfException === "RuntimeException") {
          toast.error(t("changePassword.form.toast.tokenExpired"));
          navigate("/forgot-password", { replace: true });
        }
        return error;
      })
      .then(() => setIsDisabled(false));
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled =
        value.repeat_password.length && value.password.length;
      setIsDisabled(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch, errors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormError>
        <span>{errors.password?.message}</span>
      </FormError>
      {errors.password?.message !== errors.repeat_password?.message && (
        <FormError>
          <span>{errors.repeat_password?.message}</span>
        </FormError>
      )}
      <InputLabel>{t("changePassword.form.newPassword.label")}</InputLabel>
      <TextInput
        resetPassInput
        type="password"
        aria-label="NewPassword"
        hasError={errors.password}
        {...register("password", {
          required: t("form.password.required"),
          minLength: {
            value: 8,
            message: t("form.password.minLength"),
          },
        })}
      />
      <InputLabel>{t("changePassword.form.repeatPassword.label")}</InputLabel>
      <TextInput
        resetPassInput
        type="password"
        aria-label="ConfirmNewPassword"
        hasError={errors.repeat_password}
        {...register("repeat_password", {
          required: t("changePassword.form.repeatPassword.required"),
          validate: {
            minLength: (value) =>
              value.length >= 8 || t("form.password.minLength"),
            samePassword: (value) =>
              value === watch("password") ||
              t("changePassword.form.repeatPassword.match"),
          },
        })}
      />
      <Styles.LoginSubmitButton
        aria-label="ChangePasswordButton"
        disabled={isDisabled}
      >
        {t("login.form.submit.label")}
      </Styles.LoginSubmitButton>
    </form>
  );
}

export default ChangePasswordForm;

ChangePasswordForm.propTypes = {
  changePasswordKey: PropTypes.string.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { StyledCheckbox } from "components/global/forms";
import { ReactComponent as Chevron } from "assets/chevron-collapse.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addRowsToExport,
  removeRowFromExport,
  selectCollapsedRows,
  selectRowsToExport,
  toggleRow,
} from "data/features/translationQueue/translationQueueSlice";

export const TranslationQueueLangActionsCell = ({ value, row, noCollapse }) => {
  const dispatch = useDispatch();
  const rowsToExport = useSelector(selectRowsToExport);
  const collapsedRows = useSelector(selectCollapsedRows);
  const rowId = value || row?.original.project_id;

  const handleCollapse = () => {
    dispatch(toggleRow(rowId));
  };

  const jobIds = row?.project_jobs?.map((job) => job.xtm_job_id);

  const isSelectedToExport = jobIds
    .map((id) => rowsToExport.includes(id))
    .every((condition) => condition === true);

  const handleChange = (ev) => {
    const isSelected = ev.target.checked;
    if (isSelected) {
      dispatch(addRowsToExport(jobIds));
    } else {
      jobIds.forEach((id) => {
        dispatch(removeRowFromExport(id));
      });
    }
  };

  const isRowCollapsed = collapsedRows.includes(rowId);

  return (
    <Box display="flex" alignItems="center">
      <StyledCheckbox
        onChange={handleChange}
        checked={isSelectedToExport}
        color="success"
      />
      {!noCollapse && (
        <IconButton sx={{ marginLeft: "0.9rem" }} onClick={handleCollapse}>
          <Chevron
            style={{
              transition: "0.3s ease",
              transform: `rotate(${isRowCollapsed ? "180" : "0"}deg)`,
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

TranslationQueueLangActionsCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  row: PropTypes.object.isRequired,
  noCollapse: PropTypes.bool,
};

TranslationQueueLangActionsCell.defaultProps = {
  noCollapse: false,
};

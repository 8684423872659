import React, { useState } from "react";
import { format } from "date-fns";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { CustomInput } from "./CustomInput";
import { CalendarRange } from "./CalendarRange";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    height: 320,
    background: "white",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 6px 6px rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    border: "1px solid #DDDDDD",
  },
});

export const CalendarInput = ({
  filterDate,
  handleSetDateValue,
  calendarMaxDate,
  futureRanges,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const formattedValue = () => {
    if (!filterDate.selection.startDate || !filterDate.selection.endDate)
      return "";
    return `${format(filterDate.selection.startDate, "dd/MM/yyy")} - ${format(
      filterDate.selection.endDate,
      "dd/MM/yyy"
    )}`;
  };

  return (
    <StyledTooltip
      open={isOpen}
      title={
        <CalendarRange
          value={filterDate}
          handleSetValue={handleSetDateValue}
          handleClickAway={handleClose}
          calendarMaxDate={calendarMaxDate}
          futureRanges={futureRanges}
        />
      }
      onClose={handleClose}
      onOpen={handleOpen}
      disableHoverListener
      disableTouchListener
      disableFocusListener
    >
      <span>
        <CustomInput onOpen={handleOpen} value={formattedValue()} />
      </span>
    </StyledTooltip>
  );
};

CalendarInput.propTypes = {
  filterDate: PropTypes.object.isRequired,
  handleSetDateValue: PropTypes.func.isRequired,
  calendarMaxDate: PropTypes.object,
  futureRanges: PropTypes.bool,
};

CalendarInput.defaultProps = {
  calendarMaxDate: new Date(
    new Date().setFullYear(new Date().getFullYear() + 5)
  ),
  futureRanges: false,
};

import { ORACLE_PROFILE_FORM_TYPES } from "constants/OracleProfileFormTypes";

export const oracleProfileDataMapper = (data, id) => {
  const payload = {
    connection_profile_name: data.name,
    client: data.clientAssigned,
    user_assigned: data.userAssigned,
    connection_profile_status: data.status ? "ACTIVE" : "NOT_ACTIVE",
    oracle_temp_credential: data.oracleTempCredential,
    xtm_temp_credential: data.xtmTempCredential,
    enable_processing_media_files: data.enableProcessingMediaFiles,
    extensions_source_files: data.extensionsSourceFiles,
    extensions_reference_files: data.extensionsReferenceFiles,
    xtm_customer: data.xtmCustomer,
    xtm_customer_name: data.xtmCustomerName,
    xtm_template: data.xtmTemplate,
    default_due_date_days_ahead: Number(data.defaultDueDateDaysAhead),
    language: Object.fromEntries(
      data.language.map((item) => [item.key, item.value])
    ),
    users_mail_notification_job_is_finished_and_imported:
      data.usersMailNotificationJobIsFinishedAndImported,
    users_mail_notification_progress: data.usersMailNotificationProgress,
    enable_change_in_existing_projects: data.enableChangeInExistingProjects,
    project_name_prefix: data.projectNamePrefix,
    remove_deleted_CMS_after_days: Number(data.removeDeletedCmsAfterDays),
    remove_cancelled_jobs_after_days: Number(data.removeCancelledJobsAfterDays),
    integrated_system_name: "",
  };

  if (id) payload.id = id;

  return payload;
};

export const oracleProfileResponseDataMapper = (data, type) => ({
  id: data.id,
  name:
    type === ORACLE_PROFILE_FORM_TYPES.EDIT ? data.connection_profile_name : "",
  clientAssigned: data.client,
  userAssigned: data.user_assigned,
  status: data.connection_profile_status,
  enableProcessingMediaFiles: data.enable_processing_media_files,
  extensionsSourceFiles: data.extensions_source_files ?? [],
  extensionsReferenceFiles: data.extensions_reference_files ?? "",
  xtmCustomer: data.xtm_customer,
  xtmCustomerName: data.xtm_customer_name,
  xtmTemplate: data.xtm_template,
  defaultDueDateDaysAhead: data.default_due_date_days_ahead,
  // eslint-disable-next-line
  language: Object.keys(data.language).map((lang) => {
    return {
      key: lang,
      value: data.language[lang],
    };
  }),
  usersMailNotificationJobIsFinishedAndImported:
    data.users_mail_notification_job_is_finished_and_imported,
  usersMailNotificationProgress: data.users_mail_notification_progress,
  enableChangeInExistingProjects: data.enable_change_in_existing_projects,
  projectNamePrefix: data.project_name_prefix,
  removeDeletedCmsAfterDays: data.remove_deleted_CMS_after_days,
  removeCancelledJobsAfterDays: data.remove_cancelled_jobs_after_days,
});

import React from "react";
import { InputAdornment, styled, TextField } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/search-icon.svg";

export const SearchInput = styled((props) => (
  <TextField
    {...props}
    InputProps={{
      startAdornment: (
        <InputAdornment
          sx={{ marginLeft: "1.6rem", marginRight: "0" }}
          position="start"
        >
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
))`
  width: 100%;
  input {
    padding: 1rem 1.6rem;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
`;

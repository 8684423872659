import React from "react";
import PropTypes from "prop-types";

import MUITableCell from "@mui/material/TableCell";
import ArrowUp from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDown from "@mui/icons-material/ArrowDropDownRounded";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

import { NOT_SORTABLE_COLUMNS } from "../config";

const StyledTableColumnCell = styled(MUITableCell)`
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : undefined)};
  font-size: 13px;
  padding: 9px 24px 9px 24px;
  border-bottom: 0;
`;

export const TableColumnCell = ({ column }) => {
  const isSortable = !NOT_SORTABLE_COLUMNS.includes(column.id);
  const renderArrow = () => {
    if (column.isSorted) {
      return column.isSortedDesc ? (
        <ArrowDown
          sx={{
            position: "absolute",
            top: -5,
            left: -3,
            color: "#00A7EA",
            fontSize: "23px",
          }}
        />
      ) : (
        <ArrowUp
          sx={{
            position: "absolute",
            top: -5,
            left: -3,
            color: "#00A7EA",
            fontSize: "23px",
          }}
        />
      );
    }
    return null;
  };

  return (
    <StyledTableColumnCell
      {...column.getHeaderProps(isSortable && column.getSortByToggleProps())}
      style={{ width: column.width }}
      isSortable={isSortable}
    >
      <Box display="flex">
        {column.render("Header")}
        <Box position="relative">{renderArrow()}</Box>
      </Box>
    </StyledTableColumnCell>
  );
};

TableColumnCell.propTypes = {
  column: PropTypes.any.isRequired,
};

export const SOURCE_FILES_EXTENSIONS = [
  "Adobe FrameMaker (mif)",
  "Adobe Illustrator (fxg, svg, ai)",
  "Adobe InDesign (idml, indd, indb)",
  "Adobe Photoshop (psd)",
  "Android apps (xml)",
  "asp, aspx, ascx",
  "Digia QT (ts)",
  "DITA",
  "Document template (tpl)",
  "eps",
  "html, htm xhtml, xht shtml, shtm",
  "ini",
  "iOS apps (strings, stringsdict)",
  "Java property files",
  "JSON",
  "MadCap Flare (flprj)",
  "Markdown (md)",
  "MemoQ (mqxliff)",
  "Microsoft Office (doc, docx, xls, xlsx, xlsm, ppt, pptx)",
  "Microsoft Visio",
  "Open Office (sxw, odt, ods, odp)",
  "php",
  "po, pot",
  "rc",
  "resx, resw",
  "rtf",
  "SalesForce (stf)",
  "sdf",
  "Sketch files (.sketch)",
  "SubRip text (srt)",
  "Trados (ttx)",
  "Trados Studio (sdlxliff)",
  "txt",
  "vdx, vsdx",
  "Wordfast (txml)",
  "xlf, xliff",
  "xml",
  "yml, yaml",
  "props",
];

export const XTM_LANGUAGE_CODES = [
  "ab",
  "aa_ET",
  "ak",
  "af_ZA",
  "sq_AL",
  "am_ET",
  "am_ER",
  "ar_AA",
  "ar_AE",
  "ar_BH",
  "ar_DZ",
  "ar_EG",
  "ar_EH",
  "ar_IQ",
  "ar_JO",
  "ar_KW",
  "ar_LB",
  "ar_LY",
  "ar_MA",
  "ar_MR",
  "ar_OM",
  "ar_PS",
  "ar_QA",
  "ar_SA",
  "ar_SD",
  "ar_SY",
  "ar_TD",
  "ar_TN",
  "ar_YE",
  "anu",
  "hy_AM",
  "hy_AM_arevela",
  "hy_AM_arevmda",
  "as_IN",
  "ast_ES",
  "ay_BO",
  "az_AZ_Cyrl",
  "az_AZ_Latn",
  "ba_RU",
  "bal_IR",
  "bh_IN",
  "bi_VU",
  "bam",
  "bs_BA_Cyrl",
  "bs_BA_Latn",
  "br_FR",
  "bsk",
  "my_MM",
  "be_BY",
  "cal",
  "ca_ES",
  "ceb",
  "cha",
  "cja",
  "cjm",
  "ctd",
  "ny_MW",
  "zh_CN",
  "zh_TW",
  "zh_HK",
  "zh_SG",
  "zh_HK_Hans",
  "chk",
  "cpe",
  "co_FR",
  "hr_HR",
  "hr_BA",
  "cs_CZ",
  "eu_ES",
  "bn_IN",
  "bn_BD",
  "bg_BG",
  "ca_AD",
  "dnj",
  "da_DK",
  "prs_AF",
  "dv_IN",
  "mis",
  "nl_NL",
  "en_US",
  "en_GB",
  "en_142",
  "en_CA",
  "en_AU",
  "en_NZ",
  "en_ZA",
  "en_CH",
  "en_HK",
  "en_IN",
  "en_IE",
  "en_SG",
  "en_AE",
  "en_DE",
  "en_NL",
  "en_AT",
  "en_NT",
  "en_CY",
  "en_KE",
  "en_BS",
  "en_MY",
  "en_PK",
  "en_PH",
  "en_LU",
  "en_NG",
  "en_JP",
  "en_TT",
  "en_AW",
  "en_BH",
  "en_EG",
  "en_ES",
  "en_ID",
  "en_JO",
  "en_KR",
  "en_KW",
  "en_TH",
  "eo",
  "et_EE",
  "ee_GH",
  "eky",
  "kyu",
  "fo_FO",
  "fj_FJ",
  "fil_PH",
  "fi_FI",
  "nl_BE",
  "fr_FR",
  "fr_CA",
  "fr_CH",
  "fr_BE",
  "fr_LU",
  "fr_MA",
  "fr_SN",
  "fr_CM",
  "fy",
  "fu",
  "fat",
  "gl_ES",
  "ka_GE",
  "kar",
  "de_DE",
  "de_AT",
  "de_BE",
  "de_CH",
  "de_LU",
  "de_NL",
  "lb_LU",
  "kl_GL",
  "el_GR",
  "el_CY",
  "grc_GR",
  "gil",
  "grn",
  "gu_IN",
  "ht_HT",
  "cnh",
  "ha_NG",
  "ha_Latn",
  "he_IL",
  "hi_IN",
  "hil",
  "hmn",
  "hmn_US",
  "hu_HU",
  "haw",
  "is_IS",
  "ig",
  "ilo",
  "id_ID",
  "ia",
  "ie",
  "iu",
  "ium",
  "ik",
  "ish",
  "ga_IE",
  "it_IT",
  "it_CH",
  "ja_JP",
  "jv_ID",
  "ks",
  "kk_KZ",
  "kg_CG",
  "kik",
  "kig",
  "rw_RW",
  "ky",
  "rn",
  "sw_KE",
  "km_KH",
  "kn_IN",
  "kok_IN",
  "tlh",
  "ko_KR",
  "kos",
  "ku_TR",
  "kmr",
  "ckb",
  "ku_IQ",
  "lo_LA",
  "la",
  "lv_LV",
  "ln_CG",
  "lt_LT",
  "mfe_MU",
  "mk_MK",
  "mg_MG",
  "ms_MY",
  "ms_SG",
  "ml_IN",
  "mt_MT",
  "mi_NZ",
  "mr_IN",
  "mah",
  "mn_MN",
  "sla_ME",
  "mo_MD",
  "na_NR",
  "nv",
  "nd_ZW",
  "ne_NP",
  "no_NO",
  "nb_NO",
  "niu",
  "nn_NO",
  "nso_ZA",
  "nus",
  "oc_FR",
  "or_IN",
  "om_ET",
  "ota",
  "pag",
  "pam",
  "pau",
  "ps",
  "ps_PK",
  "fa_IR",
  "pon",
  "pl_PL",
  "pt_PT",
  "pt_BR",
  "pt_MZ",
  "pt_AO",
  "pa_PA",
  "pa_IN",
  "pa_PK",
  "qu_PE",
  "qya",
  "xr_MM",
  "rar",
  "rm_CH",
  "ro_RO",
  "ro_MD",
  "ru_RU",
  "ru_AM",
  "ru_AZ",
  "ru_GE",
  "ru_MD",
  "ru_UA",
  "sm_WS",
  "sg",
  "sa_IN",
  "sc_IT",
  "gd_GB",
  "st",
  "tn_ZA",
  "sr_YU",
  "sr_RS_Cyrl",
  "sr_ME_Cyrl",
  "sr_ME_Latn",
  "sr_RS_Latn",
  "sn",
  "sjn",
  "sd_PK",
  "si_LK",
  "ss",
  "sk_SK",
  "sl_SI",
  "snk",
  "so_SO",
  "dsb_DE",
  "hsb_DE",
  "es_ES",
  "es_AR",
  "es_BO",
  "es_CL",
  "es_CO",
  "es_CR",
  "es_CU",
  "es_DO",
  "es_EC",
  "es_SV",
  "es_GT",
  "es_HN",
  "es_419",
  "es_MX",
  "es_NI",
  "es_PA",
  "es_PY",
  "es_PE",
  "es_PR",
  "es_UY",
  "es_US",
  "es_VE",
  "es_001",
  "es_NT",
  "swa",
  "sw_SO",
  "sw_TZ",
  "sw_UG",
  "sv_SE",
  "sv_FI",
  "apd_SD",
  "apd_SD_Latn",
  "sun",
  "syr_TR",
  "tl_PH",
  "tg_TJ",
  "ta_IN",
  "ta_SG",
  "ta_LK",
  "tt_RU",
  "te_IN",
  "tet_ID",
  "tet_TL",
  "th_TH",
  "bo",
  "ti",
  "tir_ER",
  "tir_ET",
  "to_TO",
  "ts_ZA",
  "tn_BW",
  "tpi",
  "tvl",
  "tr_TR",
  "tk_TM",
  "tkl",
  "tw",
  "ty",
  "uk_UA",
  "ur_IN",
  "ur_PK",
  "ug_CN",
  "uz_UZ_Cyrl",
  "uz_UZ_Latn",
  "uz_AF",
  "cy_GB",
  "vi_VN",
  "vo",
  "wo",
  "war",
  "xh_ZA",
  "xz_AF",
  "yao",
  "yap",
  "yi",
  "yi_IL",
  "yi_US",
  "yo_NG",
  "czt",
  "zom",
  "zu_ZA",
  "kun",
  "lua",
  "sco_GB",
  "sco_IE",
  "fr_CG",
  "zh_YUE",
  "lug",
  "ogo",
  "bbc",
  "ksw",
  "cfm",
  "cmn",
  "goyu",
  "aii",
  "cld",
  "pdc",
  "ziw",
  "pap",
  "en_CN",
  "din",
  "fr_TN",
  "fur",
  "en_EU",
  "mas",
  "en_IL",
  "en_QA",
  "pis",
  "lus",
  "hif",
  "zyp",
  "sez",
  "clt",
  "mwq",
  "mrh",
  "sdh",
  "tcz",
  "rtm",
  "bfa",
  "shn",
  "mnw",
  "toq",
  "ach",
  "jam",
  "rom",
  "chr",
  "bsq",
  "cpf",
  "kgp_BR",
  "yrl_BR",
  "bem",
  "ts_ZA_changana",
  "luo",
  "xsm",
  "mni",
  "nag",
  "quc",
  "ton",
  "bdx",
  "doi",
  "mai",
  "sat",
  "daw",
  "krj",
  "fr_001",
  "pt_001",
  "en_SA",
  "mnk",
  "kjb",
  "keo",
  "kjg",
  "wbh",
  "en_MA",
  "en_BE",
  "en_CZ",
  "en_HU",
  "en_SK",
  "en_FI",
  "en_HR",
  "en_RO",
  "en_SI",
  "en_RS",
  "en_UA",
  "en_PT",
  "en_DK",
  "en_FR",
  "en_IT",
  "en_MX",
  "en_NO",
  "en_PL",
  "en_RU",
  "en_SE",
  "en_OM",
  "ms_AR",
  "ru_IL",
  "ber",
  "en_CL",
  "kri",
  "hi_Latn",
  "en_TR",
  "en_TW",
  "en_BN",
  "en_PR",
  "gaa",
  "hz",
  "ny",
  "cgg",
  "nyo",
  "kj",
  "mix",
  "ha_Arab",
  "hi_Latn_en",
  "fr_DZ",
  "ve",
  "smn",
  "sms",
  "zne",
  "bik",
  "shk",
];

import { styled } from "@mui/system";

export const InputLabel = styled("label")`
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: block;
  margin-bottom: 0.4rem;
  margin-top: 1.6rem;
  color: ${({ theme }) => theme.palette.text.darkGray};

  ${({ centered }) =>
    centered &&
    `
      padding-top:1rem;
  `}

  ${({ lead }) =>
    lead &&
    `
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;
    margin:0;

  `}

  ${({ medium }) =>
    medium &&
    `
    font-weight: 500;

  `}

  ${({ wider }) => wider && ` width:max-content; `}
`;

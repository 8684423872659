import { createStaticRanges } from "react-date-range";
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  lastDayOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";

const defined = {
  startOfYear: startOfYear(new Date()),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLast14Days: addDays(new Date(), -14),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  endOfNext14Days: endOfDay(addDays(new Date(), 14)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),
  endOfYear: endOfDay(lastDayOfYear(new Date())),
};

const todayAndEarlierRanges = [
  {
    label: "Last year",
    range: () => ({
      startDate: defined.startOfYear,
      endDate: defined.endOfToday,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defined.startOfLastMonth,
      endDate: defined.endOfLastMonth,
    }),
  },
  {
    label: "Last 14 days",
    range: () => ({
      startDate: defined.startOfLast14Days,
      endDate: defined.endOfToday,
    }),
  },

  {
    label: "Yesterday",
    range: () => ({
      startDate: defined.startOfYesterday,
      endDate: defined.endOfYesterday,
    }),
  },
  {
    label: "Today",
    range: () => ({
      startDate: defined.startOfToday,
      endDate: defined.endOfToday,
    }),
  },
];

const futureRanges = [
  {
    label: "Next 14 days",
    range: () => ({
      startDate: defined.startOfToday,
      endDate: defined.endOfNext14Days,
    }),
  },
  {
    label: "Next month",
    range: () => ({
      startDate: defined.startOfToday,
      endDate: defined.endOfNextMonth,
    }),
  },
  {
    label: "Next year",
    range: () => ({
      startDate: defined.startOfToday,
      endDate: defined.endOfYear,
    }),
  },
];

export const defaultStaticRanges = createStaticRanges(todayAndEarlierRanges);

export const fullStaticRanges = createStaticRanges(
  todayAndEarlierRanges.concat(futureRanges)
);

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  StyledCheckbox,
  CancelButton,
  SubmitButton,
} from "components/global/forms";
import { Tooltip } from "components/Tooltip";
import { editClient, fetchClientById } from "data/features/clients/clientsApi";
import { useTranslation } from "react-i18next";
import { ReactComponent as VisibilityIcon } from "assets/eye.svg";
import { ReactComponent as VisibilityIconStroke } from "assets/stroke-eye.svg";
import { ReactComponent as CopyClipboardIcon } from "assets/copy.svg";
import { ReactComponent as RefreshIcon } from "assets/arrows-rotate.svg";
import { ReactComponent as CalendarIcon } from "assets/calendar-clock.svg";
import { FlexibleModal } from "components/FlexibleModal";
import { Box } from "@mui/system";
import { CancelActionButton } from "components/global/forms/FormButton";
import { Heading, ButtonsContainer, EditClientText } from "../styles";
import {
  InnerModalContent,
  InnerModalFooter,
} from "../EventLog/EventLogsModal";

export const EditClient = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [client, setClient] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const params = useLocation();
  const paramID = Number(new URLSearchParams(params.search).get("id"));
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (paramID) {
      fetchClientById(paramID)
        .then((response) => {
          const clientData = response.data;
          clientData.status = clientData.status === "ACTIVE";
          setClient(clientData);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      navigate("/clients", { replace: true });
    }
  }, []);

  const onSubmit = (data) => {
    setIsDisabled(true);
    editClient({
      id: paramID,
      name: data.clientName.trim(),
      key: client.key,
      status: client.status ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/clients", { replace: true });
        return response;
      })
      .catch((error) => {
        setError("clientName", {
          type: "custom",
        });
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const isFieldFilled = value.clientName.trim().length;
      setIsDisabled(!isFieldFilled);
      setShowTooltip(!isFieldFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const handleCopyApiKeyToClipboard = () => {
    navigator.clipboard.writeText(client?.key);
    toast("Api key copied");
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const saveModal = () => {
    setModalOpen(false);
    setClient((prevClient) => ({ ...prevClient, key: crypto.randomUUID() }));
  };

  const handleNewApiKey = () => {
    setModalOpen(true);
  };

  const handleActiveCheckboxChange = () => {
    setClient((prevClient) => ({ ...prevClient, status: !prevClient.status }));
  };

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("editClient.heading")}</Heading>
        {client && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow bottomSpace editClient>
              <InputLabel lead centered>
                {t("addClient.form.name.label")}
              </InputLabel>
              <TextInput
                editClientInput
                aria-label="ClientName"
                defaultValue={client?.name}
                hasError={errors.clientName}
                {...register("clientName")}
              />
            </FormRow>
            <FormRow bottomSpace profile editClientPad>
              <InputLabel lead>{t("form.active.label")}</InputLabel>
              <div>
                <StyledCheckbox
                  checked={client.status}
                  onChange={handleActiveCheckboxChange}
                  color="success"
                />
              </div>
            </FormRow>
            <FormRow bottomSpace calendar profile editClientPad>
              <InputLabel lead>{t("form.dateAdded")}</InputLabel>
              <CalendarIcon />
              <EditClientText centered>
                {format(new Date(client?.created_date), "dd.MM.yyyy kk:mm")}
              </EditClientText>
            </FormRow>
            {client?.modification_date && (
              <FormRow calendar profile editClientPad>
                <InputLabel lead>{t("form.dateModified")}</InputLabel>
                <CalendarIcon />
                <EditClientText centered>
                  {format(
                    new Date(client?.modification_date),
                    "dd.MM.yyyy kk:mm"
                  )}
                </EditClientText>
              </FormRow>
            )}
            <FormRow autoFlowColumnsEdit bottomSpace editClientPad>
              <InputLabel lead centered>
                {t("addClient.form.name.apiKey")}
              </InputLabel>
              <TextInput
                password
                editClientPassword
                aria-label="ApiKey"
                type={isPasswordVisible ? "text" : "password"}
                value={client?.key}
                {...register("apiKey")}
              />
              <Tooltip
                title={t("editClient.showPassword.tooltip")}
                arrow
                placement="bottom"
              >
                {isPasswordVisible ? (
                  <VisibilityIconStroke
                    isVisible={isPasswordVisible}
                    style={{
                      cursor: "pointer",
                      marginLeft: "0.7rem",
                      marginTop: "1rem",
                    }}
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                ) : (
                  <VisibilityIcon
                    isVisible={isPasswordVisible}
                    style={{
                      cursor: "pointer",
                      marginLeft: "0.7rem",
                      marginTop: "1rem",
                    }}
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                )}
              </Tooltip>
              <Tooltip
                title={t("editClient.copyClipboard.tooltip")}
                arrow
                placement="bottom"
              >
                <CopyClipboardIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "0.7rem",
                    marginTop: "1rem",
                  }}
                  onClick={handleCopyApiKeyToClipboard}
                />
              </Tooltip>
              <Tooltip
                title={t("editClient.refreshApiKey.tooltip")}
                arrow
                placement="bottom"
              >
                <RefreshIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "0.7rem",
                    marginTop: "1rem",
                  }}
                  onClick={handleNewApiKey}
                />
              </Tooltip>
            </FormRow>

            <ButtonsContainer editClient>
              <CancelButton
                standard
                aria-label="CancelAddClientButton"
                to="/clients"
              >
                {t("form.cancel")}
              </CancelButton>
              <Tooltip
                title={showTooltip ? "Enter a client name first" : ""}
                arrow
                placement="bottom"
              >
                <div>
                  <SubmitButton
                    standard
                    aria-label="AddClientButton"
                    disabled={isDisabled}
                    type="submit"
                  >
                    {t("form.save")}
                  </SubmitButton>
                </div>
              </Tooltip>
            </ButtonsContainer>
          </form>
        )}
      </InnerSectionContainer>
      <FlexibleModal
        maxWidth="451px"
        modalOpen={modalOpen}
        heading={t("editClient.modal.heading")}
        handleCloseModal={closeModal}
        handleSaveModal={saveModal}
        renderContent={({ handleCloseModal, handleSaveModal }) => (
          <Box>
            <InnerModalContent>{t("editClient.modal.text")}</InnerModalContent>
            <InnerModalFooter>
              <Box>
                <SubmitButton sx={{ marginRight: 0 }} onClick={handleSaveModal}>
                  Confirm
                </SubmitButton>
                <CancelActionButton onClick={handleCloseModal}>
                  Cancel
                </CancelActionButton>
              </Box>
            </InnerModalFooter>
          </Box>
        )}
      />
    </StyledSection>
  );
};

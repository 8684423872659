import React, { useState } from "react";
import { Modal, Fade, Button } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { selectModal, setModal } from "data";
import PropTypes from "prop-types";
import { ReactComponent as CopyClipboardIcon } from "assets/copy-clipboard-icon.svg";
import { SubmitButton } from "components/global/forms";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const InnerModal = styled(Box)`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "685px"};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: ${({ theme }) => theme.palette.white.main};
`;

export const InnerModalCopyButton = styled(Button)`
  position: fixed;
  z-index: 10;
  right: 32px;
  bottom: 83px;
  background-color: ${({ theme }) => theme.palette.white.main};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12),
    0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
  min-height: 3.2rem;
  min-width: 11.3rem;
  font-weight: 500;
  font-size: 1.4rem;
  font-family: Roboto, serif;
  line-height: 1.641rem;
  text-transform: none;
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.white.main};
  }

  svg {
    margin-right: 0.4rem;
  }

  ${({ visible }) => visible && `opacity:1;pointer-events:auto;`}
`;

export const InnerModalHeading = styled("div")`
  padding: 1.6rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const InnerModalContent = styled("div")`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0 1.6rem 1.6rem 1.6rem;
  max-height: 44.2rem;
  overflow: auto;
  position: relative;

  ${({ wrapWords }) => wrapWords && ` overflow-wrap: break-word; `}
`;

export const InnerModalFooter = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.palette.border.main};
  padding: 1.45rem 1.6rem;
  max-height: 6.5rem;

  ${({ standard }) =>
    standard &&
    `
  height: 6.1rem;
`}
`;

export const EventLogsModal = ({ message }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const modalOpen = modal.isOpen;
  const { t } = useTranslation();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message);
    toast("Stack trace copied");
  };

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() =>
        dispatch(
          setModal({
            isOpen: false,
          })
        )
      }
      closeAfterTransition
    >
      <Fade in={modalOpen}>
        <InnerModal>
          <InnerModalHeading>Stack trace</InnerModalHeading>
          <InnerModalContent
            wrapWords
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {message}
            <InnerModalCopyButton
              onClick={handleCopyToClipboard}
              visible={visible}
            >
              <CopyClipboardIcon />
              {t("eventLogs.modal.notifications")}
            </InnerModalCopyButton>
          </InnerModalContent>
          <InnerModalFooter>
            <SubmitButton
              small
              onClick={() =>
                dispatch(
                  setModal({
                    isOpen: false,
                  })
                )
              }
              type="button"
            >
              Close
            </SubmitButton>
          </InnerModalFooter>
        </InnerModal>
      </Fade>
    </Modal>
  );
};

EventLogsModal.propTypes = {
  message: PropTypes.string.isRequired,
};

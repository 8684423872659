import { get } from "lodash";

const TRANSLATION_QUEUE_FILTERS = [
  "projectID.length",
  "jobID.length",
  "fileName.length",
  "sourceLanguages.length",
  "targetLanguages.length",
  "customer",
  "dateAdded.selection.startDate",
  "dateAdded.selection.endDate",
  "dateDue.selection.startDate",
  "dateDue.selection.endDate",
  "xtmWorkflowSteps.length",
  "xtmWorkflowStatuses.length",
];

export const someFieldIsFilled = (filters) => {
  const isFilled = TRANSLATION_QUEUE_FILTERS.some(
    (filter) => !!get(filters, filter)
  );

  return !isFilled;
};

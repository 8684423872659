import React from "react";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { Tooltip } from "components/Tooltip";
import { ReactComponent as EditIcon } from "assets/edit-list-item.svg";
import { ReactComponent as DuplicateUserIcon } from "assets/add-user.svg";

const ActionsWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-height: 3.2rem;

  button {
    margin-right: 0.5rem;
  }
`;

const IconButtonWrapper = styled(IconButton)`
  max-height: 3rem;
  max-width: 3rem;
  padding: 0.7rem;
`;

export const UserActionsCell = ({ row }) => {
  const navigate = useNavigate();

  const handleClick = ({ pathname, searchParam }) => {
    const searchParamValue = row?.values?.[searchParam];
    navigate({
      pathname,
      search: searchParamValue
        ? `?${createSearchParams({ [searchParam]: searchParamValue })}`
        : undefined,
    });
  };

  return (
    <ActionsWrapper>
      <Tooltip title="Edit user" arrow placement="top">
        <IconButtonWrapper
          onClick={() => {
            handleClick({
              pathname: "/users/edit",
              searchParam: "id",
            });
          }}
        >
          <EditIcon />
        </IconButtonWrapper>
      </Tooltip>
      <Tooltip title="Duplicate user" placement="top">
        <IconButtonWrapper
          onClick={() => {
            handleClick({
              pathname: "/users/duplicate",
              searchParam: "id",
            });
          }}
        >
          <DuplicateUserIcon />
        </IconButtonWrapper>
      </Tooltip>
    </ActionsWrapper>
  );
};

UserActionsCell.propTypes = {
  row: PropTypes.object.isRequired,
};

import { Box, styled } from "@mui/system";
import { ReactComponent as LoupeIcon } from "assets/loupe-icon.svg";

export const SearchBox = styled("li")`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  padding: 1.2rem 1.7rem;
  border-bottom: 1px solid #dddddd;
`;

export const SearchButtonsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;
`;

export const SearchButton = styled("button")`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.main};
  border: none;
  font-size: 1.3rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const NoUsersText = styled(Box)`
  padding: 0.6rem 1.7rem;
  font-size: 1.3rem;
`;

export const SearchIcon = styled(LoupeIcon)`
  position: absolute;
  top: 20px;
  right: 25px;
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchClients } from "./clientsApi";

const initialState = {
  isLoading: false,
  error: null,
  data: undefined,
  sort: {
    field: "name",
    order: "ASC",
  },
};

export const fetchClientsAsync = createAsyncThunk(
  "clients/fetchClients",
  async (params) => {
    const response = await fetchClients(params);
    return response.data;
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    clearClients: (state) => {
      state.data = undefined;
      state.error = null;
      state.isLoading = false;
    },
    updateSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchClientsAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchClientsAsync.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchClientsAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const selectClientsData = ({ clients }) => clients.data;
export const selectClientsIsLoading = ({ clients }) => clients.isLoading;
export const selectClientsError = ({ clients }) => clients.error;
export const selectClientsSort = ({ clients }) => clients.sort;

export const { clearClients, updateSort } = clientsSlice.actions;

export default clientsSlice.reducer;

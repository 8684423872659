import {
  DateCell,
  EditCell,
  StatusCell,
} from "components/TableComponent/cells";

export const clientColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: "300px",
  },
  {
    Header: "Client name",
    accessor: "name",
    width: "418px",
  },
  {
    Header: "Status",
    accessor: "status",
    width: "150px",
    Cell: StatusCell,
  },
  {
    Header: "Date added",
    accessor: "created_date",
    width: "150px",
    Cell: DateCell,
  },
  {
    Header: "Date modified",
    accessor: "modification_date",
    width: "150px",
    Cell: DateCell,
  },
  {
    Header: "",
    accessor: "edit",
    width: "50px",
    canSort: false,
    Cell: EditCell({
      tooltipContent: "Edit client",
      redirectParams: {
        pathname: "/clients/edit",
        searchParam: "id",
      },
    }),
  },
];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchEventLogs } from "./eventLogApi";

// Todo: Change rowsToExport To map
const initialState = {
  isLoading: false,
  error: null,
  data: undefined,
  sort: {
    field: "connection_profile_name",
    order: "ASC",
  },
  rowsToExport: [],
  modal: {
    isOpen: false,
    data: null,
  },
  filterTypes: [],
  filterProfiles: [],
  filterDate: {
    selection: {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  },
};

export const fetchEventLogsAsync = createAsyncThunk(
  "eventLogs/fetchEventLogs",
  async (params) => {
    const response = await fetchEventLogs(params);
    return response.data;
  }
);

export const eventLogsSlice = createSlice({
  name: "eventLogs",
  initialState,
  reducers: {
    clearEventLogFilters: (state) => {
      state.filterTypes = [];
      state.filterProfiles = [];
      state.filterDate = {
        selection: {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      };
    },
    addRowToExport: (state, action) => {
      state.rowsToExport.push(action.payload);
    },
    removeRowFromExport: (state, action) => {
      state.rowsToExport = state.rowsToExport.filter(
        (row) => row !== action.payload
      );
    },
    setModal: (state, action) => {
      state.modal = {
        isOpen: action.payload.isOpen,
        data: action.payload.data || state.modal.data,
      };
    },
    setFilterTypes: (state, action) => {
      state.filterTypes = action.payload;
    },
    setFilterProfiles: (state, action) => {
      state.filterProfiles = action.payload;
    },
    setFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    updateSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchEventLogsAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
      state.rowsToExport = [];
    },
    [fetchEventLogsAsync.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchEventLogsAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const selectEventLogsData = ({ eventLogs }) => eventLogs.data;
export const selectEventLogsIsLoading = ({ eventLogs }) => eventLogs.isLoading;
export const selectEventLogsError = ({ eventLogs }) => eventLogs.error;
export const selectRowsToExport = ({ eventLogs }) => eventLogs.rowsToExport;
export const selectModal = ({ eventLogs }) => eventLogs.modal;
export const selectFilterTypes = ({ eventLogs }) => eventLogs.filterTypes;
export const selectFilterProfiles = ({ eventLogs }) => eventLogs.filterProfiles;
export const selectFilterDate = ({ eventLogs }) => eventLogs.filterDate;
export const selectEventLogsSort = ({ eventLogs }) => eventLogs.sort;

export const {
  addRowToExport,
  removeRowFromExport,
  setModal,
  setFilterDate,
  setFilterProfiles,
  setFilterTypes,
  updateSort,
  clearEventLogFilters,
} = eventLogsSlice.actions;

export default eventLogsSlice.reducer;

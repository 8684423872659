import { css, styled } from "@mui/system";

const filterContainerStyles = css`
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.4rem;
  padding: 2.4rem;
`;

export const FilterBoxContainer = styled("div")`
  ${filterContainerStyles};
  width: 34.8rem;
  min-height: 29rem;
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 10;
`;

export const FilterBoxRelativeContainer = styled("div")`
  ${filterContainerStyles};

  &::before {
    content: "";
    position: absolute;
    left: 1.6rem;
    top: -0.8rem;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid ${({ theme }) => theme.palette.white.main};
  }
`;

export const FilterLabel = styled("label")`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.palette.text.darkGray};
  font-weight: 400;
  margin-bottom: 0.4rem;
  text-align: left;
`;

export const FilterBoxFilters = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FilterBoxButtons = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.2rem;
  button:last-child {
    margin: 0;
  }
`;

export const FilterBoxColumn = styled("div")`
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  max-width: 35rem;

  ${({ standard }) =>
    standard &&
    `
  max-width: 35.2rem;
  width: 35.2rem
          `}
`;

export const ClearFiltersButton = styled("button")`
  width: auto;
  padding: 0.85rem 1.2rem;
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 0 1.6rem;
  text-decoration: none;
  min-width: 6.4rem;
  font-family: "Roboto", sans-serif;
  border: 1px solid ${({ theme }) => theme.palette.border.light};
  color: ${({ theme }) => theme.palette.text.main};
  background-color: ${({ theme }) => theme.palette.white.main};

  ${({ disabled, theme }) =>
    disabled &&
    `
		color: ${theme.palette.text.disabled};
		
	`}

  ${({ resetSearchButton }) =>
    resetSearchButton &&
    `
  height: 3.2rem;
  width: 10rem;
  padding: 0.5rem 1.1rem;
          `}

  ${({ resetEventFilterButton }) =>
    resetEventFilterButton &&
    `
  height: 3.2rem;
  width: 9.4rem;
  padding: 0.5rem 1.1rem;
          `}
`;

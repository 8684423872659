import axios from "axios";

export const fetchUsers = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "email", order: "ASC" },
} = {}) =>
  axios.get("/users", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
  });

export const addUser = ({
  email = "",
  password = "",
  status = "ACTIVE",
  clientAssigned = "",
  language = "",
  firstName = "",
  lastName = "",
} = {}) =>
  axios.post("/users", {
    email,
    password,
    status,
    client: clientAssigned,
    language,
    first_name: firstName,
    last_name: lastName,
  });

export const editUser = ({
  id,
  email = "",
  password,
  status = "ACTIVE",
  clientAssigned,
  language = "",
  firstName = "",
  lastName = "",
} = {}) =>
  axios.put("/users", {
    id,
    email,
    password,
    status,
    client: clientAssigned,
    language,
    first_name: firstName,
    last_name: lastName,
  });

export const fetchUserById = (id) => axios.get(`/users/${id}`);

import { format } from "date-fns";

export const formatStartDate = (date) =>
  `${format(new Date(date), "yyyy-MM-dd")}T00:00:00.000Z`;

export const formatEndDate = (date) =>
  `${format(new Date(date), "yyyy-MM-dd")}T23:59:59.999Z`;

export const buildQuery = ({ date, types, profiles }) => {
  const filters = [];

  const formatedStartDate = formatStartDate(date.selection.startDate);
  const formatedEndDate = formatEndDate(date.selection.endDate);

  const selectedDate = `"earlier_than_event_date": "${formatedEndDate}","later_than_event_date": "${formatedStartDate}"`;

  const selectedTypes = types.map((item) => `"${item.toUpperCase()}"`);

  const selectedProfiles = profiles.map((item) => `"${item}"`);

  if (types.length) {
    filters.push(`"type": [${selectedTypes}]`);
  }

  if (date.selection.endDate) {
    filters.push(selectedDate);
  }

  if (profiles.length) {
    filters.push(`"oracle_profile_name": [${selectedProfiles}]`);
  }

  const query = `{${filters.filter((element) => element !== "").join(",")}}`;

  return query;
};

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@mui/system";
import { HeadingActionButton } from "components/TableComponent/components/HeadingActionButton";
import { ReactComponent as NotificationIcon } from "assets/notification-icon.svg";
import { ReactComponent as RefreshIcon } from "assets/refresh-icon.svg";
import { Popover } from "@mui/material";
import {
  addRefreshTime,
  selectFilters,
  selectRowsToExport,
  toggleShowImported,
} from "data/features/translationQueue/translationQueueSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CustomizedSwitch } from "components/Switch";
import { FilterBox } from "../FilterBox";
import { TranslationQueueBulkActions } from "./TranslationQueueBulkActions";

const FilterPopover = styled(Popover)`
  .MuiPaper-root {
    width: 780px;
    overflow: visible;
  }

  .MuiPopover-paper {
    &::before {
      content: "";
      position: absolute;
      left: 1.6rem;
      top: -0.8rem;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid ${({ theme }) => theme.palette.white.main};
    }
  }
`;

export const TranslationQueueHeading = ({ setGlobalFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const rowsToExport = useSelector(selectRowsToExport);
  const { t } = useTranslation();

  const filtersLength = useMemo(
    () =>
      (filters.dateAdded.selection.endDate ? 1 : 0) +
      (filters.dateDue.selection.endDate ? 1 : 0) +
      (filters.fileName ? 1 : 0) +
      (filters.jobID ? 1 : 0) +
      (filters.projectID ? 1 : 0) +
      filters.sourceLanguages.length +
      filters.targetLanguages.length +
      filters.xtmWorkflowStatuses.length +
      filters.xtmWorkflowSteps.length,
    [filters]
  );

  const handleRefreshList = () => {
    dispatch(addRefreshTime());
  };

  const handleShowImported = () => {
    dispatch(toggleShowImported());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        display="flex"
        alignItems="center"
        sx={{ position: "relative", paddingRight: "1rem" }}
      >
        <HeadingActionButton
          aria-label="ToggleFilterboxButton"
          filterButton
          onClick={handleClick}
          disableRipple
          active={filtersLength > 0}
        >
          <NotificationIcon />
          {t("eventLogs.filter.button")}

          {filtersLength > 0 && (
            <span data-testid="ToggleFilterboxButtonCount">
              ({filtersLength})
            </span>
          )}
        </HeadingActionButton>
        <FilterPopover
          open={open}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: -50,
          }}
          onClose={handleClose}
        >
          <FilterBox
            setGlobalFilter={setGlobalFilter}
            closeFilterbox={handleClose}
          />
        </FilterPopover>

        <TranslationQueueBulkActions disabled={!rowsToExport.length} />

        <HeadingActionButton refreshButton onClick={handleRefreshList}>
          <RefreshIcon />
          {t("translationQueue.refresh")}
        </HeadingActionButton>
      </Box>
      <Box
        sx={{
          borderLeft: "1px solid #dddddd",
          paddingLeft: "1rem",
          fontSize: "1.3rem",
          lineHeight: "1.6rem",
          color: "#333333",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CustomizedSwitch
          onClick={handleShowImported}
          value={filters.showImported}
          color="success"
        />
        {t("translationQueue.switchImportedButton")}
      </Box>
    </Box>
  );
};

TranslationQueueHeading.propTypes = {
  setGlobalFilter: PropTypes.func.isRequired,
};

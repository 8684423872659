import { Switch } from "@mui/material";
import { styled } from "@mui/system";

export const CustomizedSwitch = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  marginRight: "10px",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,

    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#A7D9B0",
        border: "1px solid #38AF49",
      },
      "&  .MuiSwitch-thumb": {
        opacity: 1,
        backgroundColor: "#38AF49",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#777777",
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    padding: "1px",
    border: "1px solid #777777",
    backgroundColor: "transparent",
    boxSizing: "border-box",
  },
}));

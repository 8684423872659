import React, { useCallback, useEffect, useMemo } from "react";
import Container from "components/global/Container";
import { TableTranslationQueue } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTranslationQueuesAsync,
  selectData,
  selectError,
  selectFilters,
  selectOracleProfile,
  selectPageSizeLimit,
  selectTranslationQueueSort,
  updateSort,
} from "data/features/translationQueue/translationQueueSlice";
import { Navigate } from "react-router-dom";
import { TranslationQueueProvider } from "./TranslationQueueContext";
import { translationQueueColumns } from "../helpers";
import { TranslationQueueModals } from "./TranslationQueueModals";
import { TranslationQueueQueryBuilder } from "../../../data/features/translationQueue/helpers/TranslationQueueQueryBuilder";

export function TranslationQueue() {
  const dispatch = useDispatch();
  const data = useSelector(selectData);
  const error = useSelector(selectError);
  const oracleProfile = useSelector(selectOracleProfile);
  const translationQueueSort = useSelector(selectTranslationQueueSort);
  const maxPageSize = useSelector(selectPageSizeLimit);
  const tqFilters = useSelector(selectFilters);

  const handleFetchTranslationQueues = useCallback(
    (params) =>
      dispatch(fetchTranslationQueuesAsync({ ...params, id: oracleProfile })),
    [oracleProfile, tqFilters]
  );

  const handleUpdateSort = (sort) => {
    dispatch(updateSort(sort));
  };

  if (oracleProfile === null) {
    return <Navigate to="/select-oracle-profile" replace />;
  }

  useEffect(() => {
    handleFetchTranslationQueues({
      limit: maxPageSize,
      sort: translationQueueSort,
      filter: new TranslationQueueQueryBuilder(tqFilters).buildQuery(),
    });
  }, [oracleProfile, tqFilters]);

  const columns = useMemo(() => translationQueueColumns, []);

  return (
    <>
      {error && <Navigate to="/server-error" state={{ error }} />}
      {data && (
        <TranslationQueueProvider>
          <Container small>
            <TableTranslationQueue
              fetchData={handleFetchTranslationQueues}
              columns={columns}
              data={data.content}
              totalItems={data.totalElements}
              sortId="createdDate"
              sortObject={translationQueueSort}
              updateSortObject={handleUpdateSort}
            />
          </Container>
          <TranslationQueueModals />
        </TranslationQueueProvider>
      )}
    </>
  );
}

import { Button, styled } from "@mui/material";

export const HeadingActionButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.light};
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  font-family: Roboto, sans-serif;
  align-items: center;
  padding: 8px 6px 8px 6px;
  max-height: 32px;
  text-transform: none;

  ${({ customDisabled, theme }) =>
    customDisabled &&
    `
        cursor:not-allowed;
        color: ${theme.palette.text.disabled};
        svg,path {
          fill: ${theme.palette.text.disabled};
        }

      `}

  ${({ disabled, theme }) =>
    disabled &&
    `
        cursor:not-allowed;
        color: ${theme.palette.text.disabled};
        svg,path {
          fill: ${theme.palette.text.disabled};
        }

      `}

  svg {
    path {
      transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.palette.primary.lightBg};
    color: ${theme.palette.primary.main};
    
    svg {
      path{ 
        fill: ${theme.palette.primary.main};
      }
    }
    `}

  ${({ filterButton }) =>
    filterButton &&
    `
  min-width: 64px;
    `}

  ${({ actionButton }) =>
    actionButton &&
    `
  max-width: 72px;
    `}

  ${({ refreshButton }) =>
    refreshButton &&
    `
          width: 104px;
  max-width: 104px;
    `}

  ${({ exportButton }) =>
    exportButton &&
    `
  width: 73px;
    `}
`;

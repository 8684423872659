import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputLabel, TextInput, FormError } from "components/global/forms";
import { fetchForgotPassword } from "data/features/auth/authApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as Styles from "../styles";

function ForgotPasswordForm({ isEndUserLogin }) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsDisabled(true);
    const subject = t("resetPassword.subject");
    fetchForgotPassword({ ...data, subject })
      .then((response) => response)
      .catch((error) => error)
      .finally(() => toast.success(t("resetPassword.message")));
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const isEmailFilled = value.email.length;
      setIsDisabled(!isEmailFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormError>
        <span>{errors.email?.message}</span>
      </FormError>
      <InputLabel>{t("form.email.label")}</InputLabel>
      <TextInput
        aria-label="Email"
        type="email"
        resetPassInput
        hasError={errors.email}
        {...register("email", {
          required: t("form.email.required"),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t("forgotPassword.email.invalid"),
          },
        })}
      />
      <Styles.LoginSubmitButton
        standard
        aria-label="ResetPasswordButton"
        disabled={isDisabled}
      >
        {t("resetPassword.form.submit.label")}
      </Styles.LoginSubmitButton>
      <Styles.LoginCancelButton
        standard
        aria-label="CancelResetPasswordButton"
        to={isEndUserLogin ? "/login-user" : "/login"}
      >
        {t("form.cancel")}
      </Styles.LoginCancelButton>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  isEndUserLogin: PropTypes.bool,
};

ForgotPasswordForm.defaultProps = {
  isEndUserLogin: false,
};

export default ForgotPasswordForm;

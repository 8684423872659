import React, { useContext, useState } from "react";
import { FlexibleModal } from "components/FlexibleModal";
import { Box, styled } from "@mui/system";
import { SubmitButton } from "components/global/forms";
import { CancelActionButton } from "components/global/forms/FormButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ReactComponent as CalendarIcon } from "assets/calendar-icon.svg";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { TranslationQueueContext } from "./TranslationQueueContext";
import {
  InnerModalContent,
  InnerModalFooter,
} from "../EventLog/EventLogsModal";
import { selectOracleProfile } from "../../../data/features/translationQueue/translationQueueSlice";

const StyledDateField = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 400;
    border-radius: 0;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .MuiInputBase-input {
    height: auto;
    padding: 10px 14px;
  }

  .MuiButtonBase-root {
    border-radius: 0;
    border-left: 1px solid ${({ theme }) => theme.palette.border.main};
  }
`;

export const removeItemModalContent = ({
  handleCloseModal,
  handleSaveModal,
}) => {
  const { modal } = useContext(TranslationQueueContext);

  return (
    <Box>
      <InnerModalContent>Remove project?</InnerModalContent>
      <InnerModalFooter standard>
        <SubmitButton
          submitRemove
          onClick={() =>
            handleSaveModal({ rowID: modal?.rowID || null }, handleCloseModal)
          }
        >
          Remove project
        </SubmitButton>
        <CancelActionButton onClick={handleCloseModal} cancelModal>
          Cancel
        </CancelActionButton>
      </InnerModalFooter>
    </Box>
  );
};

export const cancelModalContent = ({ handleCloseModal, handleSaveModal }) => {
  const { modal } = useContext(TranslationQueueContext);

  return (
    <Box>
      <InnerModalContent>
        If you cancel this project, it will be cancelled for each target
        language and jobs. Do you still want to cancel it? This action cannot be
        undone!
      </InnerModalContent>
      <InnerModalFooter>
        <SubmitButton
          onClick={() =>
            handleSaveModal({ rowID: modal?.rowID || null }, handleCloseModal)
          }
        >
          Cancel job
        </SubmitButton>
        <CancelActionButton onClick={handleCloseModal}>
          Cancel
        </CancelActionButton>
      </InnerModalFooter>
    </Box>
  );
};

export const removeItemsModalContent = ({
  handleCloseModal,
  handleSaveModal,
}) => (
  <Box>
    <InnerModalContent>Remove items?</InnerModalContent>
    <InnerModalFooter standard>
      <SubmitButton
        onClick={() => handleSaveModal(handleCloseModal)}
        submitRemove
      >
        Remove items
      </SubmitButton>
      <CancelActionButton onClick={handleCloseModal} cancelModal>
        Cancel
      </CancelActionButton>
    </InnerModalFooter>
  </Box>
);

export const changeDueDateModalContent = ({
  handleCloseModal,
  handleSaveModal,
}) => {
  const [value, setValue] = useState(new Date());
  const { modal } = useContext(TranslationQueueContext);
  const oracleProfile = useSelector(selectOracleProfile);

  return (
    <Box>
      <InnerModalContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            components={{ OpenPickerIcon: () => <CalendarIcon /> }}
            renderInput={(props) => <StyledDateField {...props} />}
            value={value}
            ampm={false}
            onChange={(newValue) => {
              setValue(newValue);
            }}
          />
        </LocalizationProvider>
      </InnerModalContent>

      <InnerModalFooter>
        <Box>
          <SubmitButton
            submitDueDate
            onClick={() =>
              handleSaveModal(
                {
                  value,
                  rowID: modal?.rowID || null,
                  jobID: modal?.jobID || null,
                  oracleProfileID: oracleProfile || null,
                },
                handleCloseModal
              )
            }
          >
            Save
          </SubmitButton>
          <CancelActionButton onClick={handleCloseModal} cancelModal>
            Cancel
          </CancelActionButton>
        </Box>
      </InnerModalFooter>
    </Box>
  );
};

export const TranslationQueueModals = () => {
  const { modal, setModal } = useContext(TranslationQueueContext);

  const handleCloseModal = () => {
    setModal((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <Box>
      <FlexibleModal
        heading={modal.heading}
        modalOpen={modal.isOpen}
        handleCloseModal={handleCloseModal}
        handleSaveModal={modal.saveCallback}
        renderContent={modal.content}
        maxWidth={modal.maxWidth}
      />
    </Box>
  );
};

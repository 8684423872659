import { isAfter, subMinutes } from "date-fns";
import jwtDecode from "jwt-decode";
import { LocalStorageService } from "./LocalStorageService";

export class RefreshTokenService {
  static canRefreshToken = () => {
    if (!LocalStorageService.getAuthToken()) {
      return false;
    }
    const loginToken = jwtDecode(LocalStorageService.getAuthToken());

    const tokenDate = subMinutes(new Date(loginToken.exp * 1000), 10);
    const currentDate = new Date();

    return isAfter(currentDate, tokenDate);
  };

  static hasTokenExpired = () => {
    const loginToken = LocalStorageService.getAuthToken();

    if (!loginToken) {
      return true;
    }

    const decodedToken = jwtDecode(loginToken);
    const expirationDate = new Date(decodedToken.exp * 1000);
    const currentDate = new Date().getTime();

    return expirationDate < currentDate;
  };
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as Chevron } from "assets/chevron-collapse.svg";

export const Wrapper = styled("div")`
  position: relative;
  margin: 0 1.7rem;
  padding: 3rem 0;
  width: 100%;
  border: 1px solid #6f8294;
  width: calc(100% - 3.4rem);
  ${({ bottomSpace }) =>
    bottomSpace &&
    `
    margin-bottom: 4rem;
  `}
`;

export const Accordion = styled(MuiAccordion)`
  position: initial;
  box-shadow: none;
  background-color: transparent;
  &.Mui-expanded {
    margin-bottom: 0;
  }
`;

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<Chevron />} {...props} />
))`
  position: absolute;
  top: 0;
  min-height: auto;
  left: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0 0.8rem;
  font-weight: 700;
  background-color: ${({ theme }) => theme.palette.background.light};
  transform: translateY(-50%);
  color: ${({ theme }) => theme.palette.text.main};
  &.Mui-expanded {
    min-height: 0;
  }
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-left: 1rem;
    margin-bottom: 0.313rem;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
`;

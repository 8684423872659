import { styled } from "@mui/system";

export const FormRow = styled("div")`
  display: grid;
  grid-template-columns: 185px 1fr;

  ${({ bottomSpace }) =>
    bottomSpace &&
    `
      margin-bottom: 1.2rem;
    `}

  ${({ topSpace }) =>
    topSpace &&
    `
      padding-top: 1rem;
    `}

  ${({ contained }) =>
    contained &&
    `
      grid-template-columns: 229px 364px;
      justify-content: space-between;
    `}

  ${({ calendar }) =>
    calendar &&
    `
      grid-template-columns: 185px 2.3rem 1fr;
    `}
    
  ${({ group }) =>
    group &&
    `
      padding-left: 3.4rem;
      padding-right: 3.4rem;
    `}

  ${({ groupInner }) =>
    groupInner &&
    `
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    `}

  ${({ vertical }) =>
    vertical &&
    `
    grid-template-columns: 1fr;
    gap: 10px;
    `}

  ${({ autoFlowColumns }) =>
    autoFlowColumns &&
    `
    grid-template-columns: unset;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: 10px;
    `}
  
  ${({ autoFlowColumnsEdit }) =>
    autoFlowColumnsEdit &&
    `
    grid-auto-flow: column;
    justify-content: flex-start;
    `}

  ${({ profile }) => profile && `height: 3.6rem;`}
  
  ${({ editClient }) => editClient && `grid-template-columns: 18.5rem 36rem;`}

  ${({ editClientPad }) => editClientPad && `padding-top: 10px;`}
  
  ${({ editAdmin }) => editAdmin && `grid-template-columns: 18.5rem 36.4rem;`}
`;

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { StyledCheckbox } from "components/global/forms";
import { ReactComponent as Chevron } from "assets/chevron-collapse.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addRowsToExport,
  addRowToExport,
  removeRowFromExport,
  selectCollapsedRows,
  selectRowsToExport,
  toggleRow,
} from "data/features/translationQueue/translationQueueSlice";

export const TranslationQueueProjectActionCell = ({
  value,
  row,
  noCollapse,
}) => {
  const dispatch = useDispatch();
  const rowsToExport = useSelector(selectRowsToExport);
  const collapsedRows = useSelector(selectCollapsedRows);
  const rowId = value || row?.original.project_id;

  const allJobIds = useMemo(
    () =>
      row?.original.languages
        .map((lang) => lang.project_jobs.map((job) => job.xtm_job_id))
        .flat(),
    [row.original]
  );

  const isSelectedToExport =
    (allJobIds.length > 0 &&
      allJobIds.every((id) => rowsToExport.includes(id))) ||
    rowsToExport.includes(rowId);

  const handleChange = () => {
    if (!isSelectedToExport) {
      dispatch(addRowToExport(rowId));
      dispatch(addRowsToExport(allJobIds));
    } else {
      dispatch(removeRowFromExport(rowId));
      allJobIds.forEach((jobId) => dispatch(removeRowFromExport(jobId)));
    }
  };

  const handleCollapse = () => {
    dispatch(toggleRow(rowId));
  };

  useEffect(() => {
    if (isSelectedToExport) {
      dispatch(addRowToExport(rowId));
      dispatch(addRowsToExport(allJobIds));
    } else {
      dispatch(removeRowFromExport(rowId));
    }
  }, [isSelectedToExport]);

  const isRowCollapsed = collapsedRows.includes(rowId);

  return (
    <Box display="flex" alignItems="center">
      <StyledCheckbox
        onChange={handleChange}
        checked={isSelectedToExport}
        color="success"
      />
      {!noCollapse && (
        <IconButton sx={{ marginLeft: "0.9rem" }} onClick={handleCollapse}>
          <Chevron
            style={{
              transition: "0.3s ease",
              transform: `rotate(${isRowCollapsed ? "180" : "0"}deg)`,
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

TranslationQueueProjectActionCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  row: PropTypes.object.isRequired,
  noCollapse: PropTypes.bool,
};

TranslationQueueProjectActionCell.defaultProps = {
  noCollapse: false,
};

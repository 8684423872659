import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";

export const XtmCustomerName = ({ row }) => (
  <Box>{row?.original?.customer_name}</Box>
);

XtmCustomerName.propTypes = {
  row: PropTypes.object.isRequired,
};

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as TranslationQueueInfo } from "assets/translation-queue-info.svg";
import { TranslationQueueContext } from "pages/Panel/TranslationQueue/TranslationQueueContext";
import {
  cancelModalContent,
  changeDueDateModalContent,
  removeItemModalContent,
} from "pages/Panel/TranslationQueue/TranslationQueueModals";
import { StyledMenuItem } from "components/MenuItem";
import {
  cancelProject,
  changeDueDate,
  removeProject,
} from "data/features/translationQueue/translationQueueApi";
import { useDispatch, useSelector } from "react-redux";
import {
  addRefreshTime,
  selectOracleProfile,
} from "data/features/translationQueue/translationQueueSlice";
import { buildDueDate } from "pages/Panel/TranslationQueue/helpers/ChangeDueDateBuilder";
import { Box } from "@mui/system";
import toast from "react-hot-toast";
import { Tooltip } from "../../Tooltip";

const ITEM_HEIGHT = 48;

export function MenuCell({ row }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setModal } = useContext(TranslationQueueContext);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const oracleProfileId = useSelector(selectOracleProfile);

  const xtmProjectID = row.original?.project_id;
  const xtmJobID = row.original?.job_id;
  const xtmStatus = row.original?.xtm_status;
  const oracleProjectId = row.original?.oracle_project_id;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyToClipboard = () => {
    const jobIdText = xtmJobID ? `Project Job ID:  ${xtmJobID}` : "";
    const oracleIdText = oracleProjectId
      ? `Connector Job ID:  ${oracleProjectId}`
      : "";
    navigator.clipboard.writeText(
      `${oracleIdText} Project ID: ${xtmProjectID} ${jobIdText}`
    );
  };

  const options = [
    {
      label: "Change due date",
      callback: () => {
        setModal({
          rowID: xtmProjectID,
          jobID: xtmJobID,
          oracleProfileID: oracleProfileId,
          isOpen: true,
          heading: "Change due date",
          content: changeDueDateModalContent,
          saveCallback: (
            { value, jobID, rowID, oracleProfileID },
            handleCloseModal
          ) => {
            const preparedDate = value.toISOString();
            const preparedData = buildDueDate(preparedDate, rowID, jobID);

            changeDueDate(preparedData, oracleProfileID).then(() => {
              dispatch(addRefreshTime());
            });
            handleCloseModal();
          },
          maxWidth: "292px",
        });
      },
      exclude: ["FINISHED", "CANCELLED"],
    },
    {
      label: "Remove",
      callback: () => {
        setModal({
          rowID: xtmProjectID,
          isOpen: true,
          heading: "Remove",
          content: removeItemModalContent,
          saveCallback: ({ rowID }, handleCloseModal) => {
            removeProject(rowID)
              .then(() => {
                toast.success(`Successfully removed project with id: ${rowID}`);
                dispatch(addRefreshTime());
              })
              .catch((err) => {
                toast.error(err.message);
              });
            handleCloseModal();
          },
          maxWidth: "451px",
        });
      },
      exclude: ["IN_PROGRESS", "SENT", "NEW", "PARTIALLY_FINISHED", "FINISHED"],
    },
    {
      label: "Cancel",
      callback: () => {
        setModal({
          rowID: xtmProjectID,
          isOpen: true,
          heading: "Cancel",
          content: cancelModalContent,
          saveCallback: ({ rowID }, handleCloseModal) => {
            cancelProject(rowID)
              .then(() => {
                toast.success(
                  `Successfully cancelled project with id: ${rowID}`
                );
                dispatch(addRefreshTime());
              })
              .catch((err) => {
                toast.error(err.message);
              });
            handleCloseModal();
          },
        });
      },
      exclude: ["CANCELLED", "FINISHED"],
    },
  ];

  const oracleProjectIdTooltipLine = oracleProjectId ? (
    <>
      <strong>Connector Job ID:</strong> {oracleProjectId} <br />{" "}
    </>
  ) : null;

  const projectJobIdTooltipLine = xtmJobID ? (
    <>
      <strong>Project Job ID:</strong> {xtmJobID} <br />{" "}
    </>
  ) : null;

  const hideCancelAndRemoveInJobs = (option) =>
    xtmJobID && (option.label === "Cancel" || option.label === "Remove")
      ? null
      : option;
  const hideExcluded = (option) => !option.exclude.includes(xtmStatus);

  const optionsToShow = options
    .filter(hideCancelAndRemoveInJobs)
    .filter(hideExcluded);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
      }}
    >
      <Tooltip
        title={
          <>
            {oracleProjectIdTooltipLine}
            <strong>Project ID:</strong> {row?.original?.project_id} <br />
            {projectJobIdTooltipLine}
            (click to copy to clipboard)
          </>
        }
        arrow
        placement="bottom"
      >
        <IconButton onClick={handleCopyToClipboard}>
          <TranslationQueueInfo />
        </IconButton>
      </Tooltip>
      <IconButton
        disabled={optionsToShow.length === 0}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {optionsToShow.map((option) => {
          if (option.exclude.includes(xtmStatus)) {
            return null;
          }
          return (
            <StyledMenuItem key={option.label} onClick={option.callback}>
              {option.label}
            </StyledMenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

MenuCell.propTypes = {
  row: PropTypes.object,
};

MenuCell.defaultProps = {
  row: {},
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
} from "react-table";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import FileDownload from "js-file-download";
import { TableContainer } from "@mui/material";
import {
  TableRow,
  Pagination,
  Search,
  TableColumnCell,
} from "components/TableComponent/components";
import { PAGES_COUNT_OPTIONS } from "components/TableComponent/config";
import { ReactComponent as ExportIcon } from "assets/export-icon.svg";
import { HeadingActionButton } from "components/TableComponent/components/HeadingActionButton";
import { useSelector } from "react-redux";
import {
  selectFilters,
  selectPageSizeLimit,
} from "data/features/translationQueue/translationQueueSlice";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";
import { styled } from "@mui/system";
import { ProjectRow } from "./ProjectRow";
import { TranslationQueueHeading } from "./TranslationQueueHeading";
import { exportProjects } from "../../../../../data/features/translationQueue/translationQueueApi";

const NoResultsTableFound = styled("td")`
  padding: 17.5px 16px;
  width: 100%;
  background-color: #fff;
  font-size: 13px;
`;

export function TableTranslationQueue({
  columns,
  data,
  fetchData,
  totalItems,
  sortId,
  sortObject,
  updateSortObject,
}) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    state: { pageIndex, globalFilter, sortBy },
    gotoPage,
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: PAGES_COUNT_OPTIONS[0],
        sortBy: [{ id: sortId, desc: true }],
      },
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      disableMultiSort: true,
      pageCount: totalItems,
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [initialRender, setInitialRender] = useState(true);
  const { t } = useTranslation();

  const filters = useSelector(selectFilters);
  const pageSizeLimit = useSelector(selectPageSizeLimit);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      fetchData({
        page: pageIndex,
        limit: pageSizeLimit,
        showImported: filters.showImported,
        filter: globalFilter,
        sort: sortObject,
      });
    }
  }, [
    pageIndex,
    pageSizeLimit,
    globalFilter,
    sortObject,
    filters.showImported,
    filters.refreshTimes,
  ]);

  const handleExportButton = () => {
    const projectIds = rows.map((row) => row.original.project_id);
    const { showImported } = filters;
    exportProjects(projectIds, showImported).then((response) => {
      FileDownload(response.data, "export.xlsx");
    });
  };

  const onChangeFilter = useAsyncDebounce((e) => {
    const prevFilters =
      globalFilter !== undefined ? JSON.parse(globalFilter) : {};

    setGlobalFilter(
      JSON.stringify({
        ...prevFilters,
        xtm_project_name: e.target.value || "",
      })
    );
  }, 200);

  const onClearFilter = () => {
    setGlobalFilter("");
  };

  useEffect(() => {
    const sort = sortBy?.[0] ?? sortObject;
    updateSortObject({
      field: camelCase(sort.id) || sortObject.field,
      order: sort.desc ? "DESC" : "ASC",
    });
  }, [sortBy]);

  const colspanLength = headerGroups[0].headers.length;

  return (
    <Box pb={5}>
      <Box
        sx={{ py: "16px", display: "flex", justifyContent: "space-between" }}
      >
        <TranslationQueueHeading setGlobalFilter={setGlobalFilter} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <HeadingActionButton
            exportButton
            onClick={handleExportButton}
            disableRipple
          >
            <ExportIcon />
            {t("translationQueue.export")}
          </HeadingActionButton>
          <Box sx={{ marginLeft: "2.2rem", width: 260 }}>
            <Search
              placeholder={t("translationQueue.search.placeholder")}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer
        sx={{
          maxWidth: "1392px",
          borderBottom: "none",
        }}
      >
        <Table
          {...getTableProps()}
          size="small"
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0 8px",
            background: "transparent",
          }}
        >
          <TableHead sx={{ display: "flex", flexDirection: "column" }}>
            <Pagination
              sx={{
                display: "flex",
                "& .MuiTableCell-root ": {
                  width: "100%",
                },
              }}
              pageSize={pageSizeLimit}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              pageCount={pageCount}
            />
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{
                  backgroundColor: "#DFE3E8 !important",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0.8rem",
                  height: "40px",
                }}
              >
                {headerGroup.headers.map((column) => (
                  <TableColumnCell
                    column={column}
                    key={`column_${column.id}`}
                  />
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {rows.length === 0 && globalFilter && (
              <NoResultsTableFound
                colSpan={colspanLength}
                aria-label="TableRow"
              >
                <span>{t("noResultsFound")}</span>
              </NoResultsTableFound>
            )}
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ProjectRow
                  prepareRow={prepareRow}
                  key={row.id}
                  row={row}
                  getTableProps={getTableProps}
                />
              );
            })}
          </TableBody>
          <Pagination
            sx={{
              marginTop: 0,
              display: "flex",
              "& .MuiTableCell-root ": {
                width: "100%",
              },
            }}
            pageSize={pageSizeLimit}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageCount={pageCount}
          />
        </Table>
      </TableContainer>
    </Box>
  );
}

TableTranslationQueue.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  searchQuery: PropTypes.func,
  sortId: PropTypes.string.isRequired,
  sortObject: PropTypes.object.isRequired,
  updateSortObject: PropTypes.func.isRequired,
  options: PropTypes.shape({
    addLabel: PropTypes.string,
    addUrl: PropTypes.string,
  }),
};

TableTranslationQueue.defaultProps = {
  options: { buttonLabel: "", addUrl: "/" },
  searchQuery: () => {},
};

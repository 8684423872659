import React from "react";
import PropTypes from "prop-types";
import StyledSection from "components/global/Section";
import { useTranslation } from "react-i18next";
import * as Styles from "./styles";
import LoginForm from "./components/LoginForm";

function Login({ isEndUserLogin }) {
  const { t } = useTranslation();

  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox>
        <Styles.LoginBoxHeader>{t("login.box.header")}</Styles.LoginBoxHeader>
        <Styles.LoginBoxContent>
          <LoginForm isEndUserLogin={isEndUserLogin} />
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
}

Login.propTypes = {
  isEndUserLogin: PropTypes.bool,
};

Login.defaultProps = {
  isEndUserLogin: false,
};

export default Login;

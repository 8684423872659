import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { set, uniq } from "lodash";
import { LocalStorageService } from "services";
import { fetchTranslationQueues } from "./translationQueueApi";

const initialState = {
  oracleProfile: LocalStorageService.getOracleProfile(),
  collapsedRows: [],
  rowsToExport: [],
  pageSizeLimit: 10,
  data: {
    isLoading: false,
    error: null,
    data: undefined,
  },
  sort: {
    field: "createdDate",
    order: "DESC",
  },
  filters: {
    projectID: "",
    jobID: "",
    fileName: "",
    sourceLanguages: [],
    targetLanguages: [],
    dateAdded: {
      selection: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    },
    dateDue: {
      selection: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    },
    xtmWorkflowSteps: [],
    xtmWorkflowStatuses: [],
    progress: [0, 100],
    showImported: false,
    refreshTimes: 0,
  },
};

export const fetchTranslationQueuesAsync = createAsyncThunk(
  "translationQueue/fetchTranslationQueues",
  async (params) => {
    const response = await fetchTranslationQueues(params);
    return response.data;
  }
);

export const translationQueueSlice = createSlice({
  name: "translationQueue",
  initialState,
  reducers: {
    setPageSizeLimit: (state, action) => {
      state.pageSizeLimit = action.payload;
    },
    resetPageSizeLimit: (state) => {
      state.pageSizeLimit = 10;
    },
    setOracleProfile: (state, action) => {
      state.oracleProfile = action.payload;
    },
    clearOracleProfile: (state) => {
      state.oracleProfile = null;
    },
    addRefreshTime: (state) => {
      state.filters.refreshTimes += 1;
    },
    toggleShowImported: (state) => {
      state.filters.showImported = !state.filters.showImported;
    },
    addRowToExport: (state, action) => {
      state.rowsToExport.push(action.payload);
      state.rowsToExport = uniq(state.rowsToExport);
    },
    addRowsToExport: (state, action) => {
      state.rowsToExport.push(...action.payload);
      state.rowsToExport = uniq(state.rowsToExport);
    },
    setRowsToExport: (state, action) => {
      state.rowsToExport = uniq(action.payload);
    },
    removeRowFromExport: (state, action) => {
      state.rowsToExport = state.rowsToExport.filter(
        (row) => row !== action.payload
      );
    },
    removeAllRowsFromExport: (state) => {
      state.rowsToExport = [];
    },
    setField(state, action) {
      set(state.filters, action.payload.field, action.payload.value);
    },
    resetFiltersState: (state) => {
      state.filters = initialState.filters;
    },
    toggleAllRows: (state, action) => {
      state.collapsedRows = state.collapsedRows.length ? [] : action.payload;
    },
    toggleRow: (state, action) => {
      const itemIndex = state.collapsedRows.indexOf(action.payload);

      if (itemIndex === -1) {
        state.collapsedRows.push(action.payload);
      } else {
        state.collapsedRows.splice(itemIndex, 1);
      }
    },
    setProgress: (state, action) => {
      state.filters.progress = action.payload;
    },
    updateSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchTranslationQueuesAsync.pending]: (state) => {
      state.data.isLoading = true;
    },
    [fetchTranslationQueuesAsync.fulfilled]: (state, action) => {
      state.data.isLoading = false;
      state.data.error = null;
      state.data.data = action.payload;
    },
    [fetchTranslationQueuesAsync.rejected]: (state, action) => {
      state.data.isLoading = false;
      state.data.error = action.payload;
    },
  },
});

export const selectCollapsedRows = ({ translationQueue }) =>
  translationQueue.collapsedRows;
export const selectRowsToExport = ({ translationQueue }) =>
  translationQueue.rowsToExport;
export const selectProgress = ({ translationQueue }) =>
  translationQueue.filters.progress;

export const selectData = ({ translationQueue }) => translationQueue.data.data;
export const selectLoading = ({ translationQueue }) =>
  translationQueue.data.isLoading;
export const selectError = ({ translationQueue }) =>
  translationQueue.data.error;

export const selectOracleProfile = ({ translationQueue }) =>
  translationQueue.oracleProfile;

export const selectTranslationQueueSort = ({ translationQueue }) =>
  translationQueue.sort;

export const selectFilters = ({ translationQueue }) => translationQueue.filters;

export const selectPageSizeLimit = ({ translationQueue }) =>
  translationQueue.pageSizeLimit;

export const {
  toggleAllRows,
  toggleRow,
  addRowToExport,
  removeRowFromExport,
  setProgress,
  setField,
  removeAllRowsFromExport,
  setRowsToExport,
  setOracleProfile,
  addRowsToExport,
  resetFiltersState,
  toggleShowImported,
  addRefreshTime,
  updateSort,
  clearOracleProfile,
  setPageSizeLimit,
  resetPageSizeLimit,
} = translationQueueSlice.actions;

export default translationQueueSlice.reducer;

import { styled } from "@mui/system";

export const TextInput = styled("input")`
  width: 100%;
  min-height: 36px;
  outline: none;
  padding: 0.8rem;
  font-size: 1.3rem;
  line-height: 1.523rem;
  color: ${({ theme }) => theme.palette.text.main};
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme }) => theme.palette.white.main};

  ${({ hasError, theme }) =>
    hasError &&
    `
		border: 1px solid ${theme.palette.error.main};
	`}

  ${({ password }) =>
    password &&
    `
      background-color: inherit;
      border: none;
      width: 30rem;
    `}

  ${({ editClientPassword }) =>
    editClientPassword &&
    `
      padding-left: 0;
    `}

${({ filter }) =>
    filter &&
    `
    min-height: 31px;
	padding:0 0.8rem;
    `}

  ${({ profileInput }) => profileInput && ` height: 3.6rem; `}
  
  ${({ loginInput }) =>
    loginInput &&
    ` 
    padding: 0.6rem;
    min-height: 3.2rem;
    min-width: 30rem;
  `}

  ${({ resetPassInput }) =>
    resetPassInput &&
    ` 
    padding: 0.6rem;
    min-height: 3.2rem;
    width: 30rem;
  `}

  ${({ editClientInput }) =>
    editClientInput &&
    ` 
    padding: 0.6rem;
    min-height: 3.6rem;
    width: 36.5rem;
  `}

  ${({ adminInput }) =>
    adminInput &&
    ` 
    padding: 0.6rem;
    min-height: 3.6rem;
    width: 36.4rem;
  `}
`;

import axios from "axios";

export const fetchEventLogs = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "name", order: "ASC" },
} = {}) =>
  axios.get("/eventlog", {
    params: {
      filter: String(filter),
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
  });

export const fetchEventLogNotifications = (profileID) =>
  axios.get("/eventlog/notifications", {
    params: {
      connection_profile_id: profileID,
    },
  });

export const fetchOracleProfiles = () => axios.get("/eventlog/profile-names");

export const exportEventLogsToCsv = (rowIds) =>
  axios.post("/eventlog/exportToCsv", rowIds);

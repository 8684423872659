import React, { useState } from "react";

import MUITInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as SearchIcon } from "assets/table-search-icon.svg";
import { useTranslation } from "react-i18next";

export const Search = styled(({ onClear, ...props }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const handleClear = () => {
    setValue("");
    onClear();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  };

  return (
    <MUITInput
      {...props}
      value={value}
      onChange={handleChange}
      placeholder={props.placeholder || t("table.search")}
      disableUnderline
      startAdornment={
        <InputAdornment sx={{ paddingLeft: "4px" }} position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment
          position="end"
          sx={{ cursor: "pointer" }}
          onClick={handleClear}
        >
          {value !== "" && <ClearIcon />}
        </InputAdornment>
      }
    />
  );
})`
  width: 100%;
  max-width: 260px;
  max-height: 32px;
  outline: none;
  padding: 0.5rem;
  font-size: 13px;
  height: 32px;
  color: ${({ theme }) => theme.palette.text.main};
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme }) => theme.palette.white.main};
`;

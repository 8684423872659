import React from "react";
import Header from "components/Header";
import { Routes, Route } from "react-router-dom";
import Login from "pages/Login/Login";
import ForgotPassword from "pages/Login/ForgotPassword";
import ChangePassword from "pages/Login/ChangePassword";
import Administrators from "pages/Panel/Administrators/Administrators";
import Panel from "pages/Panel";
import { EventLog } from "pages/Panel/EventLog";
import Logout from "pages/Logout";
import { ProtectedRoute, GuestRoute } from "components/routes";
import { AddClient, EditClient, Clients } from "pages/Panel/Clients";
import AddAdministrator from "pages/Panel/Administrators/AddAdministrator";
import EditAdministrator from "pages/Panel/Administrators/EditAdministrator";
import { AddUser, DuplicateUser, EditUser, Users } from "pages/Panel/Users";
import { OracleProfiles } from "pages/Panel/OracleProfiles";
import { TranslationQueue } from "pages/Panel/TranslationQueue";
import { OracleProfileForm } from "pages/Panel/OracleProfiles/OracleProfileForm";
import { ORACLE_PROFILE_FORM_TYPES } from "constants/OracleProfileFormTypes";
import { SelectOracleProfile } from "pages/SelectOracleProfile";
import Page404 from "pages/HttpErrors/404Page";
import Page500 from "pages/HttpErrors/500Error";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Panel tab={<Clients />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <Panel tab={<Clients />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/add"
          element={
            <ProtectedRoute>
              <AddClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/edit"
          element={
            <ProtectedRoute>
              <EditClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrators"
          element={
            <ProtectedRoute>
              <Panel tab={<Administrators />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrators/add"
          element={
            <ProtectedRoute>
              <AddAdministrator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrators/edit"
          element={
            <ProtectedRoute>
              <EditAdministrator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Panel tab={<Users />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/add"
          element={
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/edit"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/duplicate"
          element={
            <ProtectedRoute>
              <DuplicateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/oracle-profiles"
          element={
            <ProtectedRoute>
              <Panel tab={<OracleProfiles />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/oracle-profiles/add"
          element={
            <ProtectedRoute>
              <OracleProfileForm type={ORACLE_PROFILE_FORM_TYPES.ADD} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/oracle-profiles/edit"
          element={
            <ProtectedRoute>
              <OracleProfileForm type={ORACLE_PROFILE_FORM_TYPES.EDIT} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/oracle-profiles/duplicate"
          element={
            <ProtectedRoute>
              <OracleProfileForm type={ORACLE_PROFILE_FORM_TYPES.DUPLICATE} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event-log"
          element={
            <ProtectedRoute>
              <Panel tab={<EventLog />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/translation-queue"
          element={
            <ProtectedRoute>
              <Panel tab={<TranslationQueue />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/select-oracle-profile"
          element={
            <ProtectedRoute>
              <SelectOracleProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
        />
        <Route
          path="/login-user"
          element={
            <GuestRoute>
              <Login isEndUserLogin />
            </GuestRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <GuestRoute>
              <ForgotPassword />
            </GuestRoute>
          }
        />
        <Route
          path="/user/forgot-password"
          element={
            <GuestRoute>
              <ForgotPassword isEndUserLogin />
            </GuestRoute>
          }
        />
        <Route
          path="/change-password/:key"
          element={
            <GuestRoute>
              <ChangePassword />
            </GuestRoute>
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/server-error" element={<Page500 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;

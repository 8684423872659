import {
  DateCell,
  SelectRowCell,
  SelectAllRowCell,
} from "components/TableComponent/cells";
import { EventLogActionsCell } from "components/TableComponent/cells/EventLogActionsCell";
import { MessageCell } from "components/TableComponent/cells/MessageCell";

export const eventLogsColumns = [
  {
    Header: SelectAllRowCell,
    accessor: "id",
    width: "auto",
    disableSortBy: true,
    Cell: SelectRowCell,
  },
  {
    Header: "Type",
    accessor: "type",
    width: "74px",
  },
  {
    Header: "Message",
    accessor: "message",
    width: "658px",
    Cell: MessageCell,
  },
  {
    Header: "Oracle profiles",
    accessor: "connection_profile_name",
    width: "297px",
  },
  {
    Header: "Event Date",
    accessor: "created_date_time",
    width: "200px",
    Cell: DateCell,
  },
  {
    Header: "Actions",
    accessor: "actions",
    width: "auto",
    disableSortBy: true,
    Cell: EventLogActionsCell,
  },
];

import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageService } from "services";

const initialState = {
  isLoggedIn: !!LocalStorageService.getAuthToken(),
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const selectAuthState = ({ auth }) => auth.isLoggedIn;

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;

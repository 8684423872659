import axios from "axios";

export const fetchLogin = (
  isEndUserLogin,
  { email = "", password = "", client = "" } = {}
) => {
  if (isEndUserLogin) {
    return axios.post("auth/end-user/login", {
      email: String(email),
      password: String(password),
      client: String(client),
    });
  }
  return axios.post("auth/login", {
    email: String(email),
    password: String(password),
  });
};

export const fetchForgotPassword = ({ email = "", subject = "" } = {}) =>
  axios.post("auth/forgot-password", null, {
    params: {
      email: String(email),
      subject: String(subject),
    },
  });

export const fetchChangePassword = ({ key = "", newPassword = "" } = {}) =>
  axios.post("auth/change-password", {
    key: String(key),
    new_password: String(newPassword),
  });

export const fetchRefreshToken = (token) =>
  axios.post("auth/refresh-token", {
    refresh_token: String(token),
  });

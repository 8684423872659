import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchOracleProfiles } from "./oracleProfileApi";

const initialState = {
  isLoading: false,
  error: null,
  data: undefined,
  sort: {
    field: "id",
    order: "DESC",
  },
};

export const fetchOracleProfilesAsync = createAsyncThunk(
  "oracleProfiles/fetchOracleProfiles",
  async (params) => {
    const response = await fetchOracleProfiles(params);
    return response.data;
  }
);

export const oracleProfilesSlice = createSlice({
  name: "oracleProfiles",
  initialState,
  reducers: {
    clearOracleProfiles: (state) => {
      state.data = undefined;
      state.error = null;
      state.isLoading = false;
    },
    updateSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchOracleProfilesAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchOracleProfilesAsync.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchOracleProfilesAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const selectOracleProfilesData = ({ oracleProfiles }) =>
  oracleProfiles.data;
export const selectOracleProfilesError = ({ oracleProfiles }) =>
  oracleProfiles.error;
export const selectOracleProfilesIsLoading = ({ oracleProfiles }) =>
  oracleProfiles.isLoading;
export const selectOracleProfilesSort = ({ oracleProfiles }) =>
  oracleProfiles.sort;
export const selectOracleTempCredential = ({ oracleProfiles }) =>
  oracleProfiles.oracleTempCredential;
export const selectXtmTempCredential = ({ oracleProfiles }) =>
  oracleProfiles.xtmTempCredential;

export const { clearOracleProfiles, updateSort } = oracleProfilesSlice.actions;

export default oracleProfilesSlice.reducer;

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { ReactComponent as Chevron } from "assets/chevron-collapse.svg";
import { StyledCheckbox } from "components/global/forms";
import { Tooltip } from "components/Tooltip";
import { Box } from "@mui/system";
import {
  setRowsToExport,
  removeAllRowsFromExport,
  selectCollapsedRows,
  // selectRowsToExport,
  toggleAllRows,
} from "data/features/translationQueue/translationQueueSlice";

export const TranslationQueueActionsHeader = ({ data }) => {
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  // const rowsToExport = useSelector(selectRowsToExport);
  const rowsCollapsed = useSelector(selectCollapsedRows);

  const projectIds = useMemo(
    () => data.map((project) => project.project_id),
    [data]
  );

  const handleSelect = () => {
    setIsSelected((prevState) => !prevState);
  };

  const handleCollapse = () => {
    if (rowsCollapsed.length) {
      dispatch(toggleAllRows());
    } else {
      dispatch(toggleAllRows([...data.map((row) => row.project_id)]));
    }
  };

  useEffect(() => {
    if (isSelected) {
      dispatch(setRowsToExport(projectIds));
    } else {
      dispatch(removeAllRowsFromExport());
    }
  }, [isSelected]);

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Select all" arrow placement="bottom">
        <StyledCheckbox
          onChange={handleSelect}
          checked={isSelected}
          color="success"
        />
      </Tooltip>
      <IconButton sx={{ marginLeft: "0.9rem" }} onClick={handleCollapse}>
        <Chevron
          style={{
            transition: "0.3s ease",
            transform: `rotate(${rowsCollapsed.length ? "180" : "0"}deg)`,
          }}
        />
      </IconButton>
    </Box>
  );
};

TranslationQueueActionsHeader.propTypes = {
  data: PropTypes.array.isRequired,
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  CancelButton,
  SubmitButton,
  StyledCheckbox,
  FormError,
} from "components/global/forms";
import { WhiteSelect, MenuItem } from "components/Select";
import { Tooltip } from "components/Tooltip";
import { fetchClients } from "data/features/clients/clientsApi";
import { addUser } from "data/features/users/usersApi";
import { LocalStorageService } from "services";
import { Box } from "@mui/system";
import { checkPasswordStrength } from "helpers/checkPasswordStrength";
import { Heading, ButtonsContainer } from "../styles";

export const AddUser = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [clients, setClients] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const isClientAdmin =
    LocalStorageService.getUserAuthority() === "CLIENT_ADMINISTRATOR";

  useEffect(() => {
    if (isClientAdmin) {
      const loggedInUserData = LocalStorageService.getLoggedInUserData();
      setValue("clientAssigned", loggedInUserData.client);
      setValue("language", loggedInUserData.language);
    }
    fetchClients({ limit: 999 })
      .then((res) => {
        setClients(res.data.objects);
      })
      .catch((err) => toast.error(err));
  }, []);

  const onSubmit = (data) => {
    const { email, password, firstName, lastName, clientAssigned, language } =
      data;

    setIsDisabled(true);
    addUser({
      email,
      password,
      firstName,
      lastName,
      clientAssigned,
      language,
      status: isActive ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/users", { replace: true });
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled =
        value.email.length &&
        value.password.length &&
        value.confirmPassword.length &&
        value.firstName.length &&
        value.lastName.length &&
        value.clientAssigned &&
        value.clientAssigned !== "none" &&
        value.language;
      setIsDisabled(!areFieldsFilled);
      setShowTooltip(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const handleActiveCheckboxChange = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("users.add.label")}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow bottomSpace>
            <InputLabel centered lead>
              {t("form.email.label")}*
            </InputLabel>
            <TextInput
              adminInput
              type="email"
              autoComplete="new-email"
              hasError={errors.email}
              {...register("email", {
                required: t("form.email.required"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("form.email.invalid"),
                },
              })}
            />
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel centered lead>
              {t("form.password.label")}*
            </InputLabel>
            <Box>
              <TextInput
                adminInput
                aria-label="Password"
                type="password"
                hasError={errors.password}
                {...register("password", {
                  required: t("form.password.required"),
                  validate: {
                    passwordStrength: (value) =>
                      checkPasswordStrength(value) ||
                      t("form.password.strength"),
                  },
                })}
              />
              {errors?.password && (
                <FormError secondary>{errors?.password?.message}</FormError>
              )}
            </Box>
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel lead centered>
              {t("form.confirmPassword.label")}*
            </InputLabel>
            <Box>
              <TextInput
                adminInput
                aria-label="ConfirmPassword"
                type="password"
                hasError={errors.confirmPassword}
                {...register("confirmPassword", {
                  required: t("form.confirmPassword.required"),
                  validate: {
                    samePassword: (value) =>
                      value === watch("password") ||
                      t("form.passwordsDoNotMatch"),
                  },
                })}
              />
              {errors?.confirmPassword && (
                <FormError secondary>
                  {errors?.confirmPassword?.message}
                </FormError>
              )}
            </Box>
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel centered lead>
              {t("users.form.firstName.label")}*
            </InputLabel>
            <TextInput
              adminInput
              hasError={errors.firstName}
              {...register("firstName", {
                required: t("users.form.firstName.required"),
              })}
            />
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel centered lead>
              {t("users.form.lastName.label")}*
            </InputLabel>
            <TextInput
              adminInput
              hasError={errors.lastName}
              {...register("lastName", {
                required: t("users.form.lastName.required"),
              })}
            />
          </FormRow>
          {clients && (
            <FormRow bottomSpace editAdmin>
              <InputLabel centered lead>
                {t("form.clientAssigned.label")}*
              </InputLabel>
              <WhiteSelect
                sx={{ width: 364 }}
                value={watch("clientAssigned") ?? "none"}
                {...register("clientAssigned", {
                  required: t("form.clientAssigned.required"),
                })}
                readOnly={isClientAdmin}
              >
                <MenuItem value="none" className="hidden">
                  Choose...
                </MenuItem>
                {clients?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </WhiteSelect>
            </FormRow>
          )}
          <FormRow bottomSpace editAdmin>
            <InputLabel centered lead>
              {t("users.form.language.label")}*
            </InputLabel>
            <WhiteSelect
              sx={{ width: 364 }}
              defaultValue={watch("language") ?? "ENGLISH"}
              {...register("language", {
                required: t("users.form.language.required"),
              })}
            >
              <MenuItem value="ENGLISH">English</MenuItem>
            </WhiteSelect>
          </FormRow>
          <FormRow bottomSpace editClientPad>
            <InputLabel lead>{t("status.active")}</InputLabel>
            <div>
              <StyledCheckbox
                checked={isActive}
                onChange={handleActiveCheckboxChange}
                color="success"
              />
            </div>
          </FormRow>
          <ButtonsContainer editAdmin>
            <CancelButton standard aria-label="CancelAddUserButton" to="/users">
              {t("form.cancel")}
            </CancelButton>
            <Tooltip
              title={showTooltip ? t("tooltip.fillInAllMandatoryFields") : ""}
              arrow
              placement="bottom"
            >
              <div>
                <SubmitButton
                  standard
                  aria-label="AddUserButton"
                  disabled={isDisabled}
                  type="submit"
                >
                  {t("form.save")}
                </SubmitButton>
              </div>
            </Tooltip>
          </ButtonsContainer>
        </form>
      </InnerSectionContainer>
    </StyledSection>
  );
};

export const getOnlyCancelledProjectsIds = ({ rowsToExport, data }) =>
  data.content
    .filter((project) => rowsToExport.includes(project.project_id))
    .filter((project) => project.xtm_status === "CANCELLED")
    .map((project) => project.project_id);

export const hasAnyNotCancelledProjects = ({ rowsToExport, data }) =>
  data.content
    .filter((project) => rowsToExport.includes(project.project_id))
    .some((project) => project.xtm_status !== "CANCELLED");

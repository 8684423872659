import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Controller, useWatch } from "react-hook-form";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import { ReactComponent as InfoCircleIcon } from "assets/info-circle-icon.svg";
import {
  connectToOracle,
  submitOracleProfile,
} from "data/features/oracleProfiles/oracleProfileApi";
import {
  InputLabel,
  FormRow,
  TextInput,
  SubmitButton,
  StyledCheckbox,
} from "components/global/forms";
import { BorderedSection } from "components/BorderedSection";
import { CollapsibleSection } from "components/CollapsibleSection";
import { Tooltip } from "components/Tooltip";
import { MultiSelect } from "components/MultiSelect";
import { ORACLE_PROFILE_FORM_TYPES } from "constants/OracleProfileFormTypes";
import { SOURCE_FILES_EXTENSIONS } from "../constants";

export const OracleSettings = ({ form, profileId, type }) => {
  const { t } = useTranslation();
  const { control, register, watch, getValues, setValue } = form;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isOracleConnected, setIsOracleConnected] = useState(false);
  const [showTooltip, setShowTooltip] = useState(!profileId);
  const watchEnableProcessingFiles = watch("enableProcessingMediaFiles");
  const watchOracleTempCredential = watch("oracleTempCredential");
  const watchOracleConnectFields = useWatch({
    control,
    name: ["oracleClientId", "oracleClientSecret", "oracleScope", "oracleUrl"],
  });

  const handleOracleConnection = () => {
    if (type === ORACLE_PROFILE_FORM_TYPES.EDIT) {
      submitOracleProfile({
        data: getValues(),
        type: ORACLE_PROFILE_FORM_TYPES.EDIT,
        id: profileId,
      }).catch((error) => {
        setIsSubmitDisabled(false);
        toast.error(error.response.data.message);
      });
    }
  };
  const handleOnSubmit = () => {
    const values = getValues();
    const {
      oracleClientId,
      oracleClientSecret,
      oracleScope,
      oracleUrl,
      oracleIdcs,
    } = values;

    if (!isSubmitDisabled) {
      setIsSubmitDisabled(true);
      connectToOracle({
        clientId: oracleClientId,
        clientSecret: oracleClientSecret,
        scope: oracleScope,
        url: oracleUrl,
        idcs: oracleIdcs,
      })
        .then((response) => {
          setValue("oracleTempCredential", response.data);
          handleOracleConnection();
          toast.success(t("oracleProfiles.form.oracle.submit.connected.toast"));

          return response;
        })
        .catch((error) => {
          setIsSubmitDisabled(false);
          toast.error(error.message);

          return error;
        });
    }
  };

  useEffect(() => {
    if (profileId || watchOracleTempCredential) {
      setIsOracleConnected(true);
    }
  }, [profileId, watchOracleTempCredential]);

  useEffect(() => {
    const areFieldsFilled = watchOracleConnectFields.every(
      (field) => field?.trim().length
    );
    setIsSubmitDisabled(!areFieldsFilled);
    setShowTooltip(!areFieldsFilled);
    if (watchOracleConnectFields.some((field) => field !== undefined)) {
      setIsOracleConnected(false);
    }
  }, [watchOracleConnectFields]);

  return (
    <BorderedSection name={t("oracleProfiles.form.oracle.sectionName")}>
      <CollapsibleSection
        name={`${t("oracleProfiles.form.oracle.subSectionName.loginDeails")}*`}
        bottomSpace
        isHidden={isOracleConnected}
        accordionContent={
          <>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.oracle.client.label")}*
              </InputLabel>
              <TextInput {...register("oracleClientId")} />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.oracle.secret.label")}*
              </InputLabel>
              <TextInput {...register("oracleClientSecret")} />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.oracle.idcs.label")}*
              </InputLabel>
              <TextInput {...register("oracleIdcs")} />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.oracle.url.label")}*
              </InputLabel>
              <TextInput {...register("oracleUrl")} />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.oracle.scope.label")}*
              </InputLabel>
              <TextInput {...register("oracleScope")} />
            </FormRow>
          </>
        }
        outerContent={
          <FormRow contained groupInner>
            <InputLabel />
            <Tooltip
              title={showTooltip ? t("tooltip.fillInAllMandatoryFields") : ""}
              arrow
              placement="top"
            >
              <div>
                <SubmitButton
                  profileSubmit
                  type="button"
                  connectButton
                  notConnected={isOracleConnected !== isSubmitDisabled}
                  onClick={handleOnSubmit}
                  style={{
                    pointerEvents:
                      isOracleConnected || isSubmitDisabled ? "none" : "all",
                  }}
                >
                  <Box mr="0.8rem" sx={{ display: "flex" }}>
                    <LinkIcon />
                  </Box>
                  {isOracleConnected
                    ? t("oracleProfiles.form.oracle.submit.connected")
                    : t("oracleProfiles.form.oracle.submit.connect")}
                </SubmitButton>
              </div>
            </Tooltip>
          </FormRow>
        }
      />
      <CollapsibleSection
        name={`${t("oracleProfiles.form.oracle.subSectionName.mediaFiles")}`}
        accordionContent={
          <>
            <FormRow
              contained
              groupInner
              bottomSpace={watchEnableProcessingFiles}
            >
              <InputLabel lead>
                {t("oracleProfiles.form.oracle.mediaFilesEnable.label")}
              </InputLabel>
              <Controller
                name="enableProcessingMediaFiles"
                control={control}
                render={({ field }) => (
                  <StyledCheckbox
                    {...field}
                    color="success"
                    defaultChecked={watchEnableProcessingFiles}
                  />
                )}
              />
            </FormRow>
            {watchEnableProcessingFiles && (
              <>
                <FormRow contained bottomSpace groupInner>
                  <InputLabel lead>
                    <Box display="flex" alignItems="center" gap="0.8rem">
                      <span>
                        {t("oracleProfiles.form.oracle.sourceFiles.label")}*
                      </span>
                      <Tooltip
                        title={t(
                          "oracleProfiles.form.oracle.sourceFiles.tooltip"
                        )}
                        arrow
                        placement="right"
                        sx={{ fontWeight: "400" }}
                      >
                        <InfoCircleIcon />
                      </Tooltip>
                    </Box>
                  </InputLabel>
                  <Controller
                    name="extensionsSourceFiles"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        sx={{ width: 364 }}
                        searchable
                        placeholder="Choose..."
                        menuItems={SOURCE_FILES_EXTENSIONS}
                      />
                    )}
                  />
                </FormRow>
                <FormRow contained groupInner>
                  <InputLabel lead>
                    <Box display="flex" alignItems="center" gap="0.8rem">
                      <span>
                        {t("oracleProfiles.form.oracle.referenceFiles.label")}*
                      </span>
                      <Tooltip
                        title={t(
                          "oracleProfiles.form.oracle.referenceFiles.tooltip"
                        )}
                        arrow
                        placement="right"
                      >
                        <InfoCircleIcon />
                      </Tooltip>
                    </Box>
                  </InputLabel>
                  <TextInput {...register("extensionsReferenceFiles")} />
                </FormRow>
              </>
            )}
          </>
        }
      />
    </BorderedSection>
  );
};

OracleSettings.propTypes = {
  form: PropTypes.object.isRequired,
  profileId: PropTypes.number,
  type: PropTypes.oneOf(Object.values(ORACLE_PROFILE_FORM_TYPES)).isRequired,
};

OracleSettings.defaultProps = {
  profileId: null,
};

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  clientsReducer,
  authReducer,
  administratorsReducer,
  eventLogsReducer,
  usersReducer,
  oracleProfilesReducer,
} from ".";

import translationQueueReducer from "./features/translationQueue/translationQueueSlice";

const reducers = combineReducers({
  clients: clientsReducer,
  administrators: administratorsReducer,
  eventLogs: eventLogsReducer,
  auth: authReducer,
  users: usersReducer,
  oracleProfiles: oracleProfilesReducer,
  translationQueue: translationQueueReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: reducers,
    preloadedState,
  });

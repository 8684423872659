const getJobsFromLanguages = (languages) =>
  languages.map((language) => language.project_jobs).flat();

const buildJobs = (jobs, rowsToExport, preparedDate) =>
  jobs
    .map((job) => {
      if (
        rowsToExport.includes(job.xtm_job_id) &&
        job.xtm_job_status === "IN_PROGRESS"
      ) {
        return {
          job_id: job.xtm_job_id,
          due_date: preparedDate,
          xtm_job_status: job.xtm_job_status,
        };
      }
      return undefined;
    })
    .filter(Boolean);

const areJobsSelected = (projectId, data, rowsToExport) => {
  const selectedProject = data.find(
    (project) => project.project_id === projectId
  );
  const jobIds = getJobsFromLanguages(selectedProject.languages).map(
    (job) => job.xtm_job_id
  );

  return rowsToExport.some((row) => jobIds.includes(row));
};

const isNotInProgress = (project) => project.xtm_status !== "IN_PROGRESS";

export const buildDueDates = ({ rowsToExport, preparedDate, data }) => {
  const output = {
    projects: [],
  };
  const anyProjectNotInProgress = data
    .filter((project) => rowsToExport.includes(project.project_id))
    .some(isNotInProgress);

  const anyJobNotInProgress = data
    .flatMap((project) => project.languages)
    .flatMap((language) => language.project_jobs)
    .filter((job) => rowsToExport.includes(job.xtm_job_id))
    .some((job) => job.xtm_job_status !== "IN_PROGRESS");

  data.forEach((project) => {
    if (
      rowsToExport.includes(project.project_id) &&
      project.xtm_status === "IN_PROGRESS"
    ) {
      const object = {
        project_id: project.project_id,
        jobs: [],
        due_date: preparedDate,
      };

      output.projects.push(object);
    } else if (areJobsSelected(project.project_id, data, rowsToExport)) {
      const jobs = buildJobs(
        getJobsFromLanguages(project.languages),
        rowsToExport,
        preparedDate
      );

      if (jobs.length > 0) {
        output.projects.push({
          project_id: project.project_id,
          jobs,
        });
      }
    }
  });
  return {
    output,
    isProjectOrJobNotInProgress: !!(
      anyProjectNotInProgress || anyJobNotInProgress
    ),
  };
};

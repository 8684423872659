import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { Fade, Modal } from "@mui/material";
import {
  InnerModal,
  InnerModalContent,
  InnerModalFooter,
  InnerModalHeading,
} from "../../pages/Panel/EventLog/EventLogsModal";
import { SubmitButton } from "../global/forms";
import { CancelActionButton } from "../global/forms/FormButton";

export const ReconfirmationModal = ({
  heading,
  text,
  modalOpen,
  handleClose,
  handleSave,
  maxWidth,
}) => (
  <Modal open={modalOpen} onClose={handleClose} closeAfterTransition>
    <Fade in={modalOpen}>
      <InnerModal maxWidth={maxWidth}>
        <InnerModalHeading>{heading}</InnerModalHeading>
        <InnerModalContent>{text}</InnerModalContent>
        <InnerModalFooter>
          <Box>
            <SubmitButton onClick={handleSave}>Confirm</SubmitButton>
            <CancelActionButton onClick={handleClose}>
              Cancel
            </CancelActionButton>
          </Box>
        </InnerModalFooter>
      </InnerModal>
    </Fade>
  </Modal>
);

ReconfirmationModal.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

ReconfirmationModal.defaultProps = {
  maxWidth: "451px",
};

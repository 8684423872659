import React, { useEffect } from "react";
import StyledSection from "components/global/Section";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import * as Styles from "../Login/styles";

const StyledSubtitle = styled("h2")`
  color: #2e3844;
  font-size: 14px;
  line-height: 16px;
  font-weight: "Roboto";
  padding: 16px 16px 0 16px;
  font-weight: 500;
`;

const StyledText = styled("p")`
  color: #00a4e4;
  font-size: 12px;
  line-height: 16px;
  font-weight: "Roboto";
  padding: 16px;
`;

const StyledSubtext = styled("p")`
  color: #444444;
  font-size: 10px;
  line-height: 16px;
  padding: 0 16px;
`;

function Page500() {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state == null) {
      redirect("/");
    }
  }, []);

  const handleClick = () => {
    window.location.href = "https://xtm.cloud/";
  };

  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox>
        <Styles.LoginBoxHeader>
          {t("httpErrorCode.500.title")}
        </Styles.LoginBoxHeader>
        <StyledSubtitle>{t("httpErrorCode.500.subtitle")}</StyledSubtitle>
        <StyledText>
          <Trans i18nKey="httpErrorCode.500.text" />
        </StyledText>
        <StyledSubtext>{t("httpErrorCode.500.subtext")}</StyledSubtext>
        <Styles.LoginBoxContent>
          <Styles.LoginSubmitButton onClick={handleClick}>
            {t("httpErrorCode.500.button")}
          </Styles.LoginSubmitButton>
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
}

export default Page500;

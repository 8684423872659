import React from "react";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";

import { IconButton, Tooltip } from "components";
import { ReactComponent as Icon } from "assets/edit-list-item.svg";

export const EditCell =
  ({ tooltipContent, redirectParams }) =>
  (tableProps) => {
    const { pathname, searchParam } = redirectParams;
    const navigate = useNavigate();

    const handleClick = () => {
      // eslint-disable-next-line react/destructuring-assignment
      const searchParamValue = tableProps?.row?.values?.[searchParam];
      navigate({
        pathname,
        search: searchParamValue
          ? `?${createSearchParams({ [searchParam]: searchParamValue })}`
          : undefined,
      });
    };

    return (
      <Tooltip title={tooltipContent} arrow placement="top">
        <IconButton standard onClick={handleClick} disableRipple>
          <Icon />
        </IconButton>
      </Tooltip>
    );
  };

EditCell.propTypes = {
  tooltipContent: PropTypes.string,
  redirectParams: PropTypes.shape({
    pathname: PropTypes.string,
    searchParams: PropTypes.object,
  }).isRequired,
};

EditCell.defaultProps = {
  tooltipContent: undefined,
};

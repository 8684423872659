import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TableComponent } from "components";
import { fetchUsersAsync, selectUsersData, selectUsersError } from "data";
import { AddButton } from "components/TableComponent/components";
import { selectUsersSort, updateSort } from "data/features/users/usersSlice";
import Container from "components/global/Container";
import { Navigate } from "react-router-dom";
import { userColumns } from "../helpers";

const UsersHeading = ({ handleAdd }) => {
  const { t } = useTranslation();
  return (
    <AddButton onClick={() => handleAdd("/users/add")}>
      {t("users.add.label")}
    </AddButton>
  );
};

UsersHeading.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectUsersData);
  const error = useSelector(selectUsersError);
  const selectSort = useSelector(selectUsersSort);

  const handleFetchUsers = useCallback(
    (params) => dispatch(fetchUsersAsync(params)),
    []
  );

  const handleUpdateSort = (sort) => {
    dispatch(updateSort(sort));
  };

  const columns = useMemo(() => userColumns, []);

  useEffect(() => {
    handleFetchUsers();
  }, [dispatch]);

  return (
    <>
      {error && <Navigate to="/server-error" state={{ error }} />}
      {data && (
        <Container small>
          <TableComponent
            aria-label="Table"
            fetchData={handleFetchUsers}
            columns={columns}
            searchPlaceholder={t("users.search.placeholder")}
            data={data.objects}
            totalItems={data.total_elements}
            renderHeading={UsersHeading}
            sortObject={selectSort}
            updateSortObject={handleUpdateSort}
          />
        </Container>
      )}
    </>
  );
}

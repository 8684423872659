import { formatEndDate, formatStartDate } from "components/FilterBox/helpers";

export class TranslationQueueQueryBuilder {
  constructor(filters) {
    this.filters = filters;
    this.queries = [];
  }

  buildProjectID() {
    if (this.filters.projectID) {
      this.queries.push(`"xtm_project_id":"${this.filters.projectID}"`);
    }
  }

  buildJobID() {
    if (this.filters.jobID) {
      this.queries.push(`"job_id":"${this.filters.jobID}"`);
    }
  }

  buildFileName() {
    if (this.filters.fileName) {
      this.queries.push(`"file_name":"${this.filters.fileName}"`);
    }
  }

  buildSourceLanguage() {
    if (this.filters.sourceLanguages.length) {
      this.queries.push(
        `"source_languages":[${this.filters.sourceLanguages
          .map((item) => `"${item}"`)
          .join(",")}]`
      );
    }
  }

  buildTargetLanguages() {
    if (this.filters.targetLanguages.length) {
      this.queries.push(
        `"target_languages":[${this.filters.targetLanguages
          .map((item) => `"${item}"`)
          .join(",")}]`
      );
    }
  }

  buildDateAdded() {
    const { startDate, endDate } = this.filters.dateAdded.selection;
    if (startDate && endDate) {
      this.queries.push(
        `"later_than_added_date":"${formatStartDate(
          startDate
        )}","earlier_than_added_date":"${formatEndDate(endDate)}"`
      );
    }
  }

  buildDateDue() {
    const { startDate, endDate } = this.filters.dateDue.selection;
    if (startDate && endDate) {
      this.queries.push(
        `"later_than_due_date": "${formatStartDate(
          startDate
        )}","earlier_than_due_date": "${formatEndDate(endDate)}"`
      );
    }
  }

  buildXtmWorkflowStep() {
    if (this.filters.xtmWorkflowSteps.length) {
      this.queries.push(
        `"xtm_workflow_steps":[${this.filters.xtmWorkflowSteps
          .map((item) => `"${item}"`)
          .join(",")}]`
      );
    }
  }

  buildXtmWorkflowStatus() {
    if (this.filters.xtmWorkflowStatuses.length) {
      this.queries.push(
        `"xtm_statuses":[${this.filters.xtmWorkflowStatuses
          .map((item) => `"${item}"`)
          .join(",")}]`
      );
    }
  }

  buildProgress() {
    if (this.filters.progress) {
      this.queries.push(`"progress":"${this.filters.progress}"`);
    }
  }

  buildQuery() {
    this.buildProjectID();
    this.buildJobID();
    this.buildFileName();
    this.buildSourceLanguage();
    this.buildTargetLanguages();
    this.buildDateAdded();
    this.buildDateDue();
    this.buildXtmWorkflowStep();
    this.buildXtmWorkflowStatus();
    this.buildProgress();

    return `{${this.queries.join(",")}}`;
  }
}

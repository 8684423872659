import React, { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const TranslationQueueContext = createContext();

export const initialModalState = {
  isOpen: false,
  heading: "",
  content: () => {},
  footer: () => {},
  rowID: null,
  jobID: null,
  maxWidth: "685px",
};

export const TranslationQueueProvider = ({ children }) => {
  const [modal, setModal] = useState(initialModalState);
  const providerValues = useMemo(
    () => ({
      modal,
      setModal,
    }),
    [modal, setModal]
  );

  return (
    <TranslationQueueContext.Provider value={providerValues}>
      {children}
    </TranslationQueueContext.Provider>
  );
};

TranslationQueueProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

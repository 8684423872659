import React from "react";
import PropTypes from "prop-types";
import { StyledCheckbox } from "components/global/forms";
import { useDispatch, useSelector } from "react-redux";
import { removeRowFromExport, addRowToExport, selectRowsToExport } from "data";

export const SelectRowCell = ({ value }) => {
  const dispatch = useDispatch();
  const rowsToExport = useSelector(selectRowsToExport);

  const handleChange = (ev) => {
    const isSelected = ev.target.checked;
    if (isSelected) {
      dispatch(addRowToExport(value));
    } else {
      dispatch(removeRowFromExport(value));
    }
  };

  const isSelectedToExport = rowsToExport.includes(value);

  return (
    <div>
      <StyledCheckbox
        onChange={handleChange}
        checked={isSelectedToExport}
        defaultValue={value}
        color="success"
      />
    </div>
  );
};

SelectRowCell.propTypes = {
  value: PropTypes.number.isRequired,
};

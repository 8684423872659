import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { ReactComponent as ExaminationCircleIcon } from "assets/examination-circle-icon.svg";

export const ServiceNotConnected = ({ service, maxWidth }) => {
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="#fff"
      padding="10px 15px"
      maxWidth={`${maxWidth}px`}
      border="1px solid #dddd"
      borderTop="0"
    >
      <ExaminationCircleIcon
        style={{
          position: "relative",
          top: "0.2rem",
          left: "-0.2rem",
        }}
      />
      <span style={{ fontSize: "1.3rem", lineHeight: "1.6rem" }}>
        {t("serviceNotConnected", { service })}
      </span>
    </Box>
  );
};

ServiceNotConnected.propTypes = {
  service: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
};

ServiceNotConnected.defaultProps = {
  maxWidth: "100%",
};

import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { LocalStorageService } from "services";

export const GuestRoute = ({ children }) => {
  const userAuthority = LocalStorageService.getUserAuthority();

  if (
    LocalStorageService.getAuthToken() &&
    userAuthority === "MAIN_ADMINISTRATOR"
  ) {
    return <Navigate to="/clients" replace />;
  }

  if (LocalStorageService.getAuthToken() && userAuthority === "END_USER") {
    return <Navigate to="/select-oracle-profile" replace />;
  }

  return children;
};

GuestRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Menu } from "@mui/material";
import { HeadingActionButton } from "components/TableComponent/components/HeadingActionButton";
import { Box } from "@mui/system";
import { ReactComponent as CaretDown } from "assets/caret-down.svg";
import { StyledMenuItem } from "components/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addRefreshTime,
  selectData,
  selectOracleProfile,
  selectRowsToExport,
  setRowsToExport,
} from "data/features/translationQueue/translationQueueSlice";
import {
  changeDueDate,
  removeProject,
} from "data/features/translationQueue/translationQueueApi";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { TranslationQueueContext } from "../../TranslationQueueContext";
import {
  changeDueDateModalContent,
  removeItemsModalContent,
} from "../../TranslationQueueModals";
import { buildDueDates } from "../../helpers/ChangeDueDatesBuilder";
import { ReconfirmationModal } from "../../../../../components/TableComponent/ReconfirmationModal";
import {
  getOnlyCancelledProjectsIds,
  hasAnyNotCancelledProjects,
} from "../../helpers/RemoveProjectUtil";

export const TranslationQueueBulkActions = ({ disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { setModal } = useContext(TranslationQueueContext);
  const rowsToExport = useSelector(selectRowsToExport);
  const data = useSelector(selectData);
  const oracleProfileId = useSelector(selectOracleProfile);
  const { t } = useTranslation();
  const [modalDueDateConfirmationOpen, setModalDueDateConfirmationOpen] =
    useState(false);
  const [modalRemoveConfirmationOpen, setModalRemoveConfirmationOpen] =
    useState(false);
  const [dueDateData, setDueDateData] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function sendRemoveRequests() {
    const onlyCancelledIds = getOnlyCancelledProjectsIds({
      rowsToExport,
      data,
    });

    onlyCancelledIds.forEach((id) => {
      removeProject(id)
        .then(() => {
          toast.success(`Successfully removed project with id: ${id}`);
          dispatch(addRefreshTime());
        })
        .catch((err) => {
          toast.error(err.message);
        });
    });
  }

  const handleClickRemoveItem = () => {
    setAnchorEl(null);
    setModal({
      isOpen: true,
      heading: "Remove",
      content: removeItemsModalContent,
      saveCallback: (handleCloseModal) => {
        if (hasAnyNotCancelledProjects({ rowsToExport, data })) {
          setModalRemoveConfirmationOpen(true);
        } else {
          sendRemoveRequests();
        }
        handleCloseModal();
      },
      maxWidth: "451px",
    });
  };

  const handleChangeDueDates = () => {
    setAnchorEl(null);
    setModal({
      isOpen: true,
      heading: "Change due date",
      maxWidth: "292px",
      oracleProfileID: oracleProfileId,
      content: changeDueDateModalContent,
      saveCallback: ({ value, oracleProfileID }, handleCloseModal) => {
        const preparedDate = value.toISOString();
        const preparedData = buildDueDates({
          rowsToExport,
          preparedDate,
          data: data.content,
        });
        setDueDateData(preparedData);
        if (preparedData.isProjectOrJobNotInProgress) {
          setModalDueDateConfirmationOpen(true);
        } else if (preparedData.output.projects.length !== 0) {
          changeDueDate(preparedData.output, oracleProfileID).then(() => {
            dispatch(addRefreshTime());
            dispatch(setRowsToExport([]));
          });
        }
        handleCloseModal();
      },
    });
  };

  const closeDueDateConfirmationModal = () => {
    setModalDueDateConfirmationOpen(false);
  };

  const saveDueDateConfirmationModal = () => {
    setModalDueDateConfirmationOpen(false);
    if (dueDateData.output.projects.length !== 0) {
      changeDueDate(dueDateData.output, oracleProfileId).then(() => {
        dispatch(addRefreshTime());
        dispatch(setRowsToExport([]));
      });
    }
  };

  const saveRemoveProjectsConfirmationModal = () => {
    setModalRemoveConfirmationOpen(false);
    sendRemoveRequests();
  };

  const closeRemoveProjectsConfirmationModal = () => {
    setModalRemoveConfirmationOpen(false);
  };
  return (
    <>
      <HeadingActionButton
        actionButton
        customDisabled={disabled}
        onClick={handleClick}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "bulk-actions-menu" : undefined}
      >
        Actions
        <Box sx={{ marginLeft: "0.5rem", display: "flex" }}>
          <CaretDown />
        </Box>
      </HeadingActionButton>
      {!disabled && (
        <Menu
          id="bulk-actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <StyledMenuItem onClick={handleChangeDueDates}>
            Change due date
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClickRemoveItem}>
            Remove
          </StyledMenuItem>
        </Menu>
      )}
      <ReconfirmationModal
        modalOpen={modalDueDateConfirmationOpen}
        handleSave={saveDueDateConfirmationModal}
        handleClose={closeDueDateConfirmationModal}
        heading={t("translationQueue.actions.changeDueDate.heading")}
        text={t("translationQueue.actions.changeDueDate.text")}
      />
      <ReconfirmationModal
        modalOpen={modalRemoveConfirmationOpen}
        handleSave={saveRemoveProjectsConfirmationModal}
        handleClose={closeRemoveProjectsConfirmationModal}
        heading={t("translationQueue.actions.removeProjects.heading")}
        text={t("translationQueue.actions.removeProjects.text")}
      />
    </>
  );
};

TranslationQueueBulkActions.propTypes = {
  disabled: PropTypes.bool,
};

TranslationQueueBulkActions.defaultProps = {
  disabled: false,
};

import axios from "axios";

export const changeNotificationStatus = (notificationID, status) => {
  let notificationIds = [];
  if (notificationID.length > 1) {
    notificationIds = notificationID;
  } else {
    notificationIds.push(notificationID);
  }

  return axios.post("/eventlog/status", notificationIds, {
    params: {
      status,
    },
  });
};

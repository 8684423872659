import {
  DateCell,
  StatusCell,
  UserActionsCell,
} from "components/TableComponent/cells";
import i18n from "config/i18n";

const { t } = i18n;

export const userColumns = [
  {
    Header: t("column.id"),
    accessor: "id",
    width: "230px",
  },
  {
    Header: t("column.users.email"),
    accessor: "email",
    width: "250px",
  },
  {
    Header: t("column.users.client"),
    accessor: "client",
    width: "200px",
  },
  {
    Header: t("column.status"),
    accessor: "status",
    width: "150px",
    Cell: StatusCell,
  },
  {
    Header: t("column.dateAdded"),
    accessor: "created_date",
    width: "150px",
    Cell: DateCell,
  },
  {
    Header: t("column.dateModified"),
    accessor: "modification_date",
    width: "150px",
    Cell: DateCell,
  },
  {
    Header: "",
    accessor: "actions",
    canSort: false,
    Cell: UserActionsCell,
  },
];

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Wrapper,
} from "./styles";

export const CollapsibleSection = ({
  name = "",
  accordionContent,
  outerContent,
  bottomSpace,
  isHidden,
}) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (isHidden) {
      setExpanded(!isHidden);
    }
  }, [isHidden]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Wrapper bottomSpace={bottomSpace}>
      <Accordion expanded={expanded} onChange={handleChange(!expanded)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          {name}
        </AccordionSummary>
        <AccordionDetails>{accordionContent}</AccordionDetails>
      </Accordion>
      {outerContent}
    </Wrapper>
  );
};

CollapsibleSection.propTypes = {
  name: PropTypes.string,
  accordionContent: PropTypes.node,
  outerContent: PropTypes.node,
  bottomSpace: PropTypes.bool,
  isHidden: PropTypes.bool,
};

CollapsibleSection.defaultProps = {
  name: "",
  accordionContent: null,
  outerContent: null,
  bottomSpace: false,
  isHidden: false,
};

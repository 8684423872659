import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { InputLabel, TextInput, FormError } from "components/global/forms";
import { fetchLogin } from "data/features/auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "data/features/auth/authSlice";
import { handleLogin } from "helpers/handleLogin";
import { useTranslation, Trans } from "react-i18next";
import * as Styles from "../styles";

const NoCredentialsYetLink = ({ children, href }) => (
  <Styles.NoCredentialsYetLink href={href} target="_blank" rel="noreferrer">
    {children}
  </Styles.NoCredentialsYetLink>
);

NoCredentialsYetLink.propTypes = {
  children: PropTypes.array.isRequired,
  href: PropTypes.string.isRequired,
};

function LoginForm({ isEndUserLogin }) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsDisabled(true);
    fetchLogin(isEndUserLogin, data)
      .then((response) => {
        handleLogin(response);
        dispatch(login());
        if (isEndUserLogin) {
          navigate("/select-oracle-profile", { replace: true });
        } else {
          navigate("/clients", { replace: true });
        }
        return response;
      })
      .catch((error) => {
        setError("email", {
          type: "custom",
          message: error.response?.data?.message,
        });
        setError("password", { type: "custom" });
        return error;
      })
      .then(() => setIsDisabled(false));
  };
  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled =
        value.email.length &&
        value.password.length &&
        (isEndUserLogin ? value.client.length : true);
      setIsDisabled(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormError>
        <span>{errors.client?.message}</span>
      </FormError>
      <FormError>
        <span>{errors.email?.message}</span>
      </FormError>
      <FormError>
        <span>{errors.password?.message}</span>
      </FormError>
      {isEndUserLogin && (
        <>
          <InputLabel>{t("form.client.label")}</InputLabel>
          <TextInput
            loginInput
            type="text"
            aria-label="Client"
            hasError={errors.client}
            {...register("client", {
              required: t("form.client.required"),
            })}
          />
        </>
      )}
      <InputLabel>
        {t(isEndUserLogin ? "form.email.userLabel" : "form.email.label")}
      </InputLabel>
      <TextInput
        loginInput
        type="email"
        aria-label="Email"
        hasError={errors.email}
        {...register("email", {
          required: t("form.email.required"),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t("form.email.invalid"),
          },
        })}
      />
      <InputLabel>{t("form.password.label")}</InputLabel>
      <TextInput
        loginInput
        type="password"
        aria-label="Password"
        hasError={errors.password}
        {...register("password", {
          required: t("form.password.required"),
        })}
      />
      <Styles.ForgotPasswordLink
        aria-label="ForgotPasswordButton"
        to={isEndUserLogin ? "/user/forgot-password" : "/forgot-password"}
      >
        {t("login.form.forgot")}
      </Styles.ForgotPasswordLink>
      {isEndUserLogin && (
        <Styles.NoCredentialsYet>
          <Trans
            i18nKey="login.form.noCredentialsYet"
            components={{
              link1: <NoCredentialsYetLink href="https://xtm.cloud/trial/" />,
              link2: <NoCredentialsYetLink href="https://xtm.cloud/contact/" />,
            }}
          />
        </Styles.NoCredentialsYet>
      )}
      <Styles.LoginSubmitButton aria-label="LoginButton" disabled={isDisabled}>
        {t("login.form.submit.label")}
      </Styles.LoginSubmitButton>
    </form>
  );
}

LoginForm.propTypes = {
  isEndUserLogin: PropTypes.bool,
};

LoginForm.defaultProps = {
  isEndUserLogin: false,
};

export default LoginForm;

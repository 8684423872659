import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { SubmitButton, TextInput } from "components/global/forms";
import { CalendarInput } from "components/CalendarInput/CalendarInput";
import { Tooltip } from "components/Tooltip";
import {
  ClearFiltersButton,
  FilterBoxButtons,
  FilterBoxColumn,
  FilterBoxRelativeContainer,
  FilterBoxFilters,
  FilterLabel,
} from "components/FilterBox/styles";
import { MultiSelect } from "components/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { someFieldIsFilled } from "data/features/translationQueue/helpers/allRequiredFieldsFilled";
import { TranslationQueueQueryBuilder } from "data/features/translationQueue/helpers/TranslationQueueQueryBuilder";
import { fetchFilterOptions } from "data/features/translationQueue/translationQueueApi";
import { useTranslation } from "react-i18next";
import { ProgressSlider } from "./ProgressSlider";
import {
  resetFiltersState,
  selectFilters,
  selectOracleProfile,
  setField,
} from "../../../../../data/features/translationQueue/translationQueueSlice";

export const FilterBox = ({ setGlobalFilter, closeFilterbox }) => {
  const [filterOptions, setFilterOptions] = useState({
    xtm_status: [],
    xtm_workflow_step_names: [],
    target_languages: [],
    source_languages: [],
  });
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const oracleProfile = useSelector(selectOracleProfile);

  useEffect(() => {
    fetchFilterOptions({ profileID: oracleProfile }).then((response) => {
      setFilterOptions(response.data);
    });
  }, []);

  const handleUpdateField = (field) => (ev) => {
    dispatch(setField({ field, value: ev.target.value }));
  };

  const handleSetDueDate = (data) => {
    const value = { ...filters.dateDue, ...data };
    dispatch(setField({ field: "dateDue", value }));
  };

  const handleSetAddedDate = (data) => {
    const value = { ...filters.dateAdded, ...data };
    dispatch(setField({ field: "dateAdded", value }));
  };

  const handleResetFilters = () => {
    setGlobalFilter("");
    dispatch(resetFiltersState());
  };

  const handleApplyFilters = () => {
    if (isButtonDisabled) return;
    const filtersQuery = new TranslationQueueQueryBuilder(filters);

    setGlobalFilter(filtersQuery.buildQuery());
    closeFilterbox();
  };

  useEffect(() => {
    setIsButtonDisabled(someFieldIsFilled(filters));
  }, [filters]);

  return (
    <FilterBoxRelativeContainer
      style={{ width: "78rem", minHeight: "49.319rem" }}
    >
      <FilterBoxFilters>
        <Box display="flex" gap="30px">
          <Box width="50%">
            <FilterBoxColumn>
              <FilterLabel>
                {t("translationQueue.filters.projectID")}
              </FilterLabel>
              <TextInput
                filter
                aria-label="projectID"
                onChange={handleUpdateField("projectID")}
                value={filters.projectID}
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              <FilterLabel>{t("translationQueue.filters.jobID")}</FilterLabel>
              <TextInput
                filter
                aria-label="jobID"
                onChange={handleUpdateField("jobID")}
                value={filters.jobID}
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              <FilterLabel>
                {t("translationQueue.filters.fileName")}
              </FilterLabel>
              <TextInput
                filter
                aria-label="fileName"
                onChange={handleUpdateField("fileName")}
                value={filters.fileName}
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              <FilterLabel>
                {t("translationQueue.filters.sourceLanguage")}
              </FilterLabel>
              <MultiSelect
                smaller
                sx={{ width: 350 }}
                aria-label="sourceLanguage"
                value={filters.sourceLanguages}
                onChange={handleUpdateField("sourceLanguages")}
                onClear={() =>
                  dispatch(setField({ field: "sourceLanguages", value: [] }))
                }
                onSelectAll={(allItems) =>
                  dispatch(
                    setField({ field: "sourceLanguages", value: allItems })
                  )
                }
                onDelete={(items, item) => {
                  const newItems = items.filter((i) => i !== item);
                  dispatch(
                    setField({ field: "sourceLanguages", value: newItems })
                  );
                }}
                searchable
                notFoundMessage={t(
                  "translationQueue.filters.select.noLanguagesFound"
                )}
                placeholder={t("translationQueue.filters.select.placeholder")}
                menuItems={filterOptions?.source_languages}
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              <FilterLabel>
                {t("translationQueue.filters.targetLanguage")}
              </FilterLabel>
              <MultiSelect
                smaller
                sx={{ width: 350 }}
                aria-label="targetLanguage"
                value={filters.targetLanguages}
                onChange={handleUpdateField("targetLanguages")}
                onClear={() =>
                  dispatch(setField({ field: "targetLanguages", value: [] }))
                }
                onSelectAll={(allItems) =>
                  dispatch(
                    setField({ field: "targetLanguages", value: allItems })
                  )
                }
                onDelete={(items, item) => {
                  const newItems = items.filter((i) => i !== item);
                  dispatch(
                    setField({ field: "targetLanguages", value: newItems })
                  );
                }}
                searchable
                notFoundMessage={t(
                  "translationQueue.filters.select.noLanguagesFound"
                )}
                placeholder={t("translationQueue.filters.select.placeholder")}
                menuItems={filterOptions?.target_languages}
              />
            </FilterBoxColumn>
          </Box>
          <Box>
            <FilterBoxColumn standard>
              <FilterLabel>
                {t("translationQueue.filters.dateAdded")}
              </FilterLabel>
              <CalendarInput
                filterDate={filters.dateAdded}
                handleSetDateValue={handleSetAddedDate}
                calendarMaxDate={new Date()}
              />
            </FilterBoxColumn>
            <FilterBoxColumn standard>
              <FilterLabel>{t("translationQueue.filters.dueDate")}</FilterLabel>
              <CalendarInput
                filterDate={filters.dateDue}
                handleSetDateValue={handleSetDueDate}
                futureRanges
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              standard
              <FilterLabel>
                {t("translationQueue.filters.xtmWorkflowStep")}
              </FilterLabel>
              <MultiSelect
                smaller
                sx={{ width: 352 }}
                value={filters.xtmWorkflowSteps}
                onChange={handleUpdateField("xtmWorkflowSteps")}
                onClear={() =>
                  dispatch(setField({ field: "xtmWorkflowSteps", value: [] }))
                }
                onSelectAll={(allItems) =>
                  dispatch(
                    setField({ field: "xtmWorkflowSteps", value: allItems })
                  )
                }
                onDelete={(items, item) => {
                  const newItems = items.filter((i) => i !== item);
                  dispatch(
                    setField({ field: "xtmWorkflowSteps", value: newItems })
                  );
                }}
                searchable
                notFoundMessage={t(
                  "translationQueue.filters.select.noWorkflowStepsFound"
                )}
                placeholder={t("translationQueue.filters.select.placeholder")}
                menuItems={filterOptions?.xtm_workflow_step_names}
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              <FilterLabel>
                {t("translationQueue.filters.xtmStatus")}
              </FilterLabel>
              <MultiSelect
                smaller
                sx={{ width: 352 }}
                value={filters.xtmWorkflowStatuses}
                onChange={handleUpdateField("xtmWorkflowStatuses")}
                onClear={() =>
                  dispatch(
                    setField({ field: "xtmWorkflowStatuses", value: [] })
                  )
                }
                onSelectAll={(allItems) =>
                  dispatch(
                    setField({ field: "xtmWorkflowStatuses", value: allItems })
                  )
                }
                onDelete={(items, item) => {
                  const newItems = items.filter((i) => i !== item);
                  dispatch(
                    setField({ field: "xtmWorkflowStatuses", value: newItems })
                  );
                }}
                searchable
                notFoundMessage={t(
                  "translationQueue.filters.select.noWorkflowStepsFound"
                )}
                placeholder={t("translationQueue.filters.select.placeholder")}
                menuItems={filterOptions?.xtm_status}
              />
            </FilterBoxColumn>
            <FilterBoxColumn>
              <FilterLabel>
                {t("translationQueue.filters.progress")}
              </FilterLabel>
              <ProgressSlider />
            </FilterBoxColumn>
          </Box>
        </Box>
      </FilterBoxFilters>
      <FilterBoxButtons>
        <Tooltip
          title={t("translationQueue.filters.submitButton.tooltip")}
          arrow
          placement="bottom"
          hidden={!isButtonDisabled}
        >
          <SubmitButton
            applyButton
            aria-label="FilterSubmitButton"
            onClick={handleApplyFilters}
            customDisabled={isButtonDisabled}
          >
            {t("translationQueue.filters.submitButton")}
          </SubmitButton>
        </Tooltip>
        <ClearFiltersButton
          resetSearchButton
          aria-label="FilterClearButton"
          onClick={handleResetFilters}
        >
          {t("translationQueue.filters.clearFiltersButton")}
        </ClearFiltersButton>
      </FilterBoxButtons>
    </FilterBoxRelativeContainer>
  );
};

FilterBox.propTypes = {
  closeFilterbox: PropTypes.func.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { Tooltip } from "components/Tooltip";
import { ReactComponent as CopyClipboardIcon } from "assets/copy-clipboard-icon.svg";
import { ReactComponent as InformationIcon } from "assets/information-icon.svg";
import { setModal } from "data";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const ActionsWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 0.5rem;
  }
`;

export const EventLogActionsCell = ({ row }) => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(
      setModal({
        isOpen: true,
        data: row.original.stack_trace,
      })
    );
  };

  const handleCopyToClipboardBoth = () => {
    navigator.clipboard.writeText(
      `${row.original.message}\n${row.original.stack_trace}`
    );
    toast("Stack trace and message copied");
  };

  return (
    <ActionsWrapper>
      <Tooltip title="See stack trace" arrow placement="bottom">
        <IconButton
          sx={{ width: "3rem", height: "3rem", padding: 0 }}
          onClick={handleOpenModal}
        >
          <InformationIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Copy message and its stack trace"
        arrow
        placement="bottom"
      >
        <IconButton
          sx={{ width: "3rem", height: "3rem", padding: 0 }}
          onClick={handleCopyToClipboardBoth}
        >
          <CopyClipboardIcon />
        </IconButton>
      </Tooltip>
    </ActionsWrapper>
  );
};

EventLogActionsCell.propTypes = {
  row: PropTypes.object.isRequired,
};

import { css, styled } from "@mui/system";
import { Link } from "react-router-dom";

const menuItemStyles = css`
  font-size: 1.4rem;
  line-height: 1.641rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &:not(&:last-child) {
    margin-right: 2.6rem;
  }

  svg {
    position: relative;
    top: 0.1rem;
    margin-right: 0.5rem;
  }
`;

export const NavigationLink = styled(Link)`
  ${menuItemStyles};
  color: ${({ color }) => color || "white"};
`;

export const NavigationButton = styled("button")`
  ${menuItemStyles};
  color: ${({ color }) => color || "white"};
  background-color: transparent;
  border: none;
`;

export const ExternalLink = styled("a")`
  color: ${({ color }) => color || "white"};
  font-size: 1.4rem;
  line-height: 1.641rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &:not(&:last-child) {
    margin-right: 2.6rem;
  }

  svg {
    position: relative;
    top: 0.1rem;
    margin-right: 0.5rem;
  }
  /* &:hover {
		color: ${({ theme }) => theme.palette.primary.main};
	} */
`;

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Headline = styled("h1")`
  font-size: 1.6rem;
  padding-left: 3.2rem;
  line-height: 1.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.white.main};
`;

export const Header = styled("header")`
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.palette.background.dark};
  padding: 1.4rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Navigation = styled("nav")(() => ({
  display: "flex",
}));

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { ReactComponent as PlusCircleIcon } from "assets/plus-circle-icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash-icon.svg";
import { v4 as uuidv4 } from "uuid";
import {
  fetchOracleLanguages,
  fetchXtmLanguages,
} from "data/features/oracleProfiles/oracleProfileApi";
import { InputLabel, FormRow } from "components/global/forms";
import { MenuItem, WhiteSelect } from "components/Select";
import { ServiceNotConnected } from "../ServiceNotConnected";

const LanguageRow = ({
  index,
  form,
  removeLanguageMapping,
  isUnremoveable,
  onChangeHandler,
  oracleLanguageOptions,
  isOracleConnected,
  isXtmConnected,
}) => {
  const [xtmLanguages, setXtmLanguages] = useState([]);
  const { t } = useTranslation();
  const { watch } = form;
  const watchLanguage = watch("language");
  const selectedLangCodes =
    watchLanguage &&
    watch("language")
      .map((lang) => Object.values(lang))
      .flat();

  // eslint-disable-next-line

  useEffect(() => {
    fetchXtmLanguages().then((response) => {
      setXtmLanguages(response.data);
    });
  }, []);

  return (
    <FormRow contained autoFlowColumns style={{ alignItems: "flex-start" }}>
      <FormRow vertical>
        <InputLabel lead style={{ fontSize: "1.3rem" }}>
          {t("oracleProfiles.form.xtm.oracleLanguage.label")}
        </InputLabel>
        <Box display="flex" flexDirection="column">
          <WhiteSelect
            medium2
            value={index?.key ?? ""}
            displayEmpty={isOracleConnected}
            readOnly={!isOracleConnected}
            style={{ width: "285px" }}
            onChange={(ev) => {
              onChangeHandler(index, "key", ev.target.value);
            }}
            sx={{
              "& .MuiSelect-select": {
                backgroundColor: isOracleConnected
                  ? "inherit"
                  : "#EAEBEC !important",
              },
              width: "285px",
            }}
          >
            <MenuItem value="" className="hidden">
              Choose...
            </MenuItem>
            {oracleLanguageOptions &&
              oracleLanguageOptions.map((item) => (
                <MenuItem
                  key={item.code}
                  value={item.code}
                  className={`${
                    selectedLangCodes?.includes(item.code) && "hidden"
                  }`}
                >
                  {item.language} - {item.code}
                </MenuItem>
              ))}
          </WhiteSelect>
          {!isOracleConnected && (
            <ServiceNotConnected service="Oracle" maxWidth="285" />
          )}
        </Box>
      </FormRow>
      <span style={{ marginTop: "3.1rem", fontSize: "2rem" }}>=</span>
      <FormRow vertical>
        <InputLabel lead style={{ fontSize: "1.3rem" }}>
          {t("oracleProfiles.form.xtm.xtmLanguage.label")}
        </InputLabel>
        <Box display="flex" flexDirection="column">
          <WhiteSelect
            medium2
            value={index?.value ?? ""}
            displayEmpty={isXtmConnected}
            readOnly={!isXtmConnected}
            onChange={(ev) => {
              onChangeHandler(index, "value", ev.target.value);
            }}
            style={{ width: "285px" }}
            sx={{
              "& .MuiSelect-select": {
                backgroundColor: isXtmConnected
                  ? "inherit"
                  : "#EAEBEC !important",
              },
              width: "285px",
            }}
          >
            <MenuItem value="" className="hidden">
              Choose...
            </MenuItem>
            {xtmLanguages.map((item) => (
              <MenuItem
                key={item.language_code}
                value={item.language_code}
                className={`${
                  selectedLangCodes?.includes(item.language_code) && "hidden"
                }`}
              >
                {item.name}
              </MenuItem>
            ))}
          </WhiteSelect>
          {!isXtmConnected && (
            <ServiceNotConnected service="XTM Cloud" maxWidth="285" />
          )}
        </Box>
      </FormRow>
      {!isUnremoveable && (
        <TrashIcon
          style={{ marginTop: "3.4rem", cursor: "pointer" }}
          onClick={removeLanguageMapping(index.id)}
        />
      )}
    </FormRow>
  );
};

LanguageRow.propTypes = {
  index: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  removeLanguageMapping: PropTypes.func.isRequired,
  isUnremoveable: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  oracleLanguageOptions: PropTypes.array,
  isOracleConnected: PropTypes.bool.isRequired,
  isXtmConnected: PropTypes.bool.isRequired,
};

LanguageRow.defaultProps = {
  oracleLanguageOptions: null,
};

export const LanguageMapping = ({ form, profileId }) => {
  const { getValues, watch, setValue } = form;
  const oracleTempCredential = watch("oracleTempCredential");
  const xtmTempCredential = watch("xtmTempCredential");
  const isOracleConnected = Boolean(profileId ?? oracleTempCredential);
  const isXtmConnected = Boolean(profileId ?? xtmTempCredential);
  const [mappedLanguages, setMappedLanguages] = useState([
    {
      id: uuidv4(),
      key: "",
      value: "",
    },
  ]);
  const [oracleLanguageOptions, setOracleLanguageOptions] = useState(null);

  const addLanguageMapping = () => {
    setMappedLanguages((prevState) => [
      ...prevState,
      { id: uuidv4(), key: "", value: "" },
    ]);
  };

  const onChangeHandler = (language, field, value) => {
    const mappedLanguagesReference = [...mappedLanguages];

    const currentMappedLanguage = mappedLanguagesReference.find(
      (lang) => lang.id === language.id
    );

    currentMappedLanguage[field] = value;

    setMappedLanguages(mappedLanguagesReference);
  };

  const removeLanguageMapping = (id) => () => {
    const languages = [...mappedLanguages].filter((lang) => lang.id !== id);

    setMappedLanguages(languages);
  };

  useEffect(() => {
    const language = getValues("language")?.map((lang) => ({
      ...lang,
      id: uuidv4(),
    }));

    if (language?.length > 0) {
      setMappedLanguages(language);
    }
  }, []);

  useEffect(() => {
    setValue("language", mappedLanguages);
  }, [mappedLanguages]);

  useEffect(() => {
    if (isOracleConnected) {
      fetchOracleLanguages(
        profileId ?? oracleTempCredential,
        Boolean(profileId)
      )
        .then((response) => {
          setOracleLanguageOptions(
            Object.keys(response.data.languageCodes).map((code) => ({
              code,
              language: response.data.languageCodes[code].language,
            }))
          );
        })
        .catch((err) => toast.error(err.message));
    }
  }, [oracleTempCredential]);

  return (
    <FormRow contained groupInner vertical>
      {mappedLanguages.map((mappedLanguage) => (
        <LanguageRow
          key={mappedLanguage.id}
          index={mappedLanguage}
          form={form}
          onChangeHandler={onChangeHandler}
          removeLanguageMapping={removeLanguageMapping}
          isUnremoveable={mappedLanguages.length === 1}
          oracleLanguageOptions={oracleLanguageOptions}
          isOracleConnected={isOracleConnected}
          isXtmConnected={isXtmConnected}
        />
      ))}
      {isOracleConnected && isXtmConnected && (
        <PlusCircleIcon
          style={{ cursor: "pointer" }}
          onClick={addLanguageMapping}
        />
      )}
    </FormRow>
  );
};

LanguageMapping.propTypes = {
  form: PropTypes.object.isRequired,
  profileId: PropTypes.number,
};

LanguageMapping.defaultProps = {
  profileId: null,
};

import PropTypes from "prop-types";

const NOT_ACTIVE = "NOT_ACTIVE";
const ACTIVE = "ACTIVE";

export const StatusCell = ({ value }) => {
  switch (value) {
    case ACTIVE:
      return "Active";
    case NOT_ACTIVE:
      return "Inactive";
    default:
      return null;
  }
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

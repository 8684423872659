import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  CancelButton,
  SubmitButton,
  StyledCheckbox,
  FormError,
} from "components/global/forms";
import { fetchClients } from "data/features/clients/clientsApi";
import { addAdministrator } from "data/features/administrators/administratorsApi";
import { MenuItem, WhiteSelect } from "components/Select";
import { Tooltip } from "components/Tooltip";
import { checkPasswordStrength } from "helpers/checkPasswordStrength";
import { Box } from "@mui/system";
import { FormControlLabel } from "@mui/material";
import { Heading, ButtonsContainer } from "../styles";

const AddAdministrator = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchClients({ limit: 999 })
      .then((res) => setClients(res.data.objects))
      .catch((err) => toast.error(err));
  }, []);

  const onSubmit = (data) => {
    const { clientAssigned, status, email, password } = data;
    setIsDisabled(true);
    addAdministrator({
      email,
      clientAssigned,
      password,
      status: status ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/administrators", { replace: true });
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled =
        value.email.length &&
        value.password.length &&
        value.confirm_password.length &&
        value.clientAssigned !== "none";
      setIsDisabled(!areFieldsFilled);
      setShowTooltip(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("administrators.add.label")}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow bottomSpace>
            <InputLabel lead centered>
              {t("form.email.label")}*
            </InputLabel>
            <TextInput
              adminInput
              aria-label="Email"
              hasError={errors.email}
              {...register("email", {
                required: t("form.email.required"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("form.email.invalid"),
                },
              })}
            />
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel lead centered>
              {t("form.password.label")}*
            </InputLabel>
            <Box>
              <TextInput
                adminInput
                aria-label="Password"
                type="password"
                hasError={errors.password}
                {...register("password", {
                  required: t("form.password.required"),
                  validate: {
                    passwordStrength: (value) =>
                      checkPasswordStrength(value) ||
                      t("form.password.strength"),
                  },
                })}
              />
              {errors?.password && (
                <FormError secondary>{errors?.password?.message}</FormError>
              )}
            </Box>
          </FormRow>
          <FormRow bottomSpace>
            <InputLabel lead centered>
              {t("form.confirmPassword.label")}*
            </InputLabel>
            <Box>
              <TextInput
                adminInput
                aria-label="ConfirmPassword"
                type="password"
                hasError={errors.confirm_password}
                {...register("confirm_password", {
                  required: t("form.confirmPassword.required"),
                  validate: {
                    samePassword: (value) =>
                      value === watch("password") ||
                      t("form.passwordsDoNotMatch"),
                  },
                })}
              />
              {errors?.confirm_password && (
                <FormError secondary>
                  {errors?.confirm_password?.message}
                </FormError>
              )}
            </Box>
          </FormRow>
          <FormRow bottomSpace editAdmin>
            <InputLabel lead centered>
              {t("form.clientAssigned.label")}*
            </InputLabel>
            <WhiteSelect
              aria-label="ClientAssigned"
              sx={{ width: 364 }}
              displayEmpty
              bigger
              value={watch("clientAssigned") ?? "none"}
              label="Choose..."
              {...register("clientAssigned", {
                required: t("form.clientAssigned.required"),
              })}
            >
              <MenuItem value="none" className="hidden">
                Choose...
              </MenuItem>
              {clients?.map(({ name }) => (
                <MenuItem
                  aria-label="ClientAssignedItem"
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              ))}
            </WhiteSelect>
          </FormRow>

          <FormRow topSpace>
            <InputLabel lead>{t("form.active.label")}</InputLabel>
            <div>
              <FormControlLabel
                {...register("status")}
                control={<StyledCheckbox defaultChecked color="success" />}
                sx={{ margin: 0 }}
              />
            </div>
          </FormRow>
          <ButtonsContainer>
            <CancelButton
              standard
              aria-label="CancelAddAdministratorButton"
              to="/administrators"
            >
              {t("form.cancel")}
            </CancelButton>
            <Tooltip
              title={showTooltip ? "Fill in all mandatory fields first" : ""}
              arrow
              placement="bottom"
            >
              <div>
                <SubmitButton
                  standard
                  aria-label="AddAdministratorButton"
                  disabled={isDisabled}
                  type="submit"
                >
                  {t("form.save")}
                </SubmitButton>
              </div>
            </Tooltip>
          </ButtonsContainer>
        </form>
      </InnerSectionContainer>
    </StyledSection>
  );
};

export default AddAdministrator;

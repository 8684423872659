import { styled } from "@mui/system";

export const FormError = styled("p")`
  font-size: 1.3rem;
  line-height: 1.6rem;
  display: block;
  font-weight: 500;
  margin-bottom: 0.4rem;
  margin-top: 1.6rem;
  color: ${({ theme }) => theme.palette.error.main};

  ${({ secondary }) =>
    secondary &&
    `
    font-weight: 400;
    font-size: 1.4rem;
    margin-top: 8px;

  `}
`;

import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  fetchOracleProfilesAsync,
  selectOracleProfilesData,
  selectOracleProfilesError,
  selectOracleProfilesSort,
  updateSort,
} from "data";
import { TableComponent } from "components";
import Container from "components/global/Container";
import { AddButton } from "components/TableComponent/components";
import { Navigate } from "react-router-dom";
import { oracleProfilesColumns } from "../helpers";

const OracleProfilesHeading = ({ handleAdd }) => {
  const { t } = useTranslation();

  return (
    <AddButton
      aria-label="AddOracleProfileButton"
      onClick={() => handleAdd("/oracle-profiles/add")}
    >
      {t("oracleProfiles.heading")}
    </AddButton>
  );
};

OracleProfilesHeading.propTypes = {
  handleAdd: PropTypes.func.isRequired,
};

export function OracleProfiles() {
  const dispatch = useDispatch();
  const data = useSelector(selectOracleProfilesData);
  const error = useSelector(selectOracleProfilesError);
  const selectSort = useSelector(selectOracleProfilesSort);
  const { t } = useTranslation();

  const handleFetchOracleProfiles = useCallback(
    (params) => dispatch(fetchOracleProfilesAsync(params)),
    []
  );

  const handleUpdateSort = (sort) => {
    dispatch(updateSort(sort));
  };

  const columns = useMemo(() => oracleProfilesColumns, []);

  useEffect(() => {
    handleFetchOracleProfiles();
  }, [dispatch]);

  return (
    <>
      {error && <Navigate to="/server-error" state={{ error }} />}
      {data && (
        <Container small>
          <TableComponent
            aria-label="Table"
            fetchData={handleFetchOracleProfiles}
            columns={columns}
            data={data.objects}
            searchPlaceholder={t("oracle.search.placeholder")}
            totalItems={data.total_elements}
            renderHeading={OracleProfilesHeading}
            sortObject={selectSort}
            updateSortObject={handleUpdateSort}
          />
        </Container>
      )}
    </>
  );
}

import { IconButton as MUIIconButton, styled } from "@mui/material";

export const IconButton = styled(MUIIconButton)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ standard }) =>
    standard &&
    `
  padding: 5px;
  height: 3rem; 
  width: 3rem;
  `}

  ${({ standard }) =>
    standard &&
    `
  padding: 5px;
  `}
`;

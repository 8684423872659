import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import StyledSection from "components/global/Section";
import { FormRow, InputLabel } from "components/global/forms";
import { MenuItem, WhiteSelect } from "components/Select";
import { fetchOracleProfiles } from "data/features/translationQueue/translationQueueApi";
import { LocalStorageService } from "services";
import { useDispatch } from "react-redux";
import { setOracleProfile } from "data/features/translationQueue/translationQueueSlice";
import * as Styles from "../Login/styles";

export const SelectOracleProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, watch, register } = useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [oracleProfiles, setOracleProfiles] = useState(null);

  const onSubmit = (data) => {
    LocalStorageService.setOracleProfile(data.oracleProfile);
    dispatch(setOracleProfile(data.oracleProfile));

    navigate("/translation-queue", { replace: true });
  };

  useEffect(() => {
    fetchOracleProfiles({ limit: 999 })
      .then((res) => {
        setOracleProfiles(res.data);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      const isFieldFilled = value.oracleProfile;
      setIsDisabled(!isFieldFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox userLogin>
        <Styles.LoginBoxHeader>
          {t("selectProfile.box.header")}
        </Styles.LoginBoxHeader>
        <Styles.LoginBoxContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {oracleProfiles && (
              <FormRow vertical style={{ gap: 0, width: "30rem" }}>
                <InputLabel>{t("selectProfile.form.select")}</InputLabel>
                <WhiteSelect
                  sx={{ width: 300 }}
                  medium
                  defaultValue=""
                  displayEmpty
                  {...register("oracleProfile", {
                    required: "Client assigned is required",
                  })}
                >
                  <MenuItem value="" className="hidden">
                    Choose...
                  </MenuItem>
                  {oracleProfiles.map(({ id, connectionProfileName }) => (
                    <MenuItem key={id} value={id}>
                      {connectionProfileName}
                    </MenuItem>
                  ))}
                </WhiteSelect>
              </FormRow>
            )}
            <Styles.LoginSubmitButton
              loggedInButton
              disabled={isDisabled}
              standard
            >
              OK
            </Styles.LoginSubmitButton>
          </form>
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
};

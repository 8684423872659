import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { ReactComponent as SwitchIcon } from "assets/switch-icon.svg";
import { styled } from "@mui/system";
import { fetchOracleProfiles } from "data/features/translationQueue/translationQueueApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "services";
import { useDispatch } from "react-redux";
import {
  removeAllRowsFromExport,
  setOracleProfile,
} from "data/features/translationQueue/translationQueueSlice";
import * as Styles from "./styles";

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.palette.white.main};
    border: 1px solid ${({ theme }) => theme.palette.border.main};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    min-width: 282px;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      z-index: 20;
      top: -8px;
      right: 12px;
      border-style: solid;
      border-width: 0 9px 8px 9px;
      border-color: transparent transparent #ffffff transparent;
    }
  }
  .MuiMenu-list {
    padding: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.text.light};
  font-weight: 500;
  padding: 16px;
  text-decoration: none;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  }
`;

export const HeadingSwitchProfiles = () => {
  const [profiles, setProfiles] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchProfile = (ev) => {
    LocalStorageService.setOracleProfile(ev.target.value);
    dispatch(setOracleProfile(ev.target.value));
    setAnchorEl(null);
    navigate("/translation-queue");

    dispatch(removeAllRowsFromExport());
  };

  useEffect(() => {
    fetchOracleProfiles({ limit: 999 })
      .then((res) => {
        setProfiles(res.data);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  return (
    <>
      <Styles.NavigationButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <SwitchIcon />
        Switch profile
      </Styles.NavigationButton>
      <StyledMenu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -20,
          horizontal: "right",
        }}
        id="switch-profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {profiles?.map((profile) => (
          <StyledMenuItem
            onClick={handleSwitchProfile}
            key={profile.connectionProfileName}
            value={profile.id}
          >
            {profile.connectionProfileName}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { IconButton, Tooltip } from "components";
import { ReactComponent as ChevronIcon } from "assets/chevron-up.svg";
import { ReactComponent as CloseIcon } from "assets/close-icon.svg";
import { ReactComponent as CopyClipboardIcon } from "assets/copy-clipboard-icon.svg";
import { ReactComponent as ErrorIcon } from "assets/error-icon.svg";
import { Box, Collapse, Fade } from "@mui/material";
import { format } from "date-fns";

const NotificationContainer = styled("div")`
  border-radius: 0.2rem;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.palette.white.main};
  margin-bottom: 0.8rem;
  padding: 1.5rem 1.5rem 1.5rem 3.4rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 2px 0 0 2px;
    background-color: ${({ theme }) => theme.palette.error.main};
  }
`;

const NotificationHeading = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
`;

const NotificationErrorIcon = styled("div")`
  position: absolute;
  left: -21px;
  top: 50%;
  transform: translateY(-50%);
`;

const NotificationContent = styled("div")`
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ bold }) =>
    bold &&
    `
    font-weight: 600;
  `}
`;

const NotificationMessage = styled("div")`
  margin-bottom: 1.4rem;
`;

const NotificationStackTrace = styled("div")`
  max-height: 135px;
  overflow: auto;
`;

const NotificationDate = styled("div")`
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.light};

  ${({ bold }) =>
    bold &&
    `
    font-weight: 600;
  `}
`;

const NotificationHeadingColumn = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s ease;
  opacity: 1;
  position: relative;
  pointer-events: auto;
`;

const NotificationStackTraceHeading = styled("p")`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Notification = ({ data, handleDelete, handleNew }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(data.message);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <NotificationContainer
      aria-label="NotificationContainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleNew(data.id, data.status)}
    >
      <NotificationHeading>
        <NotificationHeadingColumn>
          <NotificationErrorIcon>
            <ErrorIcon />
          </NotificationErrorIcon>
          <NotificationDate bold={data.status === "ACTIVE"}>
            {format(new Date(data.created_date_time), "dd.MM.yyyy HH:mm")}
          </NotificationDate>
          <Tooltip arrow title={isCollapsed ? "See less" : "See more"}>
            <IconButton
              aria-label="NotificationCollapse"
              sx={{
                transition: "0.3s ease",
                transform: `rotate(${isCollapsed ? "180deg" : "0deg"})`,
              }}
              onClick={handleCollapse}
            >
              <ChevronIcon />
            </IconButton>
          </Tooltip>
        </NotificationHeadingColumn>
        <Fade in={isHovered}>
          <NotificationHeadingColumn>
            <IconButton onClick={handleCopyToClipboard}>
              <CopyClipboardIcon />
            </IconButton>
            <IconButton
              aria-label="DeleteNotificationButton"
              onClick={() => handleDelete(data.id)}
            >
              <CloseIcon />
            </IconButton>
          </NotificationHeadingColumn>
        </Fade>
      </NotificationHeading>
      <NotificationContent bold={data.status === "ACTIVE"}>
        <NotificationMessage>{data.message}</NotificationMessage>
        <Collapse in={isCollapsed}>
          <div>
            <NotificationStackTraceHeading>
              Stack trace:
            </NotificationStackTraceHeading>
            <NotificationStackTrace aria-label="NotificationStackTrace">
              {data.stack_trace}
            </NotificationStackTrace>
          </div>
        </Collapse>
      </NotificationContent>
    </NotificationContainer>
  );
};

Notification.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    created_date_time: PropTypes.string.isRequired,
    stack_trace: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleNew: PropTypes.func.isRequired,
};

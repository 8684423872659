import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "components/TableComponent/components";
import { styled } from "@mui/system";
import {
  DateCell,
  MenuCell,
  TranslationQueueActionsCell,
  XtmStatusCell,
} from "components/TableComponent/cells";
import { Collapse, Table, TableBody } from "@mui/material";
import { StyledTableRow } from "components/TableComponent/components/TableRow";
import { useSelector } from "react-redux";
import { selectCollapsedRows } from "data/features/translationQueue/translationQueueSlice";
import { TranslationQueueLangActionsCell } from "components/TableComponent/cells/TranslationQueueLangActionsCell";

const StyledTableCell = styled(TableCell)`
  border: none;
`;

export const ProjectJobRow = ({ data, parentRow, index }) => {
  const collapsedRows = useSelector(selectCollapsedRows);
  const isRowCollapsed = collapsedRows.includes(`${parentRow}-${index}`);

  return (
    <>
      <StyledTableRow
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "0.8rem",
          backgroundColor: "#E5E5E5!important",
          width: "100%",
          minHeight: "50px",
        }}
      >
        <StyledTableCell sx={{ width: "100px" }}>
          <TranslationQueueLangActionsCell
            row={data}
            value={`${parentRow}-${index}`}
          />
        </StyledTableCell>
        <StyledTableCell sx={{ width: "174px" }}>
          {data.target_language}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "130px" }}>
          {data.source_language}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "147px" }}>
          {data.target_language}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "140px" }}>
          {/* {data.customer} */}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "126px" }} />
        <StyledTableCell sx={{ width: "106px" }} />
        <StyledTableCell sx={{ width: "152px" }} />
        <StyledTableCell sx={{ width: "145px" }}>
          <XtmStatusCell
            row={{
              original: {
                xtm_status: data.xtm_status,
                project_progress: data.language_progress,
              },
            }}
          />
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <Collapse in={isRowCollapsed} timeout="auto" unmountOnExit>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingRight: 0,
              border: "none",
              width: "100%",
              display: "flex",
            }}
          >
            <Table>
              <TableBody>
                {data.project_jobs?.map((job) => (
                  <StyledTableRow
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: "0.8rem",
                      backgroundColor: "#fff!important",
                      width: "100%",
                    }}
                  >
                    <StyledTableCell>
                      <TranslationQueueActionsCell
                        value={job.xtm_job_id}
                        parentRow={parentRow}
                        noCollapse
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ width: "184px", overflowWrap: "break-word" }}
                    >
                      {job?.file_name}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "130px" }}>
                      {/* {job?.source_language} */}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "147px" }}>
                      {job?.target_language}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "140px" }}>
                      {/* {job?.customer} */}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ width: "126px", overflowWrap: "break-word" }}
                    >
                      <DateCell value={job?.created_date} />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "106px" }}>
                      <DateCell value={job?.due_date} />
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "152px" }}>
                      {job?.xtm_workflow_step}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "145px" }}>
                      <XtmStatusCell
                        row={{
                          original: {
                            xtm_status: job.xtm_job_status,
                            project_progress: job.xtm_job_progress,
                          },
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <MenuCell
                        row={{
                          original: {
                            xtm_status: job.xtm_job_status,
                            project_id: parentRow,
                            job_id: job.xtm_job_id,
                          },
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </Collapse>
      </StyledTableRow>
    </>
  );
};

ProjectJobRow.propTypes = {
  data: PropTypes.object.isRequired,
  parentRow: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

import axios from "axios";

export const fetchOracleProfiles = () =>
  axios.get("/api/v1/profile/end-user/my-profiles");

export const fetchTranslationQueues = ({
  id,
  filter = "",
  page = 0,
  limit = 10,
  showImported = false,
  sort,
} = {}) =>
  axios.get(`/api/v1/translation-queue/${id}`, {
    params: {
      filter: String(filter),
      page: String(page),
      limit: String(limit),
      showImported,
      sort,
    },
  });

export const changeDueDate = (preparedData, oracleProfileId) =>
  axios.put(`/api/v1/translation-queue/${oracleProfileId}`, preparedData);

export const cancelProject = (xtmProjectId) =>
  axios.put(`/api/v1/translation-queue/${xtmProjectId}/cancel`);

export const removeProject = (xtmProjectId) =>
  axios.put(`/api/v1/translation-queue/${xtmProjectId}/remove`);

export const fetchFilterOptions = ({ profileID }) =>
  axios.get(`/api/v1/translation-queue/select-fields/${profileID}`);

export const exportProjects = (projects, showImported) =>
  axios.post(`/api/v1/translation-queue/export`, projects, {
    params: {
      showImported,
    },
    responseType: "arraybuffer",
  });

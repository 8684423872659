import React from "react";
import StyledSection from "components/global/Section";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Styles from "../Login/styles";

function Page404() {
  const { t } = useTranslation();
  const redirect = useNavigate();

  const handleClick = () => {
    redirect("/");
  };

  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox>
        <Styles.LoginBoxHeader>
          {t("httpErrorCode.404.title")}
        </Styles.LoginBoxHeader>
        <Styles.LoginForgotPasswordText>
          <Trans i18nKey="httpErrorCode.404.text" />
        </Styles.LoginForgotPasswordText>
        <Styles.LoginBoxContent>
          <Styles.LoginSubmitButton onClick={handleClick}>
            {t("httpErrorCode.404.button")}
          </Styles.LoginSubmitButton>
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
}

export default Page404;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Controller, useWatch } from "react-hook-form";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import { ReactComponent as InfoCircleIcon } from "assets/info-circle-icon.svg";
import { ReactComponent as VisibilityIcon } from "assets/visibility-icon.svg";
import {
  connectToXtm,
  fetchXtmCustomers,
  fetchXtmTemplates,
} from "data/features/oracleProfiles/oracleProfileApi";
import {
  InputLabel,
  FormRow,
  TextInput,
  SubmitButton,
  StyledCheckbox,
} from "components/global/forms";
import { BorderedSection } from "components/BorderedSection";
import { MenuItem, WhiteSelect } from "components/Select";
import { CollapsibleSection } from "components/CollapsibleSection";
import { MultiSelect } from "components/MultiSelect";
import { Tooltip } from "components/Tooltip";
import { ValidationMessage } from "components/global/forms/ValidationMessage";
import { LanguageMapping } from "./LanguageMapping";
import { ServiceNotConnected } from "../ServiceNotConnected";

export const XTMSettings = ({ form, profileId }) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = form;
  const [xtmCustomers, setXtmCustomers] = useState(null);
  const [xtmTemplates, setXtmTemplates] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isXtmConnected, setIsXtmConnected] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [notifyJobFinished, setNotifyJobFinished] = useState(false);
  const [notifyTranslationProgress, setNotifyTranslationProgress] =
    useState(false);
  const watchXtmTempCredential = watch("xtmTempCredential");
  const watchUserAssigned = watch("userAssigned");
  const watchXtmConnectFields = useWatch({
    control,
    name: ["xtmUrl", "xtmUserName", "xtmPassword", "xtmCompanyName"],
  });

  const handleOnSubmit = () => {
    const values = getValues();
    const { xtmUserName, xtmPassword, xtmUrl, xtmCompanyName } = values;

    if (!isSubmitDisabled) {
      setIsSubmitDisabled(true);
      setValue("xtmTempCredential", null);
      connectToXtm({
        url: xtmUrl,
        username: xtmUserName,
        password: xtmPassword,
        companyName: xtmCompanyName,
      })
        .then((response) => {
          setValue("xtmTempCredential", response.data);
          setIsXtmConnected(true);
          toast.success(t("oracleProfiles.form.xtm.submit.connected.toast"));

          return response;
        })
        .catch((error) => {
          setIsSubmitDisabled(false);
          toast.error(error.message);

          return error;
        });
    }
  };

  const handleEmailNotifyChange = (e) => {
    const { name } = e.currentTarget;

    setValue(name, []);

    if (name === "usersMailNotificationJobIsFinishedAndImported") {
      setNotifyJobFinished(!notifyJobFinished);
    } else {
      setNotifyTranslationProgress(!notifyTranslationProgress);
    }
  };

  useEffect(() => {
    setNotifyJobFinished(
      getValues("usersMailNotificationJobIsFinishedAndImported")?.length > 0
    );
    setNotifyTranslationProgress(
      getValues("usersMailNotificationProgress")?.length > 0
    );
  }, []);

  useEffect(() => {
    if (profileId) {
      setIsXtmConnected(true);
    }
  }, [profileId]);

  useEffect(() => {
    const customerId = getValues("xtmCustomer");

    if (!getValues("xtmCustomerName")) {
      setValue(
        "xtmCustomerName",
        xtmCustomers?.find((customer) => customer.id === customerId)?.name
      );
    }

    if (!["", undefined].includes(customerId)) {
      fetchXtmTemplates(
        watchXtmTempCredential ?? profileId,
        customerId,
        !watchXtmTempCredential
      )
        .then((response) => setXtmTemplates(response.data))
        .catch((err) => toast.error(err.message));
    }
  }, [watch("xtmCustomer")]);

  useEffect(() => {
    if (isXtmConnected) {
      fetchXtmCustomers(
        watchXtmTempCredential ?? profileId,
        !watchXtmTempCredential
      )
        .then((response) => setXtmCustomers(response.data))
        .catch((err) => toast.error(err.message));
    }
  }, [isXtmConnected]);

  useEffect(() => {
    const areFieldsFilled = watchXtmConnectFields.every(
      (field) => field?.trim().length
    );
    setIsSubmitDisabled(!areFieldsFilled);
    setShowTooltip(!areFieldsFilled);
    if (watchXtmConnectFields.some((field) => field !== undefined)) {
      setIsXtmConnected(false);
    }
  }, [watchXtmConnectFields]);

  return (
    <BorderedSection name={t("oracleProfiles.form.xtm.setctionName")}>
      <CollapsibleSection
        name={`${t("oracleProfiles.form.xtm.subSectionName.loginDeails")}*`}
        bottomSpace
        isHidden={isXtmConnected}
        accordionContent={
          <>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.xtm.url.label")}*
              </InputLabel>
              <TextInput {...register("xtmUrl")} />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.xtm.companyName.label")}*
              </InputLabel>
              <TextInput {...register("xtmCompanyName")} />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.xtm.user.label")}*
              </InputLabel>
              <TextInput
                autoComplete="new-username"
                {...register("xtmUserName")}
              />
            </FormRow>
            <FormRow contained bottomSpace groupInner profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.xtm.password.label")}*
              </InputLabel>
              <Box>
                <TextInput
                  type={isPasswordVisible ? "text" : "password"}
                  autoComplete="new-password"
                  {...register("xtmPassword")}
                />
                <VisibilityIcon
                  style={{
                    position: "relative",
                    left: "340px",
                    bottom: "24px",
                    cursor: "pointer",
                    opacity: isPasswordVisible ? "0.6" : "1",
                  }}
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                />
              </Box>
            </FormRow>
          </>
        }
        outerContent={
          <FormRow contained groupInner>
            <InputLabel />
            <Tooltip
              title={showTooltip ? t("tooltip.fillInAllMandatoryFields") : ""}
              arrow
              placement="top"
            >
              <div>
                <SubmitButton
                  type="button"
                  connectButton
                  notConnected={isXtmConnected !== isSubmitDisabled}
                  onClick={handleOnSubmit}
                  style={{
                    pointerEvents:
                      isXtmConnected || isSubmitDisabled ? "none" : "all",
                  }}
                >
                  <Box mr="0.8rem" sx={{ display: "flex" }}>
                    <LinkIcon />
                  </Box>
                  {isXtmConnected
                    ? t("oracleProfiles.form.xtm.submit.connected")
                    : t("oracleProfiles.form.xtm.submit.connect")}
                </SubmitButton>
              </div>
            </Tooltip>
          </FormRow>
        }
      />
      <CollapsibleSection
        name="Settings"
        bottomSpace
        accordionContent={
          <>
            <FormRow contained bottomSpace groupInner>
              <InputLabel lead centered>
                {t("oracleProfiles.form.xtm.customer.label")}*
              </InputLabel>
              <Box display="flex" flexDirection="column">
                <WhiteSelect
                  defaultValue={profileId ? watch("xtmCustomer") : ""}
                  displayEmpty={Boolean(isXtmConnected)}
                  readOnly={!isXtmConnected}
                  sx={{
                    "& .MuiSelect-select": {
                      backgroundColor: isXtmConnected
                        ? "inherit"
                        : "#EAEBEC !important",
                    },
                    width: 364,
                  }}
                  {...register("xtmCustomer")}
                >
                  <MenuItem value="" className="hidden">
                    Choose...
                  </MenuItem>
                  {!!xtmCustomers &&
                    xtmCustomers?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </WhiteSelect>
                <input
                  type="hidden"
                  {...register("xtmCustomerName")}
                  defaultValue={getValues("xtmCustomerName")}
                />
                {!isXtmConnected && <ServiceNotConnected service="XTM Cloud" />}
              </Box>
            </FormRow>
            <FormRow contained bottomSpace groupInner>
              <InputLabel lead centered>
                {t("oracleProfiles.form.xtm.template.label")}*
              </InputLabel>
              <WhiteSelect
                sx={{ width: 364 }}
                defaultValue={profileId ? watch("xtmTemplate") : ""}
                displayEmpty
                readOnly={["", undefined].includes(watch("xtmCustomer"))}
                {...register("xtmTemplate")}
              >
                <MenuItem value="" className="hidden">
                  Choose...
                </MenuItem>
                {!!xtmTemplates &&
                  xtmTemplates?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </WhiteSelect>
            </FormRow>
            <FormRow contained groupInner profile>
              <InputLabel lead centered wider>
                {t("oracleProfiles.form.xtm.dueDate.label")}
              </InputLabel>
              <TextInput
                type="number"
                min="0"
                defaultValue={0}
                {...register("defaultDueDateDaysAhead")}
              />
            </FormRow>
          </>
        }
      />
      <CollapsibleSection
        name={`${t("oracleProfiles.form.xtm.subSectionName.languageMapping")}*`}
        bottomSpace
        accordionContent={<LanguageMapping form={form} profileId={profileId} />}
      />
      <CollapsibleSection
        name={t("oracleProfiles.form.xtm.subSectionName.emailNotifications")}
        bottomSpace
        accordionContent={
          <>
            <FormRow contained groupInner autoFlowColumns bottomSpace>
              <StyledCheckbox
                name="usersMailNotificationJobIsFinishedAndImported"
                color="success"
                checked={notifyJobFinished}
                onChange={handleEmailNotifyChange}
              />
              <InputLabel lead sx={{ marginTop: "2px" }}>
                {t("oracleProfiles.form.xtm.emailJobFinished.label")}
              </InputLabel>
            </FormRow>
            {notifyJobFinished && (
              <FormRow groupInner bottomSpace vertical>
                <Controller
                  name="usersMailNotificationJobIsFinishedAndImported"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      sx={{ width: 635 }}
                      searchable
                      placeholder={t(
                        "oracleProfiles.form.xtm.select.placeholder"
                      )}
                      menuItems={watchUserAssigned.map((user) => user)}
                      chipWidth="calc(25% - 4px)"
                    />
                  )}
                />
              </FormRow>
            )}
            <FormRow
              contained
              groupInner
              autoFlowColumns
              bottomSpace={notifyTranslationProgress}
            >
              <StyledCheckbox
                name="usersMailNotificationProgress"
                color="success"
                checked={notifyTranslationProgress}
                onChange={handleEmailNotifyChange}
              />
              <InputLabel lead sx={{ marginTop: "2px" }}>
                {t("oracleProfiles.form.xtm.emailJobProgress.label")}
              </InputLabel>
            </FormRow>
            {notifyTranslationProgress && (
              <FormRow groupInner vertical>
                <Controller
                  name="usersMailNotificationProgress"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      sx={{ width: 635 }}
                      searchable
                      placeholder={t(
                        "oracleProfiles.form.xtm.select.placeholder"
                      )}
                      menuItems={watchUserAssigned.map((user) => user)}
                      chipWidth="calc(25% - 4px)"
                    />
                  )}
                />
              </FormRow>
            )}
          </>
        }
      />
      <Box px="1.7rem">
        <FormRow contained groupInner autoFlowColumns bottomSpace>
          <Controller
            control={control}
            name="enableChangeInExistingProjects"
            render={({ field }) => (
              <StyledCheckbox
                {...field}
                color="success"
                defaultChecked={watch("enableChangeInExistingProjects")}
              />
            )}
          />
          <InputLabel lead sx={{ marginTop: "2px" }}>
            {t("oracleProfiles.form.xtm.enableChangeDueDate.label")}
          </InputLabel>
        </FormRow>
        <FormRow contained groupInner bottomSpace>
          <InputLabel lead centered>
            <Box display="flex" alignItems="center" gap="0.8rem">
              <span>
                {t("oracleProfiles.form.xtm.projectNamePrefix.label")}*
              </span>
              <Tooltip
                title={t("oracleProfiles.form.xtm.projectNamePrefix.tooltip")}
                arrow
                placement="right"
              >
                <InfoCircleIcon />
              </Tooltip>
            </Box>
          </InputLabel>
          <div>
            <TextInput
              profileInput
              hasError={errors.projectNamePrefix}
              defaultValue="oracle"
              {...register("projectNamePrefix", {
                pattern: {
                  value: /^[^<>/:?\\"|{}*]+$/g,
                  message: `${t(
                    "oracleProfiles.form.xtm.projectNamePrefix.invalid"
                  )}: \\, /, :, *, ?, ", <, >, |, {, }`,
                },
              })}
            />
            {errors.projectNamePrefix && (
              <ValidationMessage>
                {errors.projectNamePrefix.message}
              </ValidationMessage>
            )}
          </div>
        </FormRow>
        <FormRow contained groupInner bottomSpace profile>
          <InputLabel lead>
            {t("oracleProfiles.form.xtm.removeDeletedFilesAfter.label")}
          </InputLabel>
          <TextInput
            type="number"
            min="0"
            defaultValue={10}
            {...register("removeDeletedCmsAfterDays")}
          />
        </FormRow>
        <FormRow
          contained
          groupInner
          bottomSpace
          sx={{ gridTemplateColumns: "1fr 364px" }}
        >
          <InputLabel sx={{ marginRight: "10px" }} lead>
            {t("oracleProfiles.form.xtm.removeCancelledFilesAfter.label")}
          </InputLabel>
          <TextInput
            profileInput
            type="number"
            min="0"
            defaultValue={10}
            {...register("removeCancelledJobsAfterDays")}
          />
        </FormRow>
      </Box>
    </BorderedSection>
  );
};

XTMSettings.propTypes = {
  form: PropTypes.object.isRequired,
  profileId: PropTypes.number,
};

XTMSettings.defaultProps = {
  profileId: null,
};

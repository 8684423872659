import axios from "axios";
import { ORACLE_PROFILE_FORM_TYPES } from "constants/OracleProfileFormTypes";
import { oracleProfileDataMapper } from "./helpers/oracleProfileDataMapper";

export const fetchOracleProfiles = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "id", order: "DESC" },
} = {}) =>
  axios.get("/api/v1/profile/list", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
  });

export const fetchOracleProfileById = (id) =>
  axios.get(`/api/v1/profile/${id}`);

export const submitOracleProfile = ({ data, type, id }) => {
  const payload = oracleProfileDataMapper(data, id);

  if (type === ORACLE_PROFILE_FORM_TYPES.ADD) {
    return axios.post("/api/v1/profile", payload);
  }

  if (type === ORACLE_PROFILE_FORM_TYPES.DUPLICATE) {
    return axios.post("/api/v1/profile/duplicate", payload);
  }

  if (type === ORACLE_PROFILE_FORM_TYPES.EDIT) {
    return axios.put("/api/v1/profile", payload);
  }

  return Promise.reject();
};

export const connectToOracle = ({
  clientId,
  clientSecret,
  scope,
  url,
  idcs,
} = {}) =>
  axios.post("/api/v1/temp-credential/register", {
    client_id: clientId,
    client_secret: clientSecret,
    scope,
    url,
    credential_type: "ORACLE",
    idcs,
  });

export const connectToXtm = ({ url, username, password, companyName } = {}) =>
  axios.post("/api/v1/temp-credential/register", {
    url,
    username,
    password,
    company_name: companyName,
    credential_type: "XTM",
  });

export const fetchXtmCustomers = (id, isProfileId) =>
  axios.get(
    `/xtm/${isProfileId ? "profile" : "temp-credentials"}/customers/${id}`
  );

export const fetchXtmTemplates = (id, customerId, isProfileId) =>
  axios.get(
    `/xtm/${isProfileId ? "profile" : "temp-credentials"}/templates/${id}`,
    {
      params: {
        customerId,
      },
    }
  );

export const fetchOracleLanguages = (id, isProfileId) =>
  axios.get(
    `/api/v1/oracle/language/${
      isProfileId ? "profile" : "temp-credential"
    }/${id}`
  );

export const fetchXtmLanguages = () =>
  axios.get(`/api/v1/profile/xtm-languages`);

import React from "react";
import { Button, styled } from "@mui/material";
import { ReactComponent as PlusCircleIcon } from "assets/plus-circle-icon-grey-small.svg";

export const AddButton = styled(({ children, ...props }) => (
  <Button {...props} startIcon={<PlusCircleIcon />}>
    {children}
  </Button>
))`
  color: #545454;
  font-size: 14px;
  text-transform: none;
  height: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

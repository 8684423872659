import React from "react";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { ReactComponent as DuplicateIcon } from "assets/oracle-profiles-duplicate.svg";
import { styled } from "@mui/system";
import { Tooltip } from "components/Tooltip";
import { ReactComponent as EditIcon } from "assets/edit-list-item.svg";

const ActionsWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 0.5rem;
  }
`;

export const OracleProfilesActionCell = ({ row }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = ({ pathname, searchParam }) => {
    const searchParamValue = row?.values?.[searchParam];
    navigate({
      pathname,
      search: searchParamValue
        ? `?${createSearchParams({ [searchParam]: searchParamValue })}`
        : undefined,
    });
  };

  return (
    <ActionsWrapper>
      <Tooltip title={t("oracleProfiles.actions.edit")} arrow placement="top">
        <IconButton
          aria-label="EditProfileButton"
          sx={{ width: "3rem", height: "3rem", padding: 0 }}
          onClick={() => {
            handleClick({
              pathname: "/oracle-profiles/edit",
              searchParam: "id",
            });
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t("oracleProfiles.actions.duplicate")}
        arrow
        placement="top"
      >
        <IconButton
          aria-label="DuplicateProfileButton"
          sx={{ width: "3rem", height: "3rem", padding: 0 }}
          onClick={() => {
            handleClick({
              pathname: "/oracle-profiles/duplicate",
              searchParam: "id",
            });
          }}
        >
          <DuplicateIcon />
        </IconButton>
      </Tooltip>
    </ActionsWrapper>
  );
};

OracleProfilesActionCell.propTypes = {
  row: PropTypes.object.isRequired,
};

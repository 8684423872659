import { CONTAINER_SETTINGS } from "config/theme";
import { styled } from "@mui/material";

const getHeightBySectionType = (type = "") => {
  switch (type) {
    case "login":
      return "calc( 100vh - 60px )";
    case "panel":
      return "calc(100vh - 109px)";
    default:
      return "calc( 100vh - 60px )";
  }
};

export default styled("section")`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.light};
  padding: 0 ${CONTAINER_SETTINGS.spaceX};
  padding-top: ${({ spaceTop }) => (spaceTop ? "12rem" : "0")};
  min-height: ${({ type }) => getHeightBySectionType(type)};
`;

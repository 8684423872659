import { createTheme } from "@mui/material/styles";

export const globalStyles = `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
  }

  .fade-enter {
		opacity: 0;
	}

	.fade-enter-active {
		opacity: 1;
		transition: all 300ms ease-in;
	}

	.fade-exit {
		opacity: 1;
	}

	.fade-exit-active {
		opacity: 0;
		transition: all 300ms ease-in;
	}

  .fade-right-enter {
		opacity: 0;
		transform: translateX(20px);
	}

	.fade-right-enter-active {
		opacity: 1;
		transform: translateY(0px);
		transition: all 300ms ease-in;
	}

	.fade-right-exit {
		opacity: 1;
		transform: translateY(0px);
	}

	.fade-right-exit-active {
		opacity: 0;
		transform: translateX(20px);
		transition: all 300ms ease-in;
	}

`;

export const CONTAINER_SETTINGS = {
  maxWidth: "1404px",
  spaceX: "1.6rem",
};

export const theme = createTheme({
  palette: {
    primary: {
      lightBg: "#ccedfa",
      light: "#80d2f1",
      contrastText: "#fff",
      main: "#00a4e4",
    },
    background: {
      light: "#eff3f6",
      main: "#e0e1e3",
      mediumDark: "#dce5ed",
      dark: "#2e3844",
    },
    text: {
      primary: "#333333",
      muted: "#999999",
      light: "#555555",
      disabled: "#a9a9a9",
      main: "#333333",
      darkGray: "#444444",
      dark: "#2e3844",
    },
    border: {
      light: "#ccc",
      main: "#dddddd",
    },
    white: {
      main: "#ffffff",
    },
    success: {
      main: "#38af49",
    },
    error: {
      main: "#ff6347",
    },
  },
});

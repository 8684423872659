const XTM_USER_TOKEN = "XTM_USER_TOKEN";
const XTM_USER_REFRESH_TOKEN = "XTM_USER_REFRESH_TOKEN";
const XTM_USER_AUTHORITY = "XTM_USER_AUTHORITY";
const XTM_LOGGED_IN_USER_DATA = "XTM_LOGGED_IN_USER_DATA";
const XTM_SELECTED_ORACLE_PROFILE_ID = "XTM_SELECTED_ORACLE_PROFILE_ID";

export class LocalStorageService {
  static getAuthToken = () => localStorage.getItem(XTM_USER_TOKEN);

  static setAuthToken = (token) => {
    localStorage.setItem(XTM_USER_TOKEN, token);

    return true;
  };

  static getRefreshToken = () => localStorage.getItem(XTM_USER_REFRESH_TOKEN);

  static setRefreshToken = (token) => {
    localStorage.setItem(XTM_USER_REFRESH_TOKEN, token);

    return true;
  };

  static setUserAuthority = (authority) => {
    localStorage.setItem(XTM_USER_AUTHORITY, authority);

    return true;
  };

  static getUserAuthority = () => localStorage.getItem(XTM_USER_AUTHORITY);

  static setLoggedInUserData = (data) => {
    localStorage.setItem(XTM_LOGGED_IN_USER_DATA, data);

    return true;
  };

  static getLoggedInUserData = () => {
    const data = localStorage.getItem(XTM_LOGGED_IN_USER_DATA);

    return JSON.parse(data);
  };

  static setOracleProfile = (data) => {
    localStorage.setItem(XTM_SELECTED_ORACLE_PROFILE_ID, data);

    return true;
  };

  static getOracleProfile = () =>
    localStorage.getItem(XTM_SELECTED_ORACLE_PROFILE_ID);
}

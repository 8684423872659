import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";
import {
  selectProgress,
  setProgress,
} from "data/features/translationQueue/translationQueueSlice";

const CustomSlider = styled(Slider)({
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    boxShadow: "0px 2px 4px rgba(0, 164, 228, 0.2)",
    "&:before": {
      display: "none",
    },
    "&:after": {
      content: '""',
      height: 4,
      width: 4,
      backgroundColor: "currentColor",
    },
  },
});

const Thumb = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span />
    </SliderThumb>
  );
};

Thumb.propTypes = {
  children: PropTypes.func.isRequired,
};

export const ProgressSlider = () => {
  const progress = useSelector(selectProgress);
  const [value, setValue] = useState(progress);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeState = () => {
    dispatch(setProgress(value));
  };

  return (
    <Box height="35px" width="334px" position="relative" left="9px">
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box
          aria-label="ProgressBarBottomValue"
          fontSize="1.1rem"
          position="relative"
          left="-2px"
          bottom="-5px"
        >
          0%
        </Box>
        <Box
          aria-label="ProgressBarTopValue"
          fontSize="1.1rem"
          position="relative"
          left="12px"
          bottom="-5px"
        >
          100%
        </Box>
      </Box>
      <CustomSlider
        size="small"
        defaultValue={100}
        valueLabelDisplay="auto"
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeState}
        components={{ Thumb }}
      />
    </Box>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Name, Wrapper } from "./styles";

export const BorderedSection = ({ name = "", inner, children }) => (
  <Wrapper inner={inner}>
    <Name inner={inner}>{name}</Name>
    {children}
  </Wrapper>
);

BorderedSection.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  inner: PropTypes.bool,
};

BorderedSection.defaultProps = {
  name: "",
  children: null,
  inner: false,
};

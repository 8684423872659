import axios from "axios";

export const fetchAdministrators = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "email", order: "ASC" },
} = {}) =>
  axios.get("/admin/users", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
  });

export const addAdministrator = ({
  email = "",
  clientAssigned,
  password = "",
  status = "ACTIVE",
} = {}) =>
  axios.post("/admin/users", {
    email,
    password,
    authority: "CLIENT_ADMINISTRATOR",
    client: clientAssigned,
    status,
  });

export const editAdministrator = ({
  email = "",
  clientAssigned,
  id,
  password = "",
  status = "ACTIVE",
} = {}) =>
  axios.put("/admin/users", {
    id,
    email,
    password,
    client: clientAssigned,
    status,
  });

export const fetchAdministratorById = (id) => axios.get(`/admin/users/${id}`);

import React from "react";
import { styled } from "@mui/material/styles";
import MUITooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const Tooltip = styled(({ className, children, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }}>
    {children}
  </MUITooltip>
))`
  & .${tooltipClasses.tooltip} {
    background-color: ${({ theme }) => theme.palette.common.black};
    color: ${({ theme }) => theme.palette.common.white};
    padding: 8px;
    font-size: 11px;
    max-width: 100%;
    font-weight: 400;
  }

  & .${tooltipClasses.arrow} {
    color: ${({ theme }) => theme.palette.common.black};
  }

  ${({ hidden }) => hidden && `display:none;`}
`;

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  CancelButton,
  SubmitButton,
  StyledCheckbox,
} from "components/global/forms";
import { Tooltip } from "components/Tooltip";
import { addClient } from "data/features/clients/clientsApi";
import { useTranslation } from "react-i18next";
import { Heading, ButtonsContainer } from "../styles";

export const AddClient = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsDisabled(true);
    addClient({
      name: data.clientName.trim(),
      status: isActive ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/clients", { replace: true });
        return response;
      })
      .catch((error) => {
        setError("clientName", {
          type: "custom",
        });
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const isFieldFilled = value.clientName.trim().length;
      setIsDisabled(!isFieldFilled);
      setShowTooltip(!isFieldFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const handleCheckboxChange = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <StyledSection type="login">
      <InnerSectionContainer>
        <Heading>{t("addClient.heading")}</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow bottomSpace>
            <InputLabel centered lead>
              {t("addClient.form.name.label")}*
            </InputLabel>
            <TextInput
              aria-label="ClientName"
              hasError={errors.clientName}
              {...register("clientName", {
                required: t("addClient.form.name.required"),
              })}
            />
          </FormRow>
          <FormRow>
            <InputLabel lead>{t("form.active.label")}</InputLabel>
            <div>
              <StyledCheckbox
                checked={isActive}
                onChange={handleCheckboxChange}
                color="success"
              />
            </div>
          </FormRow>
          <ButtonsContainer>
            <CancelButton
              standard
              aria-label="CancelAddClientButton"
              to="/clients"
            >
              {t("form.cancel")}
            </CancelButton>
            <Tooltip
              title={showTooltip ? "Enter a client name first" : ""}
              arrow
              placement="bottom"
            >
              <SubmitButton
                standard
                aria-label="AddClientButton"
                disabled={isDisabled}
                type="submit"
              >
                {t("form.save")}
              </SubmitButton>
            </Tooltip>
          </ButtonsContainer>
        </form>
      </InnerSectionContainer>
    </StyledSection>
  );
};

import React from "react";
import * as Styles from "pages/Panel/styles";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CLIENT_ADMINISTRATOR,
  END_USER,
  MAIN_ADMINISTRATOR,
} from "data/features/auth/constants";
import { LocalStorageService } from "services";
import Container from "components/global/Container";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Notifications } from "../TranslationQueue/components/Notifications";

function TabsBar({ value }) {
  const { t } = useTranslation();
  const userAuthority = LocalStorageService.getUserAuthority();

  return (
    <Styles.TabBar>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Styles.StyledTabs sx={{ marginLeft: "1.6rem" }} value={value}>
                {userAuthority === MAIN_ADMINISTRATOR && (
                  <Styles.StyledTab
                    clients
                    value="/clients"
                    to="/clients"
                    label={t("tabs.clients")}
                    component={Link}
                  />
                )}
                {userAuthority === MAIN_ADMINISTRATOR && (
                  <Styles.StyledTab
                    admins
                    value="/administrators"
                    to="/administrators"
                    label={t("tabs.administrators")}
                    component={Link}
                  />
                )}
                {userAuthority !== END_USER && (
                  <Styles.StyledTab
                    users
                    value="/users"
                    to="/users"
                    label={t("tabs.users")}
                    component={Link}
                  />
                )}
                {userAuthority !== END_USER && (
                  <Styles.StyledTab
                    profiles
                    value="/oracle-profiles"
                    label={t("tabs.oracleProfiles")}
                    to="/oracle-profiles"
                    component={Link}
                  />
                )}
                {userAuthority !== END_USER && (
                  <Styles.StyledTab
                    eventLog
                    value="/event-log"
                    to="/event-log"
                    label={t("tabs.eventLogs")}
                    component={Link}
                  />
                )}

                {(userAuthority === END_USER ||
                  userAuthority === CLIENT_ADMINISTRATOR) && (
                  <Styles.StyledTab
                    translationQueue
                    value="/translation-queue"
                    to="/translation-queue"
                    label={t("tabs.translationQueues")}
                    component={Link}
                  />
                )}
              </Styles.StyledTabs>
            </Box>
          </Box>
          {(userAuthority === END_USER ||
            userAuthority === CLIENT_ADMINISTRATOR) && <Notifications />}
        </Box>
      </Container>
    </Styles.TabBar>
  );
}

TabsBar.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TabsBar;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { SubmitButton, StyledCheckbox } from "components/global/forms";
import { MenuItem, SelectOptionText, WhiteSelect } from "components/Select";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { Box } from "@mui/system";
import { CalendarInput } from "components/CalendarInput/CalendarInput";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterDate,
  selectFilterProfiles,
  selectFilterTypes,
  setFilterDate,
  setFilterProfiles,
  setFilterTypes,
} from "data";
import { Tooltip } from "components/Tooltip";
import { useTranslation } from "react-i18next";
import { fetchOracleProfiles } from "data/features/eventLogs/eventLogApi";
import {
  ClearFiltersButton,
  FilterBoxButtons,
  FilterBoxColumn,
  FilterBoxContainer,
  FilterBoxFilters,
  FilterLabel,
} from "./styles";
import { buildQuery } from "./helpers";
import { clearEventLogFilters } from "../../data/features/eventLogs/eventLogsSlice";

export const FilterBox = ({ toggleFilterBox, setGlobalFilter }) => {
  const [profiles, setProfiles] = useState([]);
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const selectTypes = useSelector(selectFilterTypes);
  const selectProfiles = useSelector(selectFilterProfiles);
  const selectDate = useSelector(selectFilterDate);

  const handleApplyFilters = () => {
    if (isButtonDisabled) return;

    setGlobalFilter(
      buildQuery({
        date: selectDate,
        types: selectTypes,
        profiles: selectProfiles,
      })
    );

    toggleFilterBox();
  };

  const handleClearFilters = () => {
    dispatch(clearEventLogFilters());
    setGlobalFilter(
      buildQuery({
        date: selectDate,
        types: selectTypes,
        profiles: selectProfiles,
      })
    );
  };

  const handleTypesChange = (event) => {
    dispatch(setFilterTypes(event.target.value));
  };

  const handleSelectProfiles = (event) => {
    dispatch(setFilterProfiles(event.target.value));
  };

  const handleRemoveSelectedProfile = (profile) => {
    dispatch(setFilterProfiles(selectProfiles.filter((p) => p !== profile)));
  };

  const handleRemoveSelectedType = (value) => {
    dispatch(setFilterTypes(selectTypes.filter((type) => type !== value)));
  };

  const handleSetDateValue = (data) => {
    const value = { ...selectDate, ...data };
    dispatch(setFilterDate(value));
  };

  const types = ["Success", "Error", "Warning", "Information"];

  useEffect(() => {
    if (
      selectTypes.length > 0 ||
      selectProfiles.length > 0 ||
      selectDate.selection.endDate
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectTypes, selectProfiles, selectDate]);

  useEffect(() => {
    fetchOracleProfiles({ limit: 9999 }).then((response) => {
      setProfiles(response.data);
    });
  }, []);

  return (
    <FilterBoxContainer aria-label="FilterBox">
      <FilterBoxFilters>
        <FilterBoxColumn>
          <FilterLabel>{t("filterBox.type")}</FilterLabel>
          <WhiteSelect
            smaller
            value={selectTypes}
            onChange={handleTypesChange}
            multiple
            aria-label="FilterType"
            sx={{
              "& .MuiSelect-select": {
                padding: "4.7px 8px",
              },
              width: 300,
            }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  zIndex: 1400,
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{
                      fontSize: "1.3rem",
                      height: "auto",
                      padding: "3px 0",
                      textTransform: "capitalize",
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => handleRemoveSelectedType(value)}
                    deleteIcon={<DeleteIcon />}
                  />
                ))}
              </Box>
            )}
          >
            {types.map((type) => (
              <MenuItem value={type} classes={{ selected: "selected" }}>
                <StyledCheckbox
                  checked={selectTypes.includes(type)}
                  color="success"
                />
                <SelectOptionText aria-label={type}>{type}</SelectOptionText>
              </MenuItem>
            ))}
          </WhiteSelect>
        </FilterBoxColumn>

        <FilterBoxColumn>
          <FilterLabel>{t("filterBox.connectionProfile")}</FilterLabel>
          <WhiteSelect
            smaller
            value={selectProfiles}
            onChange={handleSelectProfiles}
            multiple
            sx={{ width: 300 }}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  zIndex: 1400,
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip
                    sx={{
                      fontSize: "1.3rem",
                      height: "auto",
                      padding: "3px 0",
                      textTransform: "capitalize",
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => handleRemoveSelectedProfile(value)}
                    deleteIcon={<DeleteIcon />}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
          >
            {profiles?.map((profile) => (
              <MenuItem
                key={profile}
                value={profile}
                classes={{ selected: "selected" }}
              >
                <StyledCheckbox
                  checked={selectProfiles.includes(profile)}
                  color="success"
                />
                <SelectOptionText>{profile}</SelectOptionText>
              </MenuItem>
            ))}
          </WhiteSelect>
        </FilterBoxColumn>

        <FilterLabel>{t("filterBox.dateOccured")}</FilterLabel>
        <CalendarInput
          handleSetDateValue={handleSetDateValue}
          filterDate={selectDate}
          calendarMaxDate={new Date()}
        />
      </FilterBoxFilters>
      <FilterBoxButtons>
        <ClearFiltersButton resetEventFilterButton onClick={handleClearFilters}>
          {t("filterBox.clearFilters")}
        </ClearFiltersButton>
        <Tooltip
          title={t("filterBox.criterionTooltip")}
          arrow
          placement="bottom"
          hidden={!isButtonDisabled}
        >
          <SubmitButton
            applyButton
            aria-label="ApplyFilterButton"
            onClick={handleApplyFilters}
            customDisabled={isButtonDisabled}
          >
            {t("filterBox.applyFilters")}
          </SubmitButton>
        </Tooltip>
      </FilterBoxButtons>
    </FilterBoxContainer>
  );
};

FilterBox.propTypes = {
  toggleFilterBox: PropTypes.func.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import StyledSection from "components/global/Section";
import { InnerSectionContainer } from "components/global/Container";
import {
  InputLabel,
  FormRow,
  TextInput,
  CancelButton,
  SubmitButton,
  StyledCheckbox,
  FormError,
} from "components/global/forms";
import { WhiteSelect, MenuItem } from "components/Select";
import { Tooltip } from "components/Tooltip";
import { fetchClients } from "data/features/clients/clientsApi";
import { addUser, fetchUserById } from "data/features/users/usersApi";
import { LocalStorageService } from "services";
import { ReactComponent as CalendarIcon } from "assets/calendar-clock.svg";
import { FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { Heading, ButtonsContainer, EditClientText } from "../styles";
import { checkPasswordStrength } from "../../../helpers/checkPasswordStrength";

export const DuplicateUser = () => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [clients, setClients] = useState(null);
  const [user, setUser] = useState(null);
  const params = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const paramID = Number(new URLSearchParams(params.search).get("id"));
  const isClientAdmin =
    LocalStorageService.getUserAuthority() === "CLIENT_ADMINISTRATOR";

  useEffect(() => {
    if (paramID) {
      fetchUserById(paramID)
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      fetchClients({ limit: 999 })
        .then((res) => {
          setClients(res.data.objects);
        })
        .catch((err) => toast.error(err));
    } else {
      navigate("/users", { replace: true });
    }
  }, [paramID]);

  const onSubmit = (data) => {
    const {
      email,
      password,
      firstName,
      lastName,
      clientAssigned,
      language,
      status,
    } = data;

    setIsDisabled(true);
    addUser({
      email,
      password,
      firstName,
      lastName,
      clientAssigned,
      language,
      status: status ? "ACTIVE" : "NOT_ACTIVE",
    })
      .then((response) => {
        navigate("/users", { replace: true });
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        return error;
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const areFieldsFilled =
        value.email.length &&
        value.password.length &&
        value.confirmPassword.length &&
        value.firstName.length &&
        value.lastName.length &&
        value.clientAssigned &&
        value.clientAssigned !== "none" &&
        value.language;
      setIsDisabled(!areFieldsFilled);
      setShowTooltip(!areFieldsFilled);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledSection type="login">
      {user && (
        <InnerSectionContainer>
          <Heading>{t("duplicateUser.heading")}</Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow bottomSpace>
              <InputLabel lead centered>
                {t("form.email.label")}*
              </InputLabel>
              <TextInput
                type="email"
                autoComplete="new-email"
                hasError={errors.email}
                {...register("email", {
                  required: t("form.email.required"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("form.email.invalid"),
                  },
                })}
              />
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel centered lead>
                {t("form.password.label")}*
              </InputLabel>
              <Box>
                <TextInput
                  adminInput
                  aria-label="Password"
                  type="password"
                  hasError={errors.password}
                  {...register("password", {
                    required: t("form.password.required"),
                    validate: {
                      passwordStrength: (value) =>
                        checkPasswordStrength(value) ||
                        t("form.password.strength"),
                    },
                  })}
                />
                {errors?.password && (
                  <FormError secondary>{errors?.password?.message}</FormError>
                )}
              </Box>
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead centered>
                {t("form.confirmPassword.label")}*
              </InputLabel>
              <Box>
                <TextInput
                  adminInput
                  aria-label="ConfirmPassword"
                  type="password"
                  hasError={errors.confirmPassword}
                  {...register("confirmPassword", {
                    required: t("form.confirmPassword.required"),
                    validate: {
                      samePassword: (value) =>
                        value === watch("password") ||
                        t("form.passwordsDoNotMatch"),
                    },
                  })}
                />
                {errors?.confirmPassword && (
                  <FormError secondary>
                    {errors?.confirmPassword?.message}
                  </FormError>
                )}
              </Box>
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead centered>
                {t("users.form.firstName.label")}*
              </InputLabel>
              <TextInput
                hasError={errors.firstName}
                {...register("firstName", {
                  required: t("users.form.firstName.required"),
                })}
              />
            </FormRow>
            <FormRow bottomSpace>
              <InputLabel lead centered>
                {t("users.form.lastName.label")}*
              </InputLabel>
              <TextInput
                hasError={errors.lastName}
                {...register("lastName", {
                  required: t("users.form.lastName.required"),
                })}
              />
            </FormRow>
            {clients && (
              <FormRow bottomSpace>
                <InputLabel lead centered>
                  {t("form.clientAssigned.label")}*
                </InputLabel>
                <WhiteSelect
                  sx={{ width: 365 }}
                  defaultValue={user?.client}
                  {...register("clientAssigned", {
                    required: t("form.clientAssigned.required"),
                  })}
                  readOnly={isClientAdmin}
                >
                  <MenuItem value="none" className="hidden">
                    Choose...
                  </MenuItem>
                  {clients?.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </WhiteSelect>
              </FormRow>
            )}
            <FormRow bottomSpace>
              <InputLabel lead centered>
                {t("users.form.language.label")}*
              </InputLabel>
              <WhiteSelect
                sx={{ width: 365 }}
                defaultValue="ENGLISH"
                {...register("language", {
                  required: t("users.form.language.required"),
                })}
                placeholder="Choose..."
              >
                <MenuItem value="ENGLISH">English</MenuItem>
              </WhiteSelect>
            </FormRow>
            <FormRow bottomSpace profile editClientPad>
              <InputLabel lead>{t("status.active")}</InputLabel>
              <div>
                <FormControlLabel
                  {...register("status")}
                  control={
                    <StyledCheckbox
                      defaultChecked={user.status === "ACTIVE"}
                      color="success"
                    />
                  }
                  sx={{ margin: 0 }}
                />
              </div>
            </FormRow>
            {user && (
              <>
                <FormRow bottomSpace calendar profile editClientPad>
                  <InputLabel lead>{t("form.dateAdded")}</InputLabel>
                  <CalendarIcon />
                  <EditClientText centered>
                    {format(new Date(user.created_date), "dd.MM.yyyy kk:mm")}
                  </EditClientText>
                </FormRow>
                {user?.modification_date && (
                  <FormRow calendar profile editClientPad>
                    <InputLabel lead>{t("form.dateModified")}</InputLabel>
                    <CalendarIcon />
                    <EditClientText centered>
                      {format(
                        new Date(user.modification_date),
                        "dd.MM.yyyy kk:mm"
                      )}
                    </EditClientText>
                  </FormRow>
                )}
              </>
            )}
            <ButtonsContainer editAdmin>
              <CancelButton to="/users">{t("form.cancel")}</CancelButton>
              <Tooltip
                title={showTooltip ? t("tooltip.fillInAllMandatoryFields") : ""}
                arrow
                placement="bottom"
              >
                <div>
                  <SubmitButton disabled={isDisabled} type="submit">
                    {t("form.save")}
                  </SubmitButton>
                </div>
              </Tooltip>
            </ButtonsContainer>
          </form>
        </InnerSectionContainer>
      )}
    </StyledSection>
  );
};

import axios from "axios";

export const fetchClients = ({
  filter = "",
  page = 0,
  limit = 10,
  sort = { field: "name", order: "ASC" },
} = {}) =>
  axios.get("/clients", {
    params: {
      filter,
      page: String(page),
      limit: String(limit),
      sort: JSON.stringify(sort),
    },
  });

export const addClient = ({ name = "", status = "ACTIVE" } = {}) =>
  axios.post("/clients", {
    name,
    status,
  });

export const editClient = ({
  id,
  name = "",
  key = "",
  status = "ACTIVE",
} = {}) =>
  axios.put("/clients", {
    id,
    name,
    key,
    status,
  });

export const fetchClientById = (id) => axios.get(`/clients/${id}`);

import { setAuthorizationToken } from "config/axios";
import { LocalStorageService } from "services";

export const handleLogin = (response) => {
  LocalStorageService.setAuthToken(response.data.token);
  LocalStorageService.setRefreshToken(response.data.refresh_token);
  LocalStorageService.setUserAuthority(response.data.user.authority);
  LocalStorageService.setLoggedInUserData(
    JSON.stringify({
      client: response.data.user.client,
      language: response.data.user.language,
      id: response.data.user.id,
      user: response.data.user,
    })
  );

  setAuthorizationToken();
};

import PropTypes from "prop-types";
import { format } from "date-fns";

export const DateCell = ({ value }) => {
  if (!value) return null;
  return format(new Date(value), "dd/MM/yyyy HH:mm");
};

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ORACLE_PROFILE_FORM_TYPES } from "constants/OracleProfileFormTypes";
import { fetchClients } from "data/features/clients/clientsApi";
import { fetchUsers } from "data/features/users/usersApi";
import {
  submitOracleProfile,
  fetchOracleProfileById,
} from "data/features/oracleProfiles/oracleProfileApi";
import { oracleProfileResponseDataMapper } from "data/features/oracleProfiles/helpers/oracleProfileDataMapper";
import { LocalStorageService } from "services";
import { InnerSectionContainer } from "components/global/Container";
import StyledSection from "components/global/Section";
import {
  InputLabel,
  FormRow,
  TextInput,
  StyledCheckbox,
  CancelButton,
  SubmitButton,
} from "components/global/forms";
import { MenuItem, WhiteSelect } from "components/Select";
import { MultiSelect } from "components/MultiSelect";
import { Tooltip } from "components/Tooltip";
import { OracleSettings } from "./components/OracleSettings";
import { XTMSettings } from "./components/XTMSettings";
import { FixedActionsBar, Heading } from "../styles";

export const OracleProfileForm = ({ type }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isProfileFetched, setIsProfileFetched] = useState(
    type === ORACLE_PROFILE_FORM_TYPES.ADD
  );
  const [clients, setClients] = useState(null);
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();
  const params = useLocation();
  const form = useForm();
  const { register, handleSubmit, watch, control, reset, getValues, setValue } =
    form;
  const paramID =
    type === ORACLE_PROFILE_FORM_TYPES.ADD
      ? null
      : Number(new URLSearchParams(params.search).get("id"));
  const isClientAdministrator =
    LocalStorageService.getUserAuthority() === "CLIENT_ADMINISTRATOR";

  const onSubmit = (data) => {
    setIsDisabled(true);
    submitOracleProfile({ data, type, id: paramID })
      .then(() => {
        navigate("/oracle-profiles", { replace: true });
      })
      .catch((err) => {
        setIsDisabled(false);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    fetchClients({ limit: 999 })
      .then((res) =>
        setClients(res.data.objects.filter((obj) => obj.status === "ACTIVE"))
      )
      .catch((err) => toast.error(err));
    if (paramID) {
      fetchOracleProfileById(paramID)
        .then((response) => {
          const data = oracleProfileResponseDataMapper(response.data, type);
          reset(data);
          setIsProfileFetched(true);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    if (isClientAdministrator) {
      const loggedInUserData = LocalStorageService.getLoggedInUserData();
      setValue("clientAssigned", loggedInUserData.client);
    }
  }, []);

  useEffect(() => {
    fetchUsers({ limit: 999 })
      .then((res) =>
        setUsers(
          res.data.objects.filter(
            (obj) => obj.client === getValues("clientAssigned")
          )
        )
      )
      .catch((err) => toast.error(err));
  }, [watch("clientAssigned")]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const areFieldsFilled =
        value.name?.trim().length &&
        value.clientAssigned.length &&
        value.userAssigned.length > 0 &&
        (type === ORACLE_PROFILE_FORM_TYPES.ADD
          ? Boolean(value.oracleTempCredential) &&
            Boolean(value.xtmTempCredential)
          : true) &&
        (value.enableProcessingMediaFiles
          ? value.extensionsSourceFiles?.length &&
            value.extensionsReferenceFiles?.length
          : true) &&
        value.projectNamePrefix &&
        value.xtmCustomer &&
        value.xtmTemplate &&
        value.language.length > 0 &&
        value.language[0].key &&
        value.language[0].value;
      setIsDisabled(!areFieldsFilled);
      setShowTooltip(!areFieldsFilled);
      if (name === "clientAssigned") {
        setValue("userAssigned", []);
        setValue("usersMailNotificationJobIsFinishedAndImported", []);
        setValue("usersMailNotificationProgress", []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <StyledSection sx={{ paddingBottom: "70px" }} type="login">
      {isProfileFetched && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InnerSectionContainer big withPadding>
            <Heading>
              {type === ORACLE_PROFILE_FORM_TYPES.ADD &&
                t("oracleProfiles.header.add")}
              {type === ORACLE_PROFILE_FORM_TYPES.EDIT &&
                t("oracleProfiles.header.edit")}
              {type === ORACLE_PROFILE_FORM_TYPES.DUPLICATE &&
                t("oracleProfiles.header.duplicate")}
            </Heading>
            <FormRow bottomSpace contained profile>
              <InputLabel lead centered>
                {t("oracleProfiles.form.profileName.label")}*
              </InputLabel>
              <TextInput {...register("name")} />
            </FormRow>
            {clients && (
              <FormRow bottomSpace contained profile>
                <InputLabel lead centered>
                  {t("form.clientAssigned.label")}*
                </InputLabel>
                <WhiteSelect
                  sx={{ width: 364 }}
                  value={getValues("clientAssigned") ?? ""}
                  displayEmpty
                  bigger
                  readOnly={isClientAdministrator}
                  {...register("clientAssigned")}
                >
                  <MenuItem value="" className="hidden">
                    Choose...
                  </MenuItem>
                  {clients?.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </WhiteSelect>
              </FormRow>
            )}
            {users && (
              <FormRow bottomSpace contained>
                <InputLabel lead centered>
                  {t("oracleProfiles.form.userAssigned.label")}*
                </InputLabel>
                <Controller
                  name="userAssigned"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <MultiSelect
                      sx={{ width: 364 }}
                      {...field}
                      searchable
                      bigger
                      placeholder="Choose..."
                      onDelete={(value, item) => {
                        field.onChange(
                          field.value.filter((val) => val !== item)
                        );
                      }}
                      readOnly={watch("clientAssigned") === "" || !users.length}
                      menuItems={users.map((user) => user.email)}
                    />
                  )}
                />
              </FormRow>
            )}
            <FormRow bottomSpace contained>
              <InputLabel lead>{t("status.active")}</InputLabel>
              <div>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <StyledCheckbox
                      {...field}
                      color="success"
                      defaultChecked={field.value === "ACTIVE"}
                    />
                  )}
                />
              </div>
            </FormRow>
          </InnerSectionContainer>
          <InnerSectionContainer noPadding big sx={{ marginTop: "5rem" }}>
            <OracleSettings form={form} profileId={paramID} type={type} />
          </InnerSectionContainer>
          <InnerSectionContainer noPadding big sx={{ marginTop: "4rem" }}>
            <XTMSettings form={form} profileId={paramID} />
          </InnerSectionContainer>
          <FixedActionsBar>
            <CancelButton to="/oracle-profiles">
              {t("form.cancel")}
            </CancelButton>
            <Tooltip
              title={showTooltip ? t("tooltip.fillInAllMandatoryFields") : ""}
              arrow
              placement="top"
            >
              <div>
                <SubmitButton disabled={isDisabled} type="submit">
                  {t("form.save")}
                </SubmitButton>
              </div>
            </Tooltip>
          </FixedActionsBar>
        </form>
      )}
    </StyledSection>
  );
};

OracleProfileForm.propTypes = {
  type: PropTypes.oneOf(Object.values(ORACLE_PROFILE_FORM_TYPES)).isRequired,
};

import { LocalStorageService } from "services";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "data/features/auth/authSlice";
import { clearClients } from "data/features/clients/clientsSlice";
import { clearAdministrators } from "data/features/administrators/administratorsSlice";
import { clearUsers } from "data/features/users/usersSlice";
import { clearOracleProfiles } from "data/features/oracleProfiles/oracleProfileSlice";
import {
  clearOracleProfile,
  resetPageSizeLimit,
} from "data/features/translationQueue/translationQueueSlice";
import { clearEventLogFilters } from "../../data/features/eventLogs/eventLogsSlice";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    LocalStorageService.setAuthToken("");
    LocalStorageService.setUserAuthority(null);
    LocalStorageService.setLoggedInUserData("");
    LocalStorageService.setOracleProfile(null);
    dispatch(clearOracleProfile());
    dispatch(logout());
    dispatch(clearClients());
    dispatch(clearAdministrators());
    dispatch(clearUsers());
    dispatch(clearOracleProfiles());
    dispatch(clearEventLogFilters());
    dispatch(resetPageSizeLimit());
  }, []);

  if (LocalStorageService.getUserAuthority() === "END_USER") {
    return <Navigate to="/login-user" replace />;
  }
  return <Navigate to="/login" replace />;
};

export default Logout;

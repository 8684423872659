import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";

export const MessageCell = ({ value }) => (
  <Box sx={{ overflowWrap: "anywhere" }}>{value}</Box>
);

MessageCell.propTypes = {
  value: PropTypes.string.isRequired,
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAdministrators } from "./administratorsApi";

const initialState = {
  isLoading: false,
  error: null,
  data: undefined,
  sort: {
    field: "email",
    order: "ASC",
  },
};

export const fetchAdministratorsAsync = createAsyncThunk(
  "administrators/fetchAdministrators",
  async (params) => {
    const response = await fetchAdministrators(params);
    return response.data;
  }
);

export const administratorsSlice = createSlice({
  name: "administrators",
  initialState,
  reducers: {
    clearAdministrators: (state) => {
      state.data = undefined;
      state.error = null;
      state.isLoading = false;
    },
    updateSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchAdministratorsAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchAdministratorsAsync.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchAdministratorsAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const selectAdministratorsData = ({ administrators }) =>
  administrators.data;
export const selectAdministratorsIsLoading = ({ administrators }) =>
  administrators.isLoading;
export const selectAdministratorsError = ({ administrators }) =>
  administrators.error;
export const selectAdministratorsSort = ({ administrators }) =>
  administrators.sort;

export const { clearAdministrators, updateSort } = administratorsSlice.actions;

export default administratorsSlice.reducer;

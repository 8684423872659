import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { camelCase } from "lodash";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { TableContainer } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  TableRow,
  TableCell,
  Pagination,
  Search,
  TableColumnCell,
} from "./components";
import { PAGES_COUNT_OPTIONS } from "./config";

const NoResultsTableFound = styled("td")`
  padding: 17.5px 16px;
  width: 100%;
  background-color: #fff;
  font-size: 13px;
`;

export function TableComponent({
  columns,
  data,
  fetchData,
  totalItems,
  renderHeading = () => {},
  searchQuery = () => {},
  filterQuery,
  sortObject,
  updateSortObject,
  searchPlaceholder,
}) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    state: { pageIndex, pageSize, globalFilter, sortBy },
    gotoPage,
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: PAGES_COUNT_OPTIONS[0],
        sortBy: [{ id: sortObject.field, desc: sortObject.order === "DESC" }],
      },
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      disableMultiSort: true,
      pageCount: totalItems,
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [initialRender, setInitialRender] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    }

    fetchData({
      filter: globalFilter || filterQuery,
      page: pageIndex,
      limit: pageSize,
      sort: sortObject,
    });
  }, [fetchData, pageIndex, pageSize, globalFilter, sortObject]);

  useEffect(() => {
    const sort = sortBy?.[0] ?? sortObject;
    updateSortObject({
      field: camelCase(sort.id) || sortObject.field,
      order: sort.desc ? "DESC" : "ASC",
    });
  }, [sortBy]);

  const onChangeFilter = useAsyncDebounce((e) => {
    if (searchQuery()) {
      setGlobalFilter(searchQuery(e.target.value));
    } else {
      setGlobalFilter(e?.target?.value || "");
    }
  }, 200);

  const onClearFilter = () => {
    setGlobalFilter("");
  };

  const handleAdd = (url) => {
    navigate(url);
  };

  const colspanLength = headerGroups[0].headers.length;

  return (
    <Box pb={5}>
      <Box py="16px" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" sx={{ position: "relative" }}>
          {renderHeading({
            handleAdd,
            setGlobalFilter,
          })}
        </Box>
        <Search
          onChange={onChangeFilter}
          placeholder={searchPlaceholder}
          onClear={onClearFilter}
        />
      </Box>
      <TableContainer
        sx={{
          maxWidth: "1392px",
          background: "white",
          border: "1px solid rgba(224, 224, 224, 1)",
          borderBottom: "none",
        }}
      >
        <Table {...getTableProps()} size="small">
          <TableHead>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              pageCount={pageCount}
            />
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} heightForty>
                {headerGroup.headers.map((column) => (
                  <TableColumnCell
                    column={column}
                    key={`column_${column.id}`}
                  />
                ))}
              </TableRow>
            ))}
          </TableHead>
          {rows.length === 0 && globalFilter && (
            <NoResultsTableFound colSpan={colspanLength} aria-label="TableRow">
              <span>{t("noResultsFound")}</span>
            </NoResultsTableFound>
          )}
          <TableBody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  aria-label="TableRow"
                  {...row.getRowProps()}
                  heightForty
                >
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()} normal>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}

            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              pageCount={pageCount}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

TableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  searchQuery: PropTypes.func,
  renderHeading: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  filterQuery: PropTypes.string,
  options: PropTypes.shape({
    addLabel: PropTypes.string,
    addUrl: PropTypes.string,
  }),
  sortObject: PropTypes.object.isRequired,
  updateSortObject: PropTypes.func.isRequired,
};

TableComponent.defaultProps = {
  options: { buttonLabel: "", addUrl: "/" },
  searchQuery: () => {},
  searchPlaceholder: "",
  filterQuery: "",
};

import React, { useEffect, useState } from "react";
import StyledContainer from "components/global/Container";
import { ReactComponent as HelpIcon } from "assets/help-icon.svg";
import { ReactComponent as ReleaseIcon } from "assets/release.svg";
import { ReactComponent as LogoutIcon } from "assets/logout-icon.svg";
import { useSelector } from "react-redux";
import { selectAuthState } from "data";
import { LocalStorageService } from "services";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useLocation } from "react-router-dom";
import * as Styles from "./styles";
import { HeadingSwitchProfiles } from "./HeadingSwitchProfiles";

function Header() {
  const [version, setVersion] = useState();
  const authState = useSelector(selectAuthState);
  const { t } = useTranslation();
  const location = useLocation();

  const helpURL =
    "https://help.xtm.cloud/connectors/en/xtm-connect---oracle-content-management.html";
  const releaseNotesURL =
    "https://drive.google.com/file/d/1wMDwi95vAU6vFPlz5RV4-R7pr4DAVgM1/view?usp=sharing";

  useEffect(() => {
    axios.get("/version").then((response) => {
      setVersion(response.data);
    });
  }, []);

  const userAuthority = LocalStorageService.getUserAuthority();

  const logoLink =
    location.pathname === "/login-user" ? "/login-user" : "/clients";

  return (
    <Styles.Header>
      <StyledContainer>
        <Styles.HeaderWrapper>
          <Styles.LogoWrapper to={logoLink}>
            <img src="/xtm-logo.png" alt="XTM Logo" />
            <Styles.Headline size="big" color="white">
              {t("header.title")}{" "}
              {version && (
                <span>
                  ( {t("header.version")} {version} )
                </span>
              )}
            </Styles.Headline>
          </Styles.LogoWrapper>
          <Styles.Navigation>
            {authState && (
              <>
                <Styles.ExternalLink
                  rel="noreffer"
                  href={releaseNotesURL}
                  target="_blank"
                >
                  <ReleaseIcon />
                  {t("header.menu.releaseNotes")}
                </Styles.ExternalLink>
                <Styles.ExternalLink
                  rel="noreffer"
                  href={helpURL}
                  target="_blank"
                >
                  <HelpIcon />
                  {t("header.menu.help")}
                </Styles.ExternalLink>
                {(userAuthority === "END_USER" ||
                  userAuthority === "CLIENT_ADMINISTRATOR") && (
                  <HeadingSwitchProfiles />
                )}
                <Styles.NavigationLink to="/logout">
                  <LogoutIcon />
                  {t("header.menu.logOut")}
                </Styles.NavigationLink>
              </>
            )}
          </Styles.Navigation>
        </Styles.HeaderWrapper>
      </StyledContainer>
    </Styles.Header>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/system";
import { ReactComponent as BellIcon } from "assets/bell-icon.svg";
import { HeadingActionButton } from "components/TableComponent/components/HeadingActionButton";
import { Popover } from "@mui/material";
import { CancelActionButton } from "components/global/forms/FormButton";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { fetchEventLogNotifications } from "data/features/eventLogs/eventLogApi";
import { useSelector } from "react-redux";
import { selectOracleProfile } from "data/features/translationQueue/translationQueueSlice";
import { Notification } from "./Notification";
import { changeNotificationStatus } from "./NotificationsApi";

const NotificationPopover = styled(Popover)`
  .MuiPopover-paper {
    transition: 0.3s ease;
    width: 408px;
    border-radius: 0.5rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.23);
    position: relative;
    overflow: visible;
    border: 1px solid #dddddd;

    &::after,
    &::before {
      content: "";
      position: absolute;

      border-style: solid;
    }

    &::before {
      z-index: 19;
      top: -10px;
      right: 10px;
      border-width: 0 11px 10px 11px;
      border-color: transparent transparent #dddddd transparent;
    }

    &::after {
      z-index: 20;
      border-width: 0 9px 8px 9px;
      border-color: transparent transparent #ffffff transparent;
      top: -8px;
      right: 12px;
    }
  }
`;

const NotificationPopoverFooter = styled("div")`
  width: 100%;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.palette.white.main};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 0 0.8rem 0.8rem;
  position: sticky;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const NotificationPopoverContent = styled("div")`
  padding: 2.4rem;
  max-height: 375px;
  overflow: auto;
`;

const NoNotificationMessage = styled("div")`
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 400;
`;

const RedDotBell = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 40%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.error.main};
    opacity: 0;
  }

  ${({ show }) =>
    show &&
    `
        &::after {

            opacity: 1;
        }
    `}
`;

export const Notifications = () => {
  const [update, setUpdate] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const oracleProfile = useSelector(selectOracleProfile);

  useEffect(() => {
    fetchEventLogNotifications(oracleProfile).then((response) =>
      setNotifications(response.data)
    );
  }, [update, oracleProfile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteNotification = (id) => {
    changeNotificationStatus(id, "REMOVED").then(() => {
      setUpdate(update + 1);
    });
  };

  const handleNew = (id, status) => {
    if (status === "ACTIVE") {
      changeNotificationStatus(id, "VIEWED").then(() => {
        setUpdate(update + 1);
      });
    }
  };

  const handleClearAllNotifications = () => {
    const notificationIds = notifications?.map(
      (notification) => notification.id
    );

    changeNotificationStatus(notificationIds, "REMOVED").then(() => {
      setUpdate(update + 1);
    });
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <HeadingActionButton
        aria-label="NotificationHeadingActionButton"
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RedDotBell show={notifications.length}>
          <BellIcon />
        </RedDotBell>
        <Box
          sx={{
            fontSize: "1.4rem",
            marginLeft: "0.5rem",
            fontWeight: 500,
            color: "#555",
          }}
        >
          Notifications{" "}
          {notifications.length > 0 && (
            <span aria-label="NotificationsLength">
              {" "}
              ({notifications.length})
            </span>
          )}
        </Box>
      </HeadingActionButton>

      <NotificationPopover
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "right",
        }}
      >
        <NotificationPopoverContent>
          <TransitionGroup>
            {notifications?.map((notification) => (
              <CSSTransition
                classNames="fade-right"
                timeout={300}
                key={notification.id}
              >
                {() => (
                  <Notification
                    handleDelete={handleDeleteNotification}
                    handleNew={handleNew}
                    data={notification}
                  />
                )}
              </CSSTransition>
            ))}
            {!notifications.length && (
              <CSSTransition classNames="fade" timeout={100}>
                <NoNotificationMessage style={{ transitionDelay: "400ms" }}>
                  No notifications yet! <br /> When you get notifications, they
                  will show up here
                </NoNotificationMessage>
              </CSSTransition>
            )}
          </TransitionGroup>
        </NotificationPopoverContent>
        {notifications.length ? (
          <NotificationPopoverFooter>
            <CancelActionButton
              aria-label="ClearAllNotificationsButton"
              onClick={handleClearAllNotifications}
            >
              Clear All
            </CancelActionButton>
          </NotificationPopoverFooter>
        ) : null}
      </NotificationPopover>
    </>
  );
};

import React from "react";
import StyledSection from "components/global/Section";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Styles from "./styles";
import ChangePasswordForm from "./components/ChangePasswordForm";

function ChangePassword() {
  const { t } = useTranslation();
  const { key } = useParams();

  return (
    <StyledSection type="login" spaceTop>
      <Styles.LoginBox>
        <Styles.LoginBoxHeader>
          {t("changePassword.box.header")}
        </Styles.LoginBoxHeader>
        <Styles.LoginBoxContent>
          <ChangePasswordForm changePasswordKey={key} />
        </Styles.LoginBoxContent>
      </Styles.LoginBox>
    </StyledSection>
  );
}

export default ChangePassword;

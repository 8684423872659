import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { SubmitButton, CancelButton } from "components/global/forms";

export const LoginBox = styled("div")`
  margin: 0 auto;
  max-width: 334px;
  width: 100%;
  border-radius: 0.2rem;
  border: 0.075rem solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme }) => theme.palette.white.main};

  ${({ userLogin }) => userLogin && `max-width: 334px;`}

  ${({ adminLogin }) => adminLogin && `max-width: 334px;`}
`;

export const LoginBoxHeader = styled("div")`
  padding: 1.1rem 1.6rem;
  background-color: ${({ theme }) => theme.palette.background.mediumDark};
  font-size: 1.3rem;
  line-height: 1.523rem;
  font-weight: 500;
  min-height: 4rem;
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const LoginBoxContent = styled("div")`
  padding: 0.2rem 1.6rem 2rem 1.6rem;

  ${({ userLogin }) => userLogin && `height: 36.8rem;`}
`;

export const ForgotPasswordLink = styled(Link)`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
`;

export const LoginSubmitButton = styled(SubmitButton)`
  width: 100%;
  min-height: 3.2rem;
  margin: 2.4rem 0 0 0;
  border: none;
  font-weight: 500;

  ${({ standard }) => standard && `width: 30rem;`}
`;

export const LoginForgotPasswordText = styled("div")`
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding: 1.6rem 1.6rem 0 1.6rem;
  color: ${({ theme }) => theme.palette.text.light};
`;

export const LoginCancelButton = styled(CancelButton)`
  width: 100%;
  min-height: 3.2rem;
  margin: 0.8rem 0 0 0;
  font-weight: 500;

  ${({ standard }) =>
    standard &&
    `
  width: 30rem;
  max-height: 3.2rem;
  `}
`;

export const NoCredentialsYet = styled("p")`
  margin-top: 1.6rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  font-family: Roboto, serif;
  font-style: normal;
  max-height: 4.8rem;
`;

export const NoCredentialsYetLink = styled("a")`
  font-size: 1.2rem;
  margin-top: 0.4rem;
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
`;

import React from "react";
import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";

const UncheckedIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: "visible" }}
  >
    <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="white" />
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      stroke="#777777"
      strokeWidth={1.5}
    />
  </svg>
);

export const StyledCheckbox = styled((props) => (
  <Checkbox {...props} icon={<UncheckedIcon />} />
))`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 4px;

  & .MuiSvgIcon-root {
    font-size: 2.2rem;
  }
`;

export const StyledCheckbox2 = styled(Checkbox)`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 4px;

  & .MuiSvgIcon-root {
    font-size: 2.2rem;
  }
`;

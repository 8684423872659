import React from "react";
import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const getStatusLabel = (status) => {
  switch (status) {
    case "IN_PROGRESS":
      return "In progress";
    case "PARTIALLY_FINISHED":
      return "In progress";
    case "FINISHED":
      return "Finished";
    case "CANCELLED":
      return "Cancelled";
    case "SENT":
      return "Sent";
    case "IMPORTED":
      return "Imported";
    default:
      return "In progress";
  }
};

const getProgressVariant = (status) => {
  switch (status) {
    case "IN_PROGRESS":
      return "#00A4E4";
    case "PARTIALLY_FINISHED":
      return "#00A4E4";
    case "FINISHED":
      return "#38AF49";
    case "CANCELLED":
      return "#FF6347";
    case "SENT":
      return "#E0E1E3";
    case "IMPORTED":
      return "#a7a7a8";
    default:
      return "#00A4E4";
  }
};

export const XtmStatusCell = ({ row }) => (
  <Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          color: getProgressVariant(row?.original.xtm_status),
          fontSize: "1.3rem",
        }}
      >
        {getStatusLabel(row?.original.xtm_status)}
      </Box>
      <span>{row?.original.project_progress}%</span>
    </Box>
    <LinearProgress
      sx={{
        width: "100%",
        backgroundColor: "#eeeeee",
        "&	.MuiLinearProgress-bar": {
          backgroundColor: getProgressVariant(row?.original.xtm_status),
        },
      }}
      value={row?.original.project_progress}
      variant="determinate"
    />
  </Box>
);

XtmStatusCell.propTypes = {
  row: PropTypes.object.isRequired,
};

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { LocalStorageService } from "services";

export const ProtectedRoute = ({ children }) => {
  const userAuthority = LocalStorageService.getUserAuthority();
  const location = useLocation();

  const MAIN_ADMIN_ROUTES = [
    "/clients",
    "/clients/add",
    "/clients/edit",
    "/administrators",
    "/administrators/add",
    "/administrators/edit",
  ];

  const ADMIN_ROUTES = [
    "/users",
    "/users/add",
    "/users/duplicate",
    "/oracle-profiles",
    "/oracle-profiles/add",
    "/oracle-profiles/duplicate",
    "/event-log",
  ];

  if (
    !LocalStorageService.getAuthToken() &&
    location.pathname === "/login-user"
  ) {
    return <Navigate replace to="/login-user" />;
  }

  if (!LocalStorageService.getAuthToken()) {
    return <Navigate replace to="/login" />;
  }

  if (userAuthority !== "MAIN_ADMINISTRATOR") {
    if (
      userAuthority === "CLIENT_ADMINISTRATOR" &&
      [...MAIN_ADMIN_ROUTES, "/"].includes(location.pathname)
    ) {
      return <Navigate replace to="/users" />;
    }
    if (
      userAuthority === "END_USER" &&
      [...MAIN_ADMIN_ROUTES, ...ADMIN_ROUTES, "/"].includes(location.pathname)
    ) {
      return <Navigate replace to="/select-oracle-profile" />;
    }
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUsers } from "./usersApi";

const initialState = {
  isLoading: false,
  error: null,
  data: undefined,
  sort: {
    field: "client",
    order: "ASC",
  },
};

export const fetchUsersAsync = createAsyncThunk(
  "users/fetchUsers",
  async (params) => {
    const response = await fetchUsers(params);
    return response.data;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.data = undefined;
      state.error = null;
      state.isLoading = false;
    },
    updateSort: (state, action) => {
      state.sort = action.payload;
    },
  },
  extraReducers: {
    [fetchUsersAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchUsersAsync.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchUsersAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const selectUsersData = ({ users }) => users.data;
export const selectUsersIsLoading = ({ users }) => users.isLoading;
export const selectUsersError = ({ users }) => users.error;
export const selectUsersSort = ({ users }) => users.sort;

export const { clearUsers, updateSort } = usersSlice.actions;

export default usersSlice.reducer;

import {
  DateCell,
  MenuCell,
  XtmStatusCell,
  TranslationQueueActionsHeader,
  TargetLanguagesCell,
  ProjectNameCell,
} from "components/TableComponent/cells";
import { TranslationQueueProjectActionCell } from "components/TableComponent/cells/TranslationQueueProjectActionCell";
import { XtmCustomerName } from "components/TableComponent/cells/XtmCustomerName";

export const translationQueueColumns = [
  {
    Header: TranslationQueueActionsHeader,
    accessor: "id",
    width: "70px",
    disableSortBy: true,
    Cell: TranslationQueueProjectActionCell,
  },
  {
    Header: "Project name",
    accessor: "project_name",
    width: "200px",
    Cell: ProjectNameCell,
  },
  {
    Header: "Source language",
    accessor: "source_language",
    width: "150px",
  },
  {
    Header: "Target languages",
    accessor: "target_languages",
    width: "165px",
    Cell: TargetLanguagesCell,
  },
  {
    Header: "XTM Customer",
    accessor: "xtm_customer_name",
    width: "138px",
    Cell: XtmCustomerName,
  },
  {
    Header: "Created date",
    accessor: "created_date",
    width: "126px",
    Cell: DateCell,
  },
  {
    Header: "Due date",
    accessor: "due_date",
    width: "102px",
    height: "auto",
    Cell: DateCell,
  },
  {
    Header: "XTM workflow step",
    accessor: "xtm_workflow_step",
    width: "152px",
    disableSortBy: true,
    canSort: false,
  },
  {
    Header: "XTM status",
    accessor: "project_progress",
    width: "145px",
    Cell: XtmStatusCell,
  },
  {
    Header: "",
    accessor: "edit",
    canSort: false,
    width: "auto",
    height: "auto",
    Cell: MenuCell,
  },
];

import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Chip } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { MenuItem, WhiteSelect, SelectOptionText } from "components/Select";
import { StyledCheckbox, TextInput } from "components/global/forms";
import { Tooltip } from "components/Tooltip";
import {
  NoUsersText,
  SearchBox,
  SearchButton,
  SearchButtonsBox,
  SearchIcon,
} from "./styles";

export const MultiSelect = forwardRef(
  (
    {
      searchable,
      placeholder,
      menuItems,
      value,
      onChange,
      onClear,
      onDelete,
      onSelectAll,
      chipWidth,
      notFoundMessage,
      ...props
    },
    ref
  ) => {
    const [filteredMenuItems, setFilteredMenuItems] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
      if (menuItems) {
        setFilteredMenuItems(menuItems);
      }
    }, [menuItems]);

    useEffect(() => {
      inputRef.current?.focus();
    }, [filteredMenuItems]);

    const onSearch = (e) => {
      setFilteredMenuItems(
        menuItems.filter((item) => item.includes(e.currentTarget.value))
      );
    };

    return (
      <WhiteSelect
        {...props}
        ref={ref}
        value={value}
        onChange={onChange}
        multiple
        searchable={searchable}
        displayEmpty
        renderValue={(selected) =>
          selected.length ? (
            <Box
              sx={{
                display: "flex",
                zIndex: 1400,
                flexWrap: "wrap",
                rowGap: "12px",
                columnGap: "4px",
              }}
            >
              {selected.map((item) => (
                <Tooltip title={item} arrow placement="top">
                  <Chip
                    sx={{
                      fontSize: "1.3rem",
                      height: "2rem",
                      padding: "3px 5px 3px 0",
                      maxWidth: chipWidth,
                      backgroundColor: "#E0E1E3",
                      "& .MuiChip-label": {
                        maxWidth: "calc(100% - 9px)",
                      },
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => {
                      if (onDelete) {
                        onDelete(value, item);
                      } else {
                        onChange(value.filter((val) => val !== item));
                      }
                    }}
                    deleteIcon={<DeleteIcon />}
                    key={item}
                    label={item}
                  />
                </Tooltip>
              ))}
            </Box>
          ) : (
            <span>{placeholder}</span>
          )
        }
      >
        {searchable && (
          <SearchBox onKeyDown={(e) => e.stopPropagation()}>
            <TextInput ref={inputRef} onChange={onSearch} />
            <SearchIcon />
            <SearchButtonsBox>
              <SearchButton
                onMouseDown={() => {
                  if (typeof onSelectAll === "function") {
                    onSelectAll(menuItems);
                  } else {
                    onChange(menuItems);
                  }
                }}
              >
                Select all
              </SearchButton>
              <SearchButton
                onMouseDown={() => {
                  if (typeof onClear === "function") {
                    onClear();
                  } else {
                    onChange([]);
                  }
                }}
              >
                Clear
              </SearchButton>
            </SearchButtonsBox>
          </SearchBox>
        )}
        {filteredMenuItems.length ? (
          filteredMenuItems.map((item) => (
            <MenuItem
              key={item}
              value={item}
              classes={{ selected: "selected" }}
            >
              <StyledCheckbox checked={value.includes(item)} color="success" />
              <SelectOptionText>{item}</SelectOptionText>
            </MenuItem>
          ))
        ) : (
          <NoUsersText>{notFoundMessage}</NoUsersText>
        )}
      </WhiteSelect>
    );
  }
);

MultiSelect.propTypes = {
  searchable: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClear: PropTypes.func,
  onSelectAll: PropTypes.func,
  chipWidth: PropTypes.string,
  notFoundMessage: PropTypes.string,
};

MultiSelect.defaultProps = {
  searchable: false,
  onClear: null,
  onSelectAll: null,
  onDelete: () => {},
  chipWidth: "calc(50% - 4px)",
  notFoundMessage: "Users not found",
};

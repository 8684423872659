import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "components/TableComponent/components";
import { Collapse, Table, TableBody } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCollapsedRows } from "data/features/translationQueue/translationQueueSlice";
import { StyledTableRow } from "components/TableComponent/components/TableRow";
import { ProjectJobRow } from "./ProjectJobRow";

export const ProjectRow = ({ row }) => {
  const collapsedRows = useSelector(selectCollapsedRows);
  const isRowCollapsed = collapsedRows.includes(row.original.project_id);
  return (
    <>
      <StyledTableRow
        {...row.getRowProps()}
        sx={{
          position: "relative",
          backgroundColor: "#fff !important",
          display: "flex",
          alignItems: "center",
          height: "50px",
          "&:not(:last-child)": {
            marginBottom: "0.8rem",
          },
        }}
      >
        {row?.cells.map((cell) => (
          <TableCell
            {...cell.getCellProps()}
            sx={{
              width: cell.column.width || "auto",
              height: cell.column.height || "auto",
              backgroundColor: "#fff",
              border: "none",
            }}
          >
            {cell.render("Cell")}
          </TableCell>
        ))}
      </StyledTableRow>
      <StyledTableRow sx={{ display: "block" }}>
        <Collapse
          in={isRowCollapsed}
          timeout="auto"
          sx={{
            "& .MuiCollapse-vertical": {
              display: "block",
            },
          }}
        >
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingRight: 0,
              border: "none",
              width: "100%",
              display: "block",
            }}
          >
            <Table>
              <TableBody>
                {row?.original?.languages.map((language, index) => (
                  <ProjectJobRow
                    index={index}
                    data={language}
                    parentRow={row.original.project_id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </Collapse>
      </StyledTableRow>
    </>
  );
};

ProjectRow.propTypes = {
  row: PropTypes.object.isRequired,
  getTableProps: PropTypes.func.isRequired,
};

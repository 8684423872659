export const buildDueDate = (value, rowID, jobID) => {
  const jobs = [];

  if (jobID !== null) {
    jobs.push({
      job_id: jobID,
      due_date: value,
    });
  }

  const preparedData = {
    projects: [
      {
        project_id: rowID,
        due_date: value,
        jobs,
      },
    ],
  };

  return preparedData;
};

import React from "react";
import { styled } from "@mui/system";
import MUITInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const DateIconWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555555;
  position: relative;
  height: 32px;
  width: 32px;
  border-left: 1px solid #dddddd;
  cursor: pointer;
`;

const DateIcon = styled(CalendarTodayIcon)`
  color: #555555;
  margin-left: 5px;
`;

export const CustomInput = styled(({ onOpen, ...props }) => (
  <MUITInput
    {...props}
    placeholder="Choose..."
    disableUnderline
    endAdornment={
      <InputAdornment position="end" onClick={onOpen} sx={{ zIndex: 99 }}>
        <DateIconWrapper>
          <DateIcon />
        </DateIconWrapper>
      </InputAdornment>
    }
  />
))`
  width: 100%;
  outline: none;
  padding: 0.5rem;
  font-size: 13px;
  height: 32px;
  color: ${({ theme }) => theme.palette.text.main};
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  background-color: ${({ theme }) => theme.palette.white.main};
`;

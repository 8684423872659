import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const SubmitButton = styled("button")`
  width: auto;
  padding: 0.75rem 1.2rem;
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  border: 1px solid transparent;
  text-decoration: none;
  margin: 0 0.8rem;
  font-family: "Roboto", sans-serif;
  min-width: 6.4rem;

  color: ${({ theme }) => theme.palette.white.main};
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ theme, customDisabled }) =>
    customDisabled &&
    `
    cursor: not-allowed;
    background-color: ${theme.palette.primary.light};
  `}
  ${({ connectButton }) =>
    connectButton &&
    `
    font-weight: 500;
    margin: 0;
    width: 100%; 
    border-radius: 0.4rem;
  `}
  ${({ notConnected }) =>
    notConnected &&
    `
    background-color: #6F8294;
  `}
  ${({ submitDueDate }) =>
    submitDueDate &&
    `
  height: 2.9rem;
  width: 5.3rem;
  min-width: 5.3rem;
`}

  ${({ applyButton }) =>
    applyButton &&
    `
  height: 3.2rem;
  width: 9.4rem;
  padding: 0.5rem 1rem;
`}

  ${({ submitRemove }) =>
    submitRemove &&
    `
  height: 2.9rem;
  width: 10.7rem;
  min-width: 10.7rem;
  padding: 0.2rem .7rem;
`}

  ${({ profileSubmit }) =>
    profileSubmit &&
    `
  height: 3.2rem;
  width: 36.5rem;
`}

  ${({ standard }) =>
    standard &&
    `
  height: 3.2rem;
  width: 6.4rem;
`}

  ${({ small }) =>
    small &&
    `
  height: 3.2rem;
  min-width: 5.2rem;
  padding: 0.45rem 0.8rem;
`}
`;

export const CancelButton = styled(Link)`
  width: auto;
  padding: 0.85rem 1.2rem;
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 0 0.8rem;
  text-decoration: none;
  min-width: 6.4rem;
  font-family: "Roboto", sans-serif;
  border: 1px solid ${({ theme }) => theme.palette.border.light};
  color: ${({ theme }) => theme.palette.text.main};
  background-color: ${({ theme }) => theme.palette.white.main};

  ${({ standard }) =>
    standard &&
    `
  padding: 0.75rem 1.2rem;
  width: 6.4rem; 
  height: 3.2rem
  `}

  ${({ admin }) =>
    admin &&
    `
  padding: 0.75rem 1.2rem;
  width: 6.4rem; 
  height: 3.2rem
  `}
`;

export const CancelActionButton = styled("button")`
  width: auto;
  padding: 0.85rem 1.2rem;
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 0 0.8rem;
  text-decoration: none;
  min-width: 6.4rem;
  font-family: "Roboto", sans-serif;
  border: 1px solid ${({ theme }) => theme.palette.border.light};
  color: ${({ theme }) => theme.palette.text.main};
  background-color: ${({ theme }) => theme.palette.white.main};

  ${({ cancelModal }) =>
    cancelModal &&
    `
  height: 2.9rem;
  width: 6.4rem;
`}
`;

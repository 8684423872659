import MUITableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";

export const TableRow = styled(MUITableRow)`
  &:nth-of-type(odd) {
    background-color: #eff3f6;
  }
  &.MuiTableRow-head,
  &:last-of-type {
    background-color: #fff;
  }
  &.MuiTableRow-head {
    height: 40px;
    align-items: center;
  }

  ${({ heightForty }) =>
    heightForty &&
    `
          height: 4rem;
          `}
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-head,
  &:last-of-type {
    background-color: transparent;
  }
`;

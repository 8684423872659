import axios from "axios";
import { fetchRefreshToken } from "data/features/auth/authApi";
import { store } from "data/store";
import { EnvService, LocalStorageService, RefreshTokenService } from "services";

const URLS_TO_SKIP = [
  "auth/login",
  "auth/end-user/login",
  "auth/forgot-password",
  "auth/change-password",
  "/version",
];

export const setAuthorizationToken = () => {
  const token = LocalStorageService.getAuthToken();
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const configureAxios = () => {
  axios.defaults.baseURL = EnvService.getApiUrl();
  axios.interceptors.request.use(
    (config) => {
      if (!URLS_TO_SKIP.includes(config.url)) {
        if (RefreshTokenService.hasTokenExpired()) {
          window.location.href = "/login";
          LocalStorageService.setAuthToken("");
          store.dispatch({
            type: "auth/logout",
          });
        }

        if (RefreshTokenService.canRefreshToken()) {
          fetchRefreshToken(LocalStorageService.getRefreshToken()).then(
            (response) => {
              LocalStorageService.setAuthToken(response.data.token);
              LocalStorageService.setRefreshToken(response.data.refresh_token);
              setAuthorizationToken();
            }
          );
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
  setAuthorizationToken();
};

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Tooltip } from "../../Tooltip";

const maxTextLength = 30;
export const TargetLanguagesCell = ({ value }) => {
  const languages =
    value.length > maxTextLength
      ? `${value.substring(0, maxTextLength - 3)}...`
      : value;

  return (
    <Tooltip title={value} arrow placement="bottom-start">
      <Box sx={{ display: "flex", overflowWrap: "anywhere" }}>{languages}</Box>
    </Tooltip>
  );
};

TargetLanguagesCell.propTypes = {
  value: PropTypes.array.isRequired,
};

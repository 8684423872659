import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Tooltip } from "../../Tooltip";

export const ProjectNameCell = ({ row }) => {
  const projectName = row?.original?.project_name;
  return (
    <Tooltip title={projectName} arrow placement="bottom">
      <Box>{projectName}</Box>
    </Tooltip>
  );
};

ProjectNameCell.propTypes = {
  row: PropTypes.object.isRequired,
};

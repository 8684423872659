import React from "react";
import { Modal, Fade } from "@mui/material";
import PropTypes from "prop-types";
import {
  InnerModal,
  InnerModalHeading,
} from "pages/Panel/EventLog/EventLogsModal";
import { Box } from "@mui/system";

export const FlexibleModal = ({
  heading,
  renderContent,
  modalOpen,
  handleCloseModal,
  handleSaveModal,
  maxWidth,
}) => (
  <Modal open={modalOpen} onClose={handleCloseModal} closeAfterTransition>
    <Fade in={modalOpen}>
      <InnerModal maxWidth={maxWidth}>
        <InnerModalHeading>{heading}</InnerModalHeading>
        <Box>{renderContent({ handleCloseModal, handleSaveModal })}</Box>
      </InnerModal>
    </Fade>
  </Modal>
);

FlexibleModal.propTypes = {
  heading: PropTypes.string.isRequired,
  renderContent: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveModal: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

FlexibleModal.defaultProps = {
  maxWidth: "685px",
};

import {
  OracleProfilesActionCell,
  DateCell,
  StatusCell,
} from "components/TableComponent/cells";
import i18n from "config/i18n";

const { t } = i18n;

export const oracleProfilesColumns = [
  {
    Header: t("column.id"),
    accessor: "id",
    width: "230px",
  },
  {
    Header: t("column.oracleProfiles.profileName"),
    accessor: "connection_profile_name",
    width: "250px",
  },
  {
    Header: t("column.oracleProfiles.client"),
    accessor: "client",
    width: "200px",
  },
  {
    Header: t("column.status"),
    accessor: "connection_profile_status",
    Cell: StatusCell,
    width: "150px",
  },
  {
    Header: t("column.dateAdded"),
    accessor: "created_date",
    Cell: DateCell,
    width: "150px",
  },
  {
    Header: t("column.dateModified"),
    accessor: "modification_date",
    Cell: DateCell,
    width: "150px",
  },
  {
    Header: "",
    accessor: "edit",
    width: "50px",
    disableSortBy: false,
    Cell: OracleProfilesActionCell,
  },
];

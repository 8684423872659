import {
  DateCell,
  EditCell,
  StatusCell,
} from "components/TableComponent/cells";

export const administratorColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: "230px",
  },
  {
    Header: "Administrator email",
    accessor: "email",
    width: "250px",
  },
  {
    Header: "Client name",
    accessor: "client",
    width: "200px",
  },
  {
    Header: "Status",
    accessor: "status",
    width: "150px",
    Cell: StatusCell,
  },
  {
    Header: "Date added",
    accessor: "created_date",
    width: "150px",
    Cell: DateCell,
  },
  {
    Header: "Date modified",
    accessor: "modification_date",
    width: "150px",
    Cell: DateCell,
  },
  {
    Header: "",
    accessor: "edit",
    width: "50px",
    canSort: false,
    Cell: EditCell({
      tooltipContent: "Edit administrator",
      redirectParams: {
        pathname: "/administrators/edit",
        searchParam: "id",
      },
    }),
  },
];

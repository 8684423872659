import MUITableCell from "@mui/material/TableCell";
import styled from "@emotion/styled";

export const TableCell = styled(MUITableCell)`
  font-size: 13px;
  padding: 0 24px 0 24px;
  ${({ pagination }) =>
    pagination &&
    `
          height: 40px;
  padding: 0px 16px 0px 16px;
    `}

  ${({ projectRow }) =>
    projectRow &&
    `
  padding: 12.5px 5px 12.5px 0px;
    `}

  ${({ normal }) =>
    normal &&
    `
          height: 40px;
  padding: .35rem 2rem 0.35rem 2.4rem;
    `}
`;
